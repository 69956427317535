import { Skeleton } from "@mui/material";
import { Card } from "antd";

export default function LoadingCarCard() {
  return (
    <Card
      cover={
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={200}
          sx={{
            borderRadius: "8px 8px 0px 0px",
          }}
        />
      }
      bordered={true}
    >
      <div className="pb-3">
        <Skeleton variant="text" sx={{ fontSize: "24px" }} />
        <div className="flex gap-2">
          <Skeleton
            variant="rectangular"
            width={69}
            height={32}
            sx={{
              borderRadius: "40px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={80}
            height={32}
            sx={{
              borderRadius: "40px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={32}
            sx={{
              borderRadius: "40px",
            }}
          />
        </div>
        <p className="flex items-center space-x-1 pt-1">
          <Skeleton variant="text" width={199} sx={{ fontSize: "16px" }} />
        </p>
      </div>

      <div className="flex justify-between pt-2 border-t mb-2">
        <Skeleton variant="text" width={89} sx={{ fontSize: "16px" }} />
        <Skeleton variant="text" width={151} sx={{ fontSize: "22px" }} />
      </div>

      <div className="cta-container">
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={48}
          sx={{
            borderRadius: "40px",
          }}
        />
      </div>
    </Card>
  );
}
