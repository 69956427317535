import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Space, Tag, Button, Typography } from "antd";
import { ImageLoader } from "@/utils/ImageLoader";
import { numberWithCommas } from "@/utils/commonFunctions";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { CarLocationRepoIcon } from "@/assets/svgs";
import formatInt from "@/utils/format-int";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { device } from "@/utils/device";

const CardWrapper = styled.div`
  .image-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
  }
  .card-container {
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
    .ant-card {
    }
    .ant-card .ant-card-body {
      padding: 10px 10px 14px;
    }
    .ant-tag.ant-tag-has-color,
    .ant-tag.ant-tag-has-color a,
    .ant-tag.ant-tag-has-color a:hover,
    .ant-tag.ant-tag-has-color .anticon-close,
    .ant-tag.ant-tag-has-color .anticon-close:hover {
      color: #fff;
      border-radius: 40px;
      font-weight: bold;
      font-size: 14px;
      padding: 0.125rem 0.62rem;
      @media ${device.mobileL} {
        font-size: 13px;
      }
    }
    .car-container {
      /* border-bottom: 1px solid #e1e7ec; */
      /* padding-bottom: 16px; */
      .car-name {
        color: #485164;
        font-size: 1.25rem;
        font-family: OutfitBold !important;
        @media ${device.mobileL} {
          font-size: 20px;
        }
      }
    }
    .amount-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0 12px;
      .amount {
        color: #485164;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        @media ${device.mobileL} {
          font-size: 14px;
        }
      }
      .location {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 12px;
      }
      .min-percent {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 12.5px;
        @media ${device.mobileL} {
          font-size: 11px;
        }
      }
      .min-amount {
        color: #4e538a;
        font-size: 22px;
        line-height: 20px;
        font-family: OutfitBold !important;
        text-align: right;
        @media ${device.mobileL} {
          font-size: 18px;
        }
      }
    }
    .cta-container {
      display: flex;
      align-items: center;
      gap: 14px;
      @media ${device.mobileL} {
        gap: 4px;
      }
      button {
        width: 100%;
      }
      .req-inspection-btn {
        height: 48px;
        border-radius: 60px;
        background: #fff;
        border: 1px solid #30345e;
        span {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          @media ${device.mobileL} {
            font-size: 12px;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: rgba(49, 53, 91, 0.04);
          border: 1px solid rgb(49, 53, 91);
        }
      }
      .apply-loan-btn {
        height: 48px;
        background: #ffb619;
        border-radius: 60px;
        border: 0;
        span {
          color: #30345e;
          font-weight: 700;
          text-transform: capitalize;
          font-size: 14px;
          @media ${device.mobileL} {
            font-size: 12px;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: rgb(255, 182, 25);
          box-shadow:
            rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
            rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
        }
      }
    }
  }
`;

const CarCard = ({ variant, elm }: { variant: string; elm: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("inventory");
  const { Text } = Typography;
  const [ellipsis] = useState(true);

  const toRepoGuide = (id: string) => {
    navigate(`/dealer/financing/guide?car_id=${id}`);
  };

  const getInspectionButtonProps = () => {
    let buttonText;
    let buttonDisabled = false;

    if (elm?.hasInspection) {
      buttonText = t("list-car");
      // You can add onClick handler here: onClick={() => showListCar(elm?.id)}
    } else if (elm?.hasInspectionRequest) {
      buttonText = t("pending-inspection");
      buttonDisabled = true;
    } else {
      buttonText = t("request-inspection");
      // You can add onClick handler here: onClick={() => showReqInspection(elm)}
    }

    return { text: buttonText, disabled: buttonDisabled };
  };

  const getButtonText = () => {
    let buttonText;

    if (variant === "change-car") {
      buttonText = t("select-car");
    } else if (variant === "repo") {
      buttonText = "make an offer";
    } else {
      buttonText = t("apply-for-loan");
    }

    return buttonText;
  };

  const { text, disabled } = getInspectionButtonProps();
  const buttonText = getButtonText();

  return (
    <CardWrapper>
      <div className="card-container">
        <Card
          cover={
            <img
              src={ImageLoader({
                src: `${elm?.imageUrl}`,
                width: 400,
                quality: 100,
              })}
              alt="car-banner"
              className="image-cover"
              onClick={() => navigate(`/dealer/inventory/${elm?.id}?repo=true`)}
            />
          }
          bordered={true}
        >
          <div
            className="car-container pb-3"
            onClick={() => navigate(`/dealer/inventory/${elm?.id}?repo=true`)}
          >
            <div className="car-name">
              {elm?.year
                ? `${elm?.year} ${elm?.model?.make?.name || elm?.title} ${
                    elm?.model?.name || ""
                  }`
                : "---"}
            </div>
            <Space size={[0, 8]} wrap>
              <Tag color="#4E538A">
                {elm?.sellingCondition
                  ? capitalFirstLetter(t(elm?.sellingCondition))
                  : "---"}
              </Tag>
              <Tag color="#4E538A">
                {numberWithCommas(elm?.mileage)}{" "}
                {t(elm?.mileageUnit)?.toUpperCase()}
              </Tag>
              <Tag color="#4E538A">
                {elm?.engineType
                  ? capitalFirstLetter(t(elm?.engineType)?.toUpperCase())
                  : "---"}
              </Tag>
            </Space>
            <p className="flex items-center space-x-1 pt-1">
              <CarLocationRepoIcon />
              <Text
                style={ellipsis ? { width: "250px" } : undefined}
                className="location"
                ellipsis={ellipsis ? { tooltip: `${elm?.address}` } : false}
              >
                {elm?.address || "---"}
              </Text>
            </p>
          </div>
          <div
            className="flex  justify-between amount-container pt-2 border-t mb-2"
            onClick={() => navigate(`/dealer/inventory/${elm?.id}?repo=true`)}
          >
            <div>
              <div className="amount">
                {formatInt(elm?.marketplacePrice, true)}
              </div>
            </div>
            <div>
              <div className="min-amount">
                {formatInt(elm?.installment, true)} / Mo
              </div>
            </div>
          </div>

          <div className="cta-container">
            {variant === "inventory" && (
              <>
                {elm?.marketplaceVisible ? (
                  <Button
                    className="req-inspection-btn"
                    //   onClick={() => showDelistCar(elm?.id)}
                  >
                    {t("delist-car")}
                  </Button>
                ) : (
                  <>
                    <Button className="req-inspection-btn" disabled={disabled}>
                      {text}
                    </Button>
                  </>
                )}
              </>
            )}
            <Button
              className="apply-loan-btn"
              onClick={() => toRepoGuide(elm?.id)}
              // onClick={() => {
              //   variant === "change-car" && simulate
              //     ? toOfferSimulator(elm?.id)
              //     : variant === "change-car"
              //     ? handleCarChange(elm?.id)
              //     : toLoan(elm?.id);
              // }}
            >
              {buttonText}
            </Button>
          </div>
        </Card>
      </div>
    </CardWrapper>
  );
};

export default CarCard;
