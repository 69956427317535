import React, { useState, useEffect } from "react";
import {
  CreateCarContainer,
  CustomCarUploadContainer,
  CustomFormContainer,
  CustomFormField,
  CustomInputMileage,
} from "@/layouts/application-layouts/styles";
import {
  Button,
  Upload,
  message,
  InputNumber,
  Select,
  Input,
  Radio,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  CheckCircleFilled,
  DeleteTwoTone,
} from "@ant-design/icons";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import storage from "@l/storage";
import Box from "@mui/material/Box";
import CarUploads from "@/layouts/application-layouts/CarUploads";
import CarFeatures from "@/layouts/application-layouts/CarFeatures";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import { generateArrayOfYears } from "@/utils/yearsArray";
import { TelInputContainer } from "../auth/styles/sign-in";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { RadioChangeEvent, DatePickerProps } from "antd";
import { ImageLoader } from "@/utils/ImageLoader";
import { useTranslation } from "react-i18next";
import formatCurrency from "@u/formatCurrency";
import { Loader } from "../auth/styles/loader";

interface ICarMakes {
  name: string;
  id: number;
}

interface ICarModels {
  name: string;
  id: number;
}

const CreateCarForm = () => {
  const { Option } = Select;
  const role = storage.getRole();
  const user = storage.getUser();
  const country = storage.getCountry();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const [step, setStep] = useState(1);
  const [carMakes, setCarMakes]: any = useState([]);
  const [carModels, setCarModels]: any = useState([]);
  const [carMakeId, setCarMakeId] = useState("");
  const [carModelId, setCarModelId] = useState("");
  const [carYear, setCarYear] = useState("");
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isFranchise = storage.utilities.isFranchise();
  const [inputValue, setInputValue] = useState({
    vinNumber: "",
    address: "",
    mileage: "",
    engineCapacity: "",
    reasonForSelling: "",
    identityNumber: "",
    licensePlate: "",
    engineDisplacement: "",
    regSeries: "",
    franchise: "",
  });
  const [franchiseId, setFranchiseId] = useState("");
  const [carPrice, setCarPrice] = useState("");
  const [carColors, setCarColors] = useState([]);
  const [interiorColor, setInteriorColor] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [countryId, setCountryId] = useState<any>({});
  const [states, setStates] = useState<any>([]);
  const [stateId, setStateId] = useState("");
  const [cities, setCities] = useState<any>([]);
  const [cityId, setCityId] = useState("");
  const [carCondition, setCarCondition] = useState("");
  const [alert, setAlert]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [resData, setResData]: any = useState({});
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageResUrl, setImageResUrl] = useState("");
  const [franchiseDetails, setFranchiseDetails]: any = useState("");
  const [bodyType, setBodyType]: any = useState();
  const [bodyTypeId, setBodyTypeId]: any = useState();
  const [engineType, setEngineType] = useState("");
  const [carTrim, setCarTrim] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [carData, setCarData]: any = useState([]);
  const [franchiseData, setFranchiseData]: any = useState([]);
  const [mileageUnit, setMileageUnit] = useState("km");
  const [regStatus, setRegStatus] = useState(2);
  const [isCarUpgraded, setIsCarUpgraded] = useState(2);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("colors");
  const { t: t2 } = useTranslation("car-upload");

  useEffect(() => {
    document.title = `Dealer Plus | Inventory [${role}]`;
  }, [role]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t2("you-can-only-upload-jgp"));
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(t2("image-must-smaller-than-5mb"));
    }
    return isJpgOrPng && isLt2M;
  };

  const regex = /^[0-9a-zA-Z]{15,17}$/;

  const yearsArray = generateArrayOfYears().map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  const { isLoading: loadCarMakes } = useQuery({
    queryKey: ["get_cars_makes", country],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
      });
      return client.get(`/v1/inventory/make`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carMakes = parsedResults?.makeList?.map((make: ICarMakes) => {
        return {
          label: make?.name,
          value: make?.id,
        };
      });
      setCarMakes(carMakes);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadCarModel } = useQuery({
    queryKey: ["get_cars_models", country, carMakeId],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
        make_id: carMakeId,
      });
      return client.get(`/v1/inventory/model`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carModels = parsedResults?.modelList?.map((model: ICarModels) => {
        return {
          label: model?.name,
          value: model?.id,
        };
      });
      setCarModels(carModels);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!carMakeId,
  });

  const { isLoading: loadCarColors } = useQuery({
    queryKey: ["get_cars_colors"],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/marketplace/colors`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carColors = parsedResults?.colors?.map((color: string) => {
        return {
          label: t1(color),
          value: color,
        };
      });
      setCarColors(carColors);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_country_id", country],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/country/${country}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCountryId(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadStates } = useQuery({
    queryKey: ["get_states", countryId],
    queryFn: () => {
      const params = new URLSearchParams({
        country_id: countryId?.id,
        page_size: "1000",
      });
      return client.get(`/v1/state`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const states = parsedResults?.states?.map((state: any) => {
        return {
          label: state?.name,
          value: state?.id,
        };
      });
      setStates(states);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!countryId,
  });

  const { isLoading: loadCities } = useQuery({
    queryKey: ["get_cities", stateId],
    queryFn: () => {
      const params = new URLSearchParams({
        state_id: stateId,
        page_size: "1000",
      });
      return client.get(`/v1/city`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const cities = parsedResults?.cities?.map((city: any) => {
        return {
          label: city?.name,
          value: city?.id,
        };
      });
      setCities(cities);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!stateId,
  });

  useQuery({
    queryKey: ["get_franchise_by_ids"],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/franchise/${user?.company?.id}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setFranchiseDetails(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadBodyType } = useQuery({
    queryKey: ["get_body_type"],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
      });
      return client.get(`/v1/inventory/body_type`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const bodyType = parsedResults?.bodyTypeList?.map((type: any) => {
        return {
          label: type?.name,
          value: type?.id,
        };
      });
      setBodyType(bodyType);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadingVin } = useQuery({
    queryKey: ["fetch_inventory_cars_for_vin", inputValue?.vinNumber],
    queryFn: () => {
      const params = new URLSearchParams({
        vin: inputValue?.vinNumber,
        page_size: "10",
      });
      return client.get(`/v1/inventory/car`, { params });
    },
    onSuccess: (res: any) => {
      const parsedCars = JSON.parse(res?.data);
      setCarData(parsedCars);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadingFranchise } = useQuery({
    queryKey: ["fetch_franchise", inputValue?.franchise],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        search: inputValue?.franchise,
        page_size: "10000",
        // account_manager_id: isAccountManager ? user.id : "",
        dsa_id: isDealerDSA ? user.id : "",
      });
      return client.get(`/v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedFranchise = JSON.parse(res?.data);
      const franchises = parsedFranchise?.result.map((franchise: any) => {
        return {
          label: franchise?.name,
          value: `${franchise?.id},${franchise?.owner}`,
        };
      });
      setFranchiseData(franchises);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleBrandSearch = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarMakeId(newValue?.value);
  };

  const handleModelSearch = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarModelId(newValue?.value);
  };

  const handleYearChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarYear(newValue?.value);
  };

  const handleInteriorColor = (e: React.ChangeEvent<{}>, newValue: any) => {
    setInteriorColor(newValue?.value);
  };

  const handleExteriorColor = (e: React.ChangeEvent<{}>, newValue: any) => {
    setExteriorColor(newValue?.value);
  };

  const handleStatesChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setStateId(newValue?.value);
    setStateName(newValue?.label);
  };

  const handleCitiesChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCityId(newValue?.value);
    setCityName(newValue?.label);
  };

  const handleCarCondition = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarCondition(newValue?.value);
  };

  const handleBodyType = (e: React.ChangeEvent<{}>, newValue: any) => {
    setBodyTypeId(newValue?.value);
  };

  const handleEngineType = (e: React.ChangeEvent<{}>, newValue: any) => {
    setEngineType(newValue?.value);
  };

  const handleCarTrim = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarTrim(newValue?.value);
  };

  const handleFuelType = (e: React.ChangeEvent<{}>, newValue: any) => {
    setFuelType(newValue?.value);
  };

  const handleFranchiseSelect = (e: React.ChangeEvent<{}>, newValue: any) => {
    setFranchiseId(newValue?.value);
  };

  const handleMileageUnit = (value: any) => {
    setMileageUnit(value);
  };

  const handleRegStatusChange = (e: RadioChangeEvent) => {
    setRegStatus(e.target.value);
    if (e.target.value === 2) {
      inputValue["licensePlate"] = "";
    }
  };

  const handleCarUpgradedChange = (e: RadioChangeEvent) => {
    setIsCarUpgraded(e.target.value);
    if (e.target.value === 2) {
      setFromDate("");
      setToDate("");
    }
  };

  const handleFromDate: DatePickerProps["onChange"] = (date, dateString) => {
    setFromDate(dateString);
  };

  const handleToDate: DatePickerProps["onChange"] = (date, dateString) => {
    setToDate(dateString);
  };

  const handleInputValue =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        [name]: e.target.value,
      });
    };

  const handleCarPrice = (value: any) => {
    setCarPrice(value);
  };

  const generateCompressedUrl = async (url: string) => {
    try {
      const img = new Image();
      img.src = url;

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const compressedUrl = ImageLoader({
        src: url,
        width: img.naturalWidth > 1200 ? 1200 : img.naturalWidth,
        quality: 100,
      });

      setImageResUrl(compressedUrl);
      return compressedUrl;
    } catch (error) {
      return "";
    }
  };

  const handleUploadChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadingImage(false);
      message.success(`${info.file.name} file uploaded successfully.`);
      generateCompressedUrl(info?.file?.response?.file?.url);
    }
  };

  const selectAfter = (
    <Select onChange={handleMileageUnit} value={mileageUnit}>
      <Option value="km">Km</Option>
      <Option value="miles">Miles</Option>
    </Select>
  );

  const uploadButton = (
    <div style={{ zIndex: 5 }}>
      {loadingImage && <Loader variant={"secondary"} />}
      {!loadingImage && <PlusOutlined />}

      <div style={{ marginTop: 8 }}>{t("upload")}</div>
    </div>
  );

  const handleSubmitForm = async () => {
    setLoading(true);
    if (!regex.test(inputValue.vinNumber)) {
      setAlert(t("vin-must-not-be-less-than-15"));
      setLoading(false);
      return;
    }
    if (!imageResUrl) {
      setAlert(t("car-image-is-required"));
      setLoading(false);
      return;
    }

    const payload: any = {
      year: parseInt(carYear),
      mileage: parseInt(inputValue?.mileage),
      vin: inputValue?.vinNumber,
      price: parseInt(carPrice),
      model_id: parseInt(carModelId),
      owner_id:
        isAccountManager || isDealerDSA
          ? franchiseId?.split(",")[1]
          : franchiseDetails?.ownerInfo?.id,
      city: cityName,
      state: stateName,
      country: country,
      address: inputValue?.address,
      selling_condition: carCondition,
      interior_color: interiorColor,
      exterior_color: exteriorColor,
      franchise_id:
        isAccountManager || isDealerDSA
          ? franchiseId?.split(",")[0]
          : user?.company?.id,
      image_url: imageResUrl,
      body_type_id: bodyTypeId,
      cc_measurement: parseInt(inputValue?.engineCapacity),
      engine_type: engineType,
      fuel_type: fuelType,
      transmission: carTrim,
      mileage_unit: mileageUnit,
      license_plate: inputValue?.licensePlate,
      car_manager_id:
        isAccountManager || isDealerDSA
          ? franchiseId?.split(",")[0]
          : user?.company?.id,
      is_upgraded: isCarUpgraded === 1,
      upgraded_from: fromDate && parseInt(fromDate),
      upgraded_to: toDate && parseInt(toDate),
      engine_displacement:
        inputValue?.engineDisplacement &&
        parseInt(inputValue?.engineDisplacement),
      registration_series: inputValue?.regSeries,
      created_by: user?.id,
    };

    Object.keys(payload).forEach((item: any) => {
      if (
        payload[item] === undefined ||
        payload[item] === null ||
        payload[item] === ""
      ) {
        delete payload[item];
      }
    });

    try {
      const response: any = await client.post("/v1/inventory/car", payload);
      if (response) {
        setResData(JSON.parse(response?.data));
        setStep(2);
        message.success(t("car-created-successfully"));
        setLoading(false);
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || t("error-creating-car")
      );
      setLoading(false);
    }
  };

  const renderDisplayContent = () => {
    let displayContent;

    if (loadingVin) {
      displayContent = (
        <div className="display-flex">
          <LoadingOutlined />
        </div>
      );
    } else if (carData?.carList?.length === 1) {
      displayContent = (
        <div className="display-flex">
          <CheckCircleFilled style={{ color: "red" }} />
        </div>
      );
    } else if (
      regex.test(inputValue?.vinNumber) &&
      carData?.carList?.length === 0
    ) {
      displayContent = (
        <div className="display-flex">
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        </div>
      );
    }
    return displayContent;
  };

  const renderVinErrorMessage = () => {
    let vinErrorMessage;

    if (
      !regex.test(inputValue?.vinNumber) &&
      inputValue.vinNumber?.length > 0
    ) {
      vinErrorMessage = t("vin-must-not-be-less-than-15");
    } else if (carData?.carList?.length === 1) {
      vinErrorMessage = t("car-with-vin-already-exist");
    }

    return vinErrorMessage;
  };

  return (
    <CreateCarContainer>
      <header>{t("add-a-car")}</header>
      <div className="form-main-wrapper">
        <div className="form-container-page">
          <div className="header">
            {step === 1 && t("car-details")}
            {step === 2 && (
              <button className="flex gap-2" onClick={() => setStep(1)}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
                  alt=""
                />{" "}
                {t("car-images")}
              </button>
            )}
            {step === 3 && (
              <button className="flex gap-2" onClick={() => setStep(2)}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
                  alt=""
                />{" "}
                {t("car-features")}
              </button>
            )}
          </div>

          {step === 1 && (
            <CustomFormContainer>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmitForm}
              >
                <div className="custom-form-container">
                  {!isFranchise && (
                    <CustomFormField>
                      <div className="label">{t("franchise")} *</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="franchise"
                          disablePortal
                          loading={loadingFranchise}
                          options={franchiseData}
                          onChange={handleFranchiseSelect}
                          renderInput={(params) => (
                            <TextField
                              onChange={handleInputValue("franchise")}
                              {...params}
                              placeholder={t("select-franchise")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                  )}
                  <CustomFormField>
                    <div className="label"> {t("car-brand")} *</div>
                    <Autocomplete
                      fullWidth
                      id="make"
                      disablePortal
                      loading={loadCarMakes}
                      options={carMakes}
                      onChange={handleBrandSearch}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          placeholder={t("select-brand")}
                        />
                      )}
                    />
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-model")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="model"
                        disablePortal
                        loading={loadCarModel}
                        options={carModels}
                        onChange={handleModelSearch}
                        disabled={!carMakeId}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-model")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-year")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="year"
                        disablePortal
                        options={yearsArray}
                        onChange={handleYearChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-year")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div
                      className="label"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      {t("car-vin")} *
                      {inputValue?.vinNumber && <>{renderDisplayContent()}</>}
                    </div>
                    <TextField
                      id="outlined-controlled"
                      fullWidth
                      placeholder={t("enter-vin")}
                      inputProps={{ pattern: "/^[0-9a-zA-Z]{15,17}$/" }}
                      onChange={handleInputValue("vinNumber")}
                      value={inputValue?.vinNumber}
                    />
                    <div className="feedback">
                      {renderVinErrorMessage() ?? ""}
                    </div>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-price")} *</div>
                    <TelInputContainer>
                      <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) => {
                          return formatCurrency(value);
                        }}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                        onChange={handleCarPrice}
                        keyboard={false}
                        placeholder={t("enter-price")}
                      />
                    </TelInputContainer>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-body-type")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="bodyType"
                        disablePortal
                        options={bodyType}
                        loading={loadBodyType}
                        onChange={handleBodyType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-body-type")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-engine-type")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="engineType"
                        disablePortal
                        options={[
                          {
                            label: t("2-cylinder(I2)"),
                            value: "2-cylinder(I2)",
                          },
                          {
                            label: t("3-cylinder(I3)"),
                            value: "3-cylinder(I3)",
                          },
                          {
                            label: t("4-cylinder(I4)"),
                            value: "4-cylinder(I4)",
                          },
                          {
                            label: t("5-cylinder(I5)"),
                            value: "5-cylinder(I5)",
                          },
                          {
                            label: t("6-cylinder(I6)"),
                            value: "6-cylinder(I6)",
                          },
                          {
                            label: t("6-cylinder(V6)"),
                            value: "6-cylinder(V6)",
                          },
                          {
                            label: t("8-cylinder(V8)"),
                            value: "8-cylinder(V8)",
                          },
                          {
                            label: t("10-cylinder(V10)"),
                            value: "10-cylinder(V10)",
                          },
                          {
                            label: t("12-cylinder(V12)"),
                            value: "12-cylinder(V12)",
                          },
                          {
                            label: t("4-cylinder(H4)"),
                            value: "4-cylinder(H4)",
                          },
                          {
                            label: t("6-cylinder(H6)"),
                            value: "6-cylinder(H6)",
                          },
                        ]}
                        onChange={handleEngineType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-engine-type")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-engine-capacity")} *</div>
                    <TextField
                      id="outlined-controlled"
                      fullWidth
                      onChange={handleInputValue("engineCapacity")}
                      value={inputValue?.engineCapacity}
                      placeholder={t("enter-engine-capacity")}
                      type="number"
                    />
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-transmission")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="carTrim"
                        disablePortal
                        options={[
                          { label: t("manual"), value: "manual" },
                          { label: t("automatic"), value: "automatic" },
                          { label: t("duplex"), value: "duplex" },
                        ]}
                        onChange={handleCarTrim}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-car-trim")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-fuel-type")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="fuelType"
                        disablePortal
                        options={[
                          { label: t("petrol"), value: "petrol" },
                          { label: t("diesel"), value: "diesel" },
                        ]}
                        onChange={handleFuelType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-fuel-type")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                </div>

                <div className="custom-form-container">
                  <CustomFormField>
                    <div className="label">{t("car-interior-color")} *</div>
                    <Autocomplete
                      fullWidth
                      id="interiorColor"
                      disablePortal
                      loading={loadCarColors}
                      options={carColors}
                      onChange={handleInteriorColor}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("select-color")}
                        />
                      )}
                    />
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("car-exterior-color")} *</div>
                    <Autocomplete
                      fullWidth
                      id="exteriorColor"
                      disablePortal
                      loading={loadCarColors}
                      options={carColors}
                      onChange={handleExteriorColor}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("select-color")}
                        />
                      )}
                    />
                  </CustomFormField>
                  <CustomInputMileage>
                    <div className="label">{t("car-mileage")} *</div>
                    <Input
                      type="number"
                      onChange={handleInputValue("mileage")}
                      value={inputValue.mileage}
                      addonAfter={selectAfter}
                      placeholder={t("enter-mileage")}
                    />
                  </CustomInputMileage>
                  <CustomFormField>
                    <div className="label">{t("car-selling-condition")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="year"
                        disablePortal
                        options={[
                          { label: t("new"), value: "new" },
                          { label: t("foreign"), value: "foreign" },
                          { label: t("locally-used"), value: "local" },
                        ]}
                        onChange={handleCarCondition}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-condition")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("state")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="year"
                        disablePortal
                        loading={loadStates}
                        options={states}
                        onChange={handleStatesChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-state")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("city")} *</div>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        id="year"
                        disablePortal
                        loading={loadCities}
                        options={cities}
                        onChange={handleCitiesChange}
                        disabled={!stateId}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-city")}
                          />
                        )}
                      />
                    </FormControl>
                  </CustomFormField>
                  <CustomFormField>
                    <div className="label">{t("address")} *</div>
                    <TextField
                      id="outlined-controlled"
                      fullWidth
                      onChange={handleInputValue("address")}
                      value={inputValue.address}
                      placeholder={t("enter-address")}
                    />
                  </CustomFormField>

                  {country === "KE" && (
                    <>
                      <CustomFormField>
                        <div className="label">
                          {t("capture-engine-displacement")} *
                        </div>
                        <TextField
                          id="outlined-controlled"
                          fullWidth
                          onChange={handleInputValue("engineDisplacement")}
                          value={inputValue.engineDisplacement}
                          placeholder={t("enter-displacement-value")}
                          type="number"
                        />
                      </CustomFormField>
                      <CustomFormField>
                        <div className="label">
                          {t("capture-registration-series")} *
                        </div>
                        <TextField
                          id="outlined-controlled"
                          fullWidth
                          onChange={handleInputValue("regSeries")}
                          value={inputValue.regSeries}
                          placeholder={t("enter-series-value")}
                          type="text"
                        />
                      </CustomFormField>
                    </>
                  )}
                </div>

                {country === "NG" && (
                  <div style={{ marginBottom: "24px" }}>
                    <CustomFormField style={{ marginBottom: "16px" }}>
                      <div className="label" style={{ marginBottom: "4px" }}>
                        {t("has-the-vehicle-been-upgraded")} *
                      </div>
                      <Radio.Group
                        onChange={handleCarUpgradedChange}
                        value={isCarUpgraded}
                      >
                        <Radio value={1}>{t("yes")}</Radio>
                        <Radio value={2}>{t("no")}</Radio>
                      </Radio.Group>
                    </CustomFormField>
                    {isCarUpgraded === 1 && (
                      <div className="custom-form-container">
                        <CustomFormField>
                          <div className="label">{t("from-date")}</div>
                          <DatePicker
                            picker="year"
                            placeholder={t("select-year")}
                            onChange={handleFromDate}
                          />
                        </CustomFormField>
                        <CustomFormField>
                          <div className="label">{t("to-date")}</div>
                          <DatePicker
                            picker="year"
                            placeholder={t("select-year")}
                            onChange={handleToDate}
                          />
                        </CustomFormField>
                      </div>
                    )}
                  </div>
                )}

                <div style={{ marginBottom: "24px" }}>
                  <CustomFormField style={{ marginBottom: "16px" }}>
                    <div className="label" style={{ marginBottom: "4px" }}>
                      {t("registration-status")} *
                    </div>
                    <Radio.Group
                      onChange={handleRegStatusChange}
                      value={regStatus}
                    >
                      <Radio value={1}>{t("yes")}</Radio>
                      <Radio value={2}>{t("no")}</Radio>
                    </Radio.Group>
                  </CustomFormField>
                  {regStatus === 1 && (
                    <div className="custom-form-container">
                      <CustomFormField>
                        <div className="label">{t("license-plate")}</div>
                        <TextField
                          id="outlined-controlled"
                          fullWidth
                          onChange={handleInputValue("licensePlate")}
                          value={inputValue?.licensePlate}
                          placeholder={t("enter-license-plate")}
                          type="text"
                        />
                      </CustomFormField>
                    </div>
                  )}
                </div>

                <CustomFormField className="mt-5">
                  <div
                    className="label"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("upload-car-image")} *{" "}
                    {imageResUrl && loadingImage ? <LoadingOutlined /> : ""}
                    &nbsp;
                    {imageResUrl && (
                      <Button
                        onClick={() => setImageResUrl("")}
                        className="delete-btn"
                        type="text"
                        icon={<DeleteTwoTone />}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></Button>
                    )}
                  </div>

                  <CustomCarUploadContainer
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingBottom: "0px",
                      padding: "0px",
                    }}
                  >
                    <div className="upload-container">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${API_URL}image/upload`}
                        headers={{
                          authorization: `bearer ${token}`,
                        }}
                        onChange={handleUploadChange}
                        beforeUpload={beforeUpload}
                        accept=".png, .jpg"
                      >
                        {imageResUrl && (
                          <div className="loading-text">
                            <h1>
                              {t("processing-file-for-upload")}
                              <span className="dot-one"> .</span>
                              <span className="dot-two"> .</span>
                              <span className="dot-three"> .</span>
                            </h1>
                          </div>
                        )}

                        {imageResUrl ? (
                          <img
                            // src={ImageLoader({
                            //   src: `${imageResUrl}`,
                            //   width: 400,
                            //   quality: 100,
                            // })}
                            src={imageResUrl}
                            alt="avatar"
                            style={{ width: "100%", zIndex: 2 }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  </CustomCarUploadContainer>
                </CustomFormField>

                {/* <CustomUploader
                  label={"Upload Car Image *"}
                  loading={loadingImage}
                  setLoading={setLoadingImage}
                  image={imageResUrl}
                  setImage={setImageResUrl}
                /> */}

                <CustomFormField>
                  <div className="loading-btn-container">
                    <Button
                      disabled={
                        !carMakeId ||
                        !carModelId ||
                        !carYear ||
                        !inputValue.vinNumber ||
                        !carPrice ||
                        !bodyTypeId ||
                        !engineType ||
                        !inputValue?.engineCapacity ||
                        !carTrim ||
                        !fuelType ||
                        !interiorColor ||
                        !exteriorColor ||
                        !inputValue.mileage ||
                        !carCondition ||
                        !stateId ||
                        !cityId ||
                        !imageResUrl ||
                        (inputValue?.engineDisplacement &&
                          !inputValue?.engineDisplacement) ||
                        (inputValue?.regSeries && !inputValue?.regSeries) ||
                        !inputValue.address ||
                        !regStatus
                      }
                      className="loading-btn"
                      type="primary"
                      onClick={handleSubmitForm}
                    >
                      {loading ? <Loader variant={"secondary"} /> : t("next")}
                    </Button>
                  </div>
                </CustomFormField>
              </Box>
            </CustomFormContainer>
          )}

          {step === 2 && <CarUploads setStep={setStep} resData={resData} />}

          {step === 3 && <CarFeatures resData={resData} />}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!alert}
        autoHideDuration={5000}
        onClose={() => setAlert("")}
      >
        <Alert severity="error" variant="filled">
          {alert}
        </Alert>
      </Snackbar>
    </CreateCarContainer>
  );
};

export default CreateCarForm;
