import styled from "styled-components";
import tw from "tailwind-styled-components";
import { device } from "@/utils/device";

export const Container = tw.div`flex flex-col gap-y-10 justify-center items-center w-full h-screen`;

export const Content = tw.div`w-[100%] lg:w-[420px] h-auto p-10 lg:p-8 bg-white rounded-lg`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
  position: relative;
  .chevron-back {
    position: absolute;
    top: 16px;
    left: 0px;
    cursor: pointer;
  }
`;

export const TelInputContainer = styled.div`
  img {
    width: 21px !important;
    height: 14px !important;
  }
  .ant-input-number .ant-input-number-input {
    height: 54px;
    border-radius: 4px;
    font-size: 16px;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    border-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 45px;
  }
  .ant-input-number-affix-wrapper {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

export const EquityInputNumber = styled.div`
  img {
    width: 21px !important;
    height: 14px !important;
  }
  .ant-input-number .ant-input-number-input {
    height: 45px;
    border-radius: 2px;
    font-size: 16px;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    border-radius: 0px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    width: 45px;
  }
  .ant-input-number-affix-wrapper {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

export const FormContainer = styled.div`
  label {
    font-size: 14px;
    font-weight: 600;
  }
  input {
    font-size: 16px;
  }
  .input-field {
    border-radius: 0.375rem;
    height: 48px;
  }
  .ant-input-affix-wrapper {
    height: 48px;
    border-radius: 0.375rem;
  }
  button {
    height: 48px;
    border: 0;
    width: 100%;
    background: #ffb619;
    border-radius: 60px;
    color: #30345e !important;
    font-weight: 600;
    margin-top: 24px;
  }
`;

export const ForgotPasswordLink = styled.div`
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  color: #2563eb;
  cursor: pointer;
`;

export const Title = tw.p`text-center text-2xl font-semibold`;

export const Description = tw.p`text-gray-400 text-center`;

export const Form = tw.form`mt-0`;

export const Inputs = tw.div`flex flex-col gap-y-6 mb-6`;

export const CustomSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .language-img {
    img {
      width: 32px;
      height: 32px;
    }
  }
  .ant-select {
    height: 40px;
    min-width: 200px;
    width: 100%;
    @media ${device.mobileL} {
      min-width: auto;
      height: auto;
    }
    .ant-select-selector {
      height: 40px;
      border-radius: 2px;
      background: #f8fafc;
      align-items: center;
      color: rgb(48, 52, 94);
      font-size: 16px;
      font-weight: 600;
      @media ${device.mobileL} {
        height: auto;
      }
    }
  }
`;
