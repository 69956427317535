import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Button, message, Checkbox } from "antd";
import { CarFeaturesContainer, CustomFormField } from "./styles";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";

interface IFeatures {
  resData: any;
}

const CarFeatures = ({ resData }: IFeatures) => {
  const { t } = useTranslation("inventory");
  const navigate = useNavigate();
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const [carFeatures, setCarFeatures]: any = useState([]);
  const [checkedCarFeatures, setCheckedCarFeatures] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFeatureCheck = (checkedValues: CheckboxValueType[]) => {
    setCheckedCarFeatures(checkedValues);
  };

  useQuery({
    queryKey: ["get_cars_feature_q", query],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
        ...query,
      });
      return client.get(`/v1/inventory/feature`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarFeatures(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    navigate(
      `/inventory/create-car${paramsObjectToQueryString({
        ...query,
        search: e.target.value,
      })}`
    );
  };

  const handleSaveFeatures = async () => {
    setLoading(true);
    const _data = {
      car_id: resData?.id,
      payload: checkedCarFeatures,
    };

    try {
      const response = await client.post("/v1/inventory/car_feature", _data);
      if (response) {
        message.success("Car features added successfully");
        setLoading(false);
        navigate(`/inventory/${resData?.id}`, { replace: true });
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || "Error adding car features"
      );
      setLoading(false);
    }
  };

  return (
    <CarFeaturesContainer>
      <div className="search-container">
        <div className="label">{t("search-features")}</div>
        <Input
          placeholder={t("search")}
          onChange={handleSearchChange}
          prefix={<SearchOutlined />}
          allowClear
        />
      </div>
      <Checkbox.Group style={{ width: "100%" }} onChange={handleFeatureCheck}>
        <div className="car-features-check-container">
          {carFeatures?.featureList?.map((feature: any) => (
            <div key={feature?.id}>
              <Checkbox value={feature?.id}>{feature?.name}</Checkbox>
            </div>
          ))}
        </div>
      </Checkbox.Group>

      <CustomFormField>
        <div className="loading-btn-container">
          <Button
            disabled={checkedCarFeatures?.length === 0}
            type="primary"
            onClick={handleSaveFeatures}
          >
            {loading ? <Loader variant={"secondary"} /> : t("save")}
          </Button>
        </div>
      </CustomFormField>
    </CarFeaturesContainer>
  );
};

export default CarFeatures;
