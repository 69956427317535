import { useState, useEffect, useRef } from "react";
import storage from "@/lib/storage";
import styled from "styled-components";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCountries,
  getDealerProduct,
  getDealerProductData,
  getStates,
  getCities,
  fetchCountryId,
} from "@/hooks/use-loan";
import { LoanLimitStyled } from "@/layouts/application-layouts/styles";
import { Box, Container, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import tw from "tailwind-styled-components";
import NewUploadButton from "@/components/formElements/new-upload-button";
import InputControl from "@/components/formElements/input-control";
import { useForm } from "react-hook-form";
import InputGroup, { Input } from "@/components/formElements/input-group";
import client from "@/lib/axios-client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCountryName } from "@/utils/getCountryCurrency";
import { Loader } from "../auth/styles/loader";

interface TField {
  options: [];
  fields: [];
  actions: [];
  when: [];
  label: string;
  name: string;
  type: string;
  required: boolean;
  visibility: boolean;
  initialLabel: string;
}

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;

const SubmitButton = styled.div`
  .MuiButtonBase-root {
    background: #ffb619;
    color: #30345e;
    font-weight: 600;

    &:disabled {
      background: #d6dbe2;
    }

    &:hover {
      background: #d89300;
    }
  }
`;

export default function LoanLimitForm() {
  const role = storage.getRole();
  const navigate = useNavigate();
  const { t } = useTranslation("loan-form");
  const { t: t1 } = useTranslation("inventory");
  const country = storage.getCountry();
  const countryName = storage.getCountry();
  const user = storage.getUser();
  const methods = useForm();
  const { control, handleSubmit } = methods;
  let selectOptions: any = {};
  const queryClient = useQueryClient();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanLimitId: any = queryParameters.get("loan_limit");
  const new_loan: any = queryParameters.get("form_type");
  const financeId: any = queryParameters.get("finance_id");
  const [dealerProduct, setDealerProduct]: any = useState({});
  const [productData, setProductData]: any = useState({});
  const [expanded, setExpanded] = useState(0);
  const [completed, setCompleted]: any = useState({});
  const [formData, setFormData] = useState<any>({});
  const [formDocData, setFormDocData] = useState<any>({});
  const [alert, setAlert]: any = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [countries, setCountries]: any = useState([]);
  const [countryId, setCountryId]: any = useState();
  const [state, setState]: any = useState([]);
  const [city, setCity]: any = useState([]);
  const [loanDocumentFields, setLoanDocumentFields]: any = useState();
  const [rerender, setRerender] = useState(false);
  const submitionInProgress = useRef(false);
  const [loading, setLoading] = useState(false);
  const [limitId, setLimitId] = useState("");
  const [limitById, setLimitById]: any = useState([]);
  const [formRender, setFormRender] = useState(false);
  const isFinanceId = loanLimitId || financeId;

  useEffect(() => {
    document.title = `Dealer Plus | Loan Limit Application [${role}]`;
  }, [role]);

  useEffect(() => {
    setLimitId(loanLimitId);
  }, [loanLimitId]);

  useQuery({
    queryKey: ["loan_limit_id", country, isFinanceId],
    queryFn: () => {
      return client.get(`/v1/dealer/loanlimit/${isFinanceId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setLimitById(parsedLoanDetails?.customer?.profile);
    },
    enabled: !!isFinanceId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    let initialData: any = {};

    if (new_loan === "new") {
      setFormRender(true);
    } else {
      for (let i = 0; i < limitById?.length; i++) {
        initialData[limitById[i]?.name] = limitById[i];
        if (i === limitById?.length - 1) {
          setFormRender(true);
        }
      }
    }
    setFormData(initialData ?? {});
  }, [limitById, new_loan]);

  //Loan Sections
  const handleAccordionChange =
    (panel: any) => (event: any, newExpanded: any) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    const getCountry = getCountries();
    getCountry.then((result) => setCountries(JSON.parse(result?.data)));
  }, []);

  useEffect(() => {
    const id = fetchCountryId(country);
    id.then((result) => setCountryId(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    if (countryId) {
      const getState = getStates(countryId?.id);
      countryId?.id &&
        getState.then((result) => setState(JSON.parse(result?.data)));
    }
  }, [countryId]);

  useEffect(() => {
    if (formData?.state?.value) {
      const selectedStateId = state?.states?.find(
        (item: any) => item.name === formData?.state?.value
      )?.id;
      selectedStateId &&
        getCities(selectedStateId).then((result) =>
          setCity(JSON.parse(result?.data))
        );
    }
  }, [formData?.state?.value, state?.states]);

  useEffect(() => {
    const firstName = {
      name: "firstName",
      value: user?.firstname,
      type: "text",
      valid: true,
    };
    const lastName = {
      name: "lastName",
      value: user?.lastname,
      type: "text",
      valid: true,
    };
    const email = {
      name: "email",
      value: user?.company?.email,
      type: "text",
      valid: true,
    };
    const businessName = {
      name: "businessName",
      value: user?.company?.name,
      type: "text",
      valid: true,
    };
    const country = {
      name: "country",
      value: getCountryName(countryName),
      type: "selection",
      valid: true,
    };

    let data: any = { firstName, lastName, email, businessName, country };

    setFormData((state: any) => ({
      ...state,
      ...data,
    }));
  }, [
    user?.company?.name,
    user?.firstname,
    user?.lastname,
    user?.company?.email,
    countryName,
  ]);

  useEffect(() => {
    const product = getDealerProduct(country);
    product.then((result) => setDealerProduct(JSON.parse(result.data)));
  }, [country]);

  useEffect(() => {
    setPageLoading(true);
    if (dealerProduct?.result) {
      const productId = dealerProduct.result.find(
        (x: any) => x.name === "Loan Limit"
      ).id;
      const product = getDealerProductData(
        productId,
        new_loan === "enhancement" ? "true" : ""
      );
      product.then((result) => {
        const parsedData = JSON.parse(result.data);
        const allFieldsInUploadDocument = parsedData?.config?.documents?.filter(
          (item: { label: string }) => item.label.startsWith("Upload Documents")
        );
        setLoanDocumentFields(allFieldsInUploadDocument[0]?.fields);
        setProductData(parsedData);
        setPageLoading(false);
      });
    }
  }, [dealerProduct, new_loan]);

  const handleInputChange = async (e: any) => {
    const { name, value, type, label } = e.target;
    const valueObject = {
      name,
      value,
      type,
      label: label || e.label,
      valid: true,
    };

    setFormData((state: any) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  // Loan Document
  const allFieldsGovtId = loanDocumentFields?.filter((item: { name: string }) =>
    item.name.startsWith("identity")
  );

  const allFieldsWithWorkId = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("workId")
  );

  const allFieldsWithPOF = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("proofOfEmployment")
  );

  const allFieldsWithBankStatement = loanDocumentFields?.filter(
    (item: { name: string }) => item.name.startsWith("businessBankStatement")
  );

  const allFieldsWithMMStatement = loanDocumentFields?.filter(
    (item: { name: string }) => item.name?.startsWith("mobileMoneyStatement")
  );

  const showExtraIDButton = (item: { name: string }) => {
    const visibleIdField = allFieldsGovtId.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleIdFieldsCount = visibleIdField.length / 2;

    return (
      visibleIdFieldsCount < allFieldsGovtId.length / 2 &&
      item.name.endsWith(
        `identity${
          visibleIdFieldsCount < 2 ? "" : visibleIdFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraEmploymentBtn = (item: any) => {
    const visiblePOFField = allFieldsWithPOF.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visiblePOFFieldsCount = visiblePOFField.length / 2;
    return (
      visiblePOFFieldsCount < allFieldsWithPOF.length / 2 &&
      item.name.endsWith(
        `proofOfEmployment${
          visiblePOFFieldsCount < 2 ? "" : visiblePOFFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showWorkIDButton = (item: { name: string }) => {
    const visibleWorkIdField = allFieldsWithWorkId.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleWorkIdFieldsCount = visibleWorkIdField.length / 2;
    return (
      visibleWorkIdFieldsCount < allFieldsWithWorkId.length / 2 &&
      item.name.endsWith(
        `workId${
          visibleWorkIdFieldsCount < 2 ? "" : visibleWorkIdFieldsCount
        }IsPasswordProtected`
      )
    );
  };

  const showExtraBankStatmentBtn = (item: any) => {
    const visibleBankStField = allFieldsWithBankStatement.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleBankStFieldCount = visibleBankStField.length / 2;
    return (
      visibleBankStFieldCount < allFieldsWithBankStatement.length / 2 &&
      item.name.endsWith(
        `businessBankStatement${
          visibleBankStFieldCount < 2 ? "" : visibleBankStFieldCount
        }IsPasswordProtected`
      )
    );
  };

  const addExtraField = async (index: number) => {
    const allFields = loanDocumentFields;
    const hasIdentityGroup = loanDocumentFields[index]?.group === "identity";
    if (hasIdentityGroup) {
      const field1 = {
        ...loanDocumentFields[index + 1],
        visibility: true,
        removeable: true,
      };
      const field2 = {
        ...loanDocumentFields[index + 2],
        visibility: true,
        removeable: true,
      };
      const field3 = {
        ...loanDocumentFields[index + 3],
        visibility: true,
        removeable: true,
      };
      allFields[index + 1] = field1;
      allFields[index + 2] = field2;
      allFields[index + 3] = field3;
    } else {
      const field1 = {
        ...loanDocumentFields[index + 1],
        visibility: true,
        removeable: true,
      };
      const field2 = {
        ...loanDocumentFields[index + 2],
        visibility: true,
        removeable: true,
      };
      allFields[index + 1] = field1;
      allFields[index + 2] = field2;
    }
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  const showExtraMMBtn = (item: any) => {
    const visibleMMField = allFieldsWithMMStatement.filter(
      (item: { visibility: boolean }) => item.visibility
    );
    const visibleMMFieldCount = visibleMMField.length / 2;
    return (
      visibleMMFieldCount < allFieldsWithMMStatement.length / 2 &&
      item.name.endsWith(
        `mobileMoneyStatement${
          visibleMMFieldCount < 2 ? "" : visibleMMFieldCount
        }IsPasswordProtected`
      )
    );
  };

  const removeField = (index: number, initialType: string, item: TField) => {
    const allFields = loanDocumentFields;
    const field1 = {
      ...loanDocumentFields[index],
      visibility: !!item.required,
      type: initialType ?? item.type,
      label: item.initialLabel ?? item.label,
    };
    const field2 = {
      ...loanDocumentFields[index + 1],
      visibility: !!item.required,
    };
    if (new_loan === "new") {
      const field3 = {
        ...loanDocumentFields[index + 2],
        visibility: !!item.required,
      };
      allFields[index + 2] = field3;
    }

    allFields[index] = field1;
    allFields[index + 1] = field2;
    const checkIfpayslipFieldIsPresent = allFields.find((field: TField) =>
      field.name.startsWith("proofOfEmploymentPayslip")
    );
    if (item.label.includes("Payslip 1")) {
      checkIfpayslipFieldIsPresent && allFields.splice(index + 1, 2);

      formData[item.name].value.includes("payslip") &&
        (formData[item.name].value = "");
    }
    if (item.name.startsWith("proofOfEmploymentPayslip")) {
      allFields.splice(index, 1);
    }
    setLoanDocumentFields(allFields);
    setRerender(!rerender);
  };

  const createNewDocObject = ({
    label,
    name,
    type,
    initialType,
    removeable,
    initialLabel,
    required,
    ...props
  }: {
    label: string;
    name: string;
    type: string;
    initialType: string;
    removeable?: boolean;
    initialLabel: string;
    required?: boolean;
  }) => {
    return {
      label: label,
      name: name,
      type: type,
      initialType: initialType,
      initialLabel: initialLabel,
      removeable: removeable,
      required: required,
      ...props,
    };
  };

  const handleDocumentChange = async (
    e: any,
    item?: TField,
    when?: any,
    initialType?: string
  ) => {
    const { name, value, type } = e.target;
    const valueObject = {
      name,
      value: value,
      type: initialType ?? type,
      label: item?.label,
      valid: true,
    };

    const newDocField = createNewDocObject({
      ...item,
      label: `Upload ${value.includes("payslip") ? "Payslip 1" : value}`,
      name: name,
      type: "file",
      initialType: item?.type!,
      initialLabel: item?.label!,
      removeable: true,
      required: item?.required,
    });
    const fieldToBeReplaced = loanDocumentFields.find(
      (field: TField) => field.name === name
    );

    const fieldIndex = loanDocumentFields.indexOf(fieldToBeReplaced);

    const allFields = loanDocumentFields;

    if (name.startsWith("proofOfEmployment") && value.includes("payslip")) {
      allFields[fieldIndex] = newDocField;
      const subsetFields = when[0].fields.map((field: TField) => {
        return {
          ...field,
          required: !!newDocField.required,
          removeable:
            name === "proofOfEmployment" ? false : newDocField.removeable,
        };
      });

      allFields.splice(fieldIndex + 1, 0, ...subsetFields);
      setLoanDocumentFields(allFields);
    }
    if (!name.includes("Password")) {
      if (initialType?.startsWith("file")) {
        allFields[fieldIndex] = newDocField;
        setLoanDocumentFields(allFields);
      }
    }

    setFormDocData((state: {}) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  function checkErrors(data: any = []): string | null {
    let error: string | null = null;

    for (const item of data) {
      if (item.type && item.required && !formData[item.name]?.value) {
        error = `${item.label} is required`;
        break;
      }

      if (item.fields?.length) {
        error = checkErrors(item.fields);
        if (error) break;
      }

      const matchingField = item.when?.find(
        (whenField: any) => whenField.value === formData[item.name]?.value
      );

      const fieldsToCheck = matchingField?.fields;

      if (fieldsToCheck?.length) {
        error = checkErrors(fieldsToCheck);
        if (error) break;
      }
    }

    return error;
  }

  const submit = async (values: any, isDraft = true) => {
    setAlert(null);
    const userId = user?.company?.id;
    const error = checkErrors(productData.config.sections);

    if (error && !isDraft) {
      setAlert(error);
      return;
    }

    if (loading || submitionInProgress.current) {
      return;
    }

    if (isDraft && !userId) {
      return;
    }

    submitionInProgress.current = true;
    !isDraft && setLoading(true);

    //Grab Loan Document Data
    const documents = extractDocumentsFields(values);
    const newFormData = filterValidFormData();
    const payload = createPayload(newFormData, documents, isDraft);
    const updatePayload = createUpdatePayload(newFormData, documents, isDraft);

    const filteredDocuments: any = documents?.filter(
      (doc: any) => doc?.file_url !== undefined
    );

    if (filteredDocuments?.length === 0) {
      delete payload.documents;
      delete updatePayload.documents;
    }

    try {
      const response = limitId
        ? await client.put(`v1/dealer/loanlimit/${limitId}`, updatePayload)
        : await client.post(`v1/dealer/create-loanlimit-request`, payload);
      if (response) {
        const parsedResponse = JSON.parse(response?.data);
        setLimitId(parsedResponse?.id);
        submitionInProgress.current = false;
        queryClient.invalidateQueries({
          queryKey: ["fetch_dealer_limit"],
        });
        queryClient.invalidateQueries({
          queryKey: ["fetch_notifications"],
        });
        setLoading(false);
        !isDraft && navigate(`/dealer/loan-limit/confirmation?path=loan-limit`);
      }
    } catch (error: any) {
      submitionInProgress.current = false;
      let errorMessage = JSON.parse(error?.response?.data);
      setLoading(false);
      setAlert(
        errorMessage.message || typeof errorMessage?.error === "string"
          ? errorMessage?.error
          : "Something went wrong."
      );
    }
  };

  const filterUndefinedValues = (values: any) => {
    const filteredValues = { ...values };
    Object.keys(filteredValues).forEach((key) => {
      if (filteredValues[key] === undefined) {
        delete filteredValues[key];
      }
    });
    return filteredValues;
  };

  const extractDocumentsFields = (values: any) => {
    let filteredValues = filterUndefinedValues(values);
    return Object.entries(filteredValues).map(([key, value]: any) => ({
      name: key,
      file_url: value?.data,
      description: formDocData[key]?.value || key,
      code: key,
      id_number: formDocData[`${key}Number`]?.value || "",
      password:
        formDocData[
          `${key as string}${key.includes("File") ? "" : "File"}Password`
        ]?.value ?? "",
    }));
  };

  const filterValidFormData = () => {
    const validFormData: any = {};
    Object.keys(formData).forEach((item) => {
      if (
        formData[item]?.value !== undefined &&
        formData[item]?.value !== null
      ) {
        validFormData[item] = { ...formData[item] };
        if (validFormData[item].type === "amount") {
          validFormData[item].value = validFormData[item]?.value.replaceAll(
            ",",
            ""
          );
        }
      }
    });
    return validFormData;
  };

  const createPayload = (formData: any, documents: any, isDraft: boolean) => ({
    product_id: productData?.id,
    dealer_id: user?.company?.id,
    country: country,
    profile: Object.values(formData),
    is_draft: isDraft,
    documents: documents,
    is_enhancement: new_loan === "enhancement",
  });

  const createUpdatePayload = (
    formData: any,
    documents: any,
    isDraft: boolean
  ) => ({
    limit_request_id: limitId,
    profile: Object.values(formData),
    is_draft: isDraft,
    documents: documents,
    is_enhancement: new_loan === "enhancement",
  });

  const renderSubmitButton = () => {
    return (
      <Grid container spacing={2} marginTop="16px">
        <Grid item xs={12} sm={12} md={4}>
          <LoadingButton
            variant="contained"
            type="button"
            fullWidth
            color="inherit"
            style={{
              background: "#ffb619",
              color: "#30345E",
              fontWeight: "600",
            }}
            onClick={handleSubmit((data) => submit(data, false))}
          >
            {loading ? <Loader variant="secondary" /> : t("Submit")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  const renderInputControl = (item: any, control: any, index: number) => {
    return (
      <InputControl key={item.name} control={control} name={item.name}>
        {({ value, onChange, onBlur }) =>
          item.visibility && item.type === "file" ? (
            <NewUploadButton
              fileType={item.fileType}
              label={item.label}
              value={value}
              onChange={onChange}
              fieldName={item.name}
              name={item.name}
              channel={item.channel}
              removeable={item.removeable ?? false}
              onRemoveField={() => removeField(index, item.initialType, item)}
            />
          ) : (
            item.visibility && (
              <>
                <Stack>
                  <Box
                    sx={{
                      fontWeight: 600,
                      width: {
                        md: "100%",
                        xs: "100%",
                      },
                      marginY: "9px",
                      cursor: "pointer",
                      display: "block",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    component="label"
                  >
                    <Input
                      formData={formDocData}
                      key={item.name}
                      onBlur={submit}
                      required={item.required}
                      type={
                        item.type === "fileSelection" ? "selection" : item.type
                      }
                      label={item.label}
                      placeholder={item.label}
                      fileType={item.fileType}
                      name={item.name}
                      when={item.when}
                      options={item.options?.length ? item.options : []}
                      onChange={(e: any) => {
                        handleDocumentChange(e, item, item.when, item.type);
                      }}
                      value={formDocData[item.name]?.value}
                      selectOptions={item.options?.length ? item.options : []}
                      visibility={true}
                      innerVisibility={true}
                      fullWidth={item.fullWidth}
                      autoComplete={false}
                      subsection={null}
                      hideSubsection
                      addDirector={undefined}
                      directorCount={undefined}
                    />
                  </Box>
                </Stack>

                {showExtraIDButton(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showWorkIDButton(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showExtraEmploymentBtn(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showExtraBankStatmentBtn(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
                {showExtraMMBtn(item) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingY: "30px",
                    }}
                  >
                    <SubmitButton>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "151px",
                          height: "43px",
                        }}
                        onClick={() => addExtraField(index)}
                      >
                        {t1("Add more")}
                      </LoadingButton>
                    </SubmitButton>
                  </Box>
                )}
              </>
            )
          )
        }
      </InputControl>
    );
  };

  return (
    <main>
      {pageLoading ? (
        <CustomLoading>
          <Loader variant={"secondary"} />
        </CustomLoading>
      ) : (
        <div>
          <button
            className="flex items-center gap-2"
            onClick={() => navigate(-1)}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
              alt="left-arrow"
            />
            <div className="text-lg text-[#30345e] font-semibold">
              {capitalFirstLetter(t1("back"))}
            </div>
          </button>

          <LoanLimitStyled>
            <div className="text-[25.6px] text-[#30345E] text-center font-bold">
              Loan Limit Approval
            </div>
            <Box
              style={{
                backgroundColor: "#F8FAFC",
                marginBottom: "-64px",
                paddingBottom: "64px",
              }}
            >
              <Container maxWidth="md">
                {formRender && (
                  <form>
                    <Stack spacing={5}>
                      <Stack alignItems="center">
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={!!alert}
                          autoHideDuration={5000}
                          onClose={() => setAlert("")}
                        >
                          <Alert severity="error" variant="filled">
                            {alert}
                          </Alert>
                        </Snackbar>
                      </Stack>

                      {productData?.config?.sections?.map(
                        (item: any, index: any) => (
                          <Accordion
                            key={productData?.config?.sections.indexOf(item)}
                            expanded={expanded === index}
                            onChange={handleAccordionChange(index)}
                          >
                            <AccordionSummary
                              expandIcon={
                                completed[index] && expanded !== index ? (
                                  <CheckCircleIcon sx={{ color: "#22C55E" }} />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                textAlign="center"
                              >
                                {item.label}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <InputGroup
                                  label={item.label}
                                  fields={item.fields}
                                  formData={formData}
                                  onBlur={submit}
                                  handleInputChange={handleInputChange}
                                  selectOptions={{
                                    ...selectOptions,
                                    country: countries?.countries,
                                    state: state?.states,
                                    city: city?.cities,
                                  }}
                                  addDirector={null}
                                  directorCount={null}
                                />
                                <Grid container spacing={2} marginTop="16px">
                                  <Grid item xs={12} sm={12} md={4}>
                                    <LoadingButton
                                      variant="contained"
                                      type="button"
                                      fullWidth
                                      color="primary"
                                      style={{
                                        background: "#ffb619",
                                        color: "#30345E",
                                        fontWeight: "600",
                                      }}
                                      loading={loading}
                                      onClick={(e) => {
                                        const error = checkErrors(item.fields);
                                        if (error) {
                                          setAlert(error);
                                          setCompleted((current: any) => ({
                                            ...current,
                                            [index]: false,
                                          }));
                                          return;
                                        }
                                        setExpanded(index + 1);
                                        setCompleted((current: any) => ({
                                          ...current,
                                          [index]: true,
                                        }));
                                      }}
                                    >
                                      {t("Next")}
                                    </LoadingButton>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4}>
                                    <LoadingButton
                                      variant="contained"
                                      type="button"
                                      fullWidth
                                      color="primary"
                                      style={{
                                        background: "#ffb619",
                                        color: "#30345E",
                                        fontWeight: "600",
                                      }}
                                      onClick={(e) => {
                                        const error = checkErrors(item.fields);
                                        if (error) {
                                          setAlert(error);
                                          setCompleted((current: any) => ({
                                            ...current,
                                            [index]: false,
                                          }));
                                          return;
                                        }
                                        setExpanded(index + 1);
                                        setCompleted((current: any) => ({
                                          ...current,
                                          [index]: true,
                                        }));
                                        navigate(financeId ? `/financing` : `/dealer/financing`);
                                      }}
                                    >
                                      {t("Save and Exit")}
                                    </LoadingButton>
                                  </Grid>
                                </Grid>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}

                      {productData?.config?.documents?.map(
                        (item: any, index: number) => (
                          <Accordion
                            key={item.name}
                            expanded={
                              expanded ===
                              productData?.config?.sections?.length + index
                            }
                            onChange={handleAccordionChange(
                              productData?.config?.sections?.length + index
                            )}
                          >
                            <AccordionSummary
                              expandIcon={
                                completed[
                                  productData?.config?.sections?.length + index
                                ] &&
                                expanded !==
                                  productData?.config?.sections?.length +
                                    index ? (
                                  <CheckCircleIcon sx={{ color: "#22C55E" }} />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                            >
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                textAlign="center"
                              >
                                {t(item.label)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {item.fields.map((item: any, index: any) =>
                                renderInputControl(item, control, index)
                              )}

                              {renderSubmitButton()}
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}
                    </Stack>
                  </form>
                )}
              </Container>
            </Box>
          </LoanLimitStyled>
        </div>
      )}
    </main>
  );
}
