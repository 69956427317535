import { Button, Dropdown, type MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import storage from "@l/storage";
import { useTranslation } from "react-i18next";

interface IMenuItems {
  carId: string;
}

export default function WhitelabelMenuItems({ carId }: Readonly<IMenuItems>) {
  const navigate = useNavigate();
  const isAssumedUser = storage.getAssumedUser();
  const { t } = useTranslation("inventory");

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          onClick={() =>
            navigate(`/applications/loans?car_id=${carId}&form_type=new`)
          }
          className="font-semibold text-sm text-[#30345e]"
        >
          Loan Application
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          onClick={() =>
            navigate(
              `/applications/loan-transfer?car_id=${carId}&form_type=new`
            )
          }
          className="font-semibold text-sm text-[#30345e]"
        >
          Dealer Whitelabel
        </button>
      ),
    },
  ];

  return (
    <Dropdown
      className="apply-loan-btn"
      menu={{ items }}
      trigger={["click"]}
      disabled={!!isAssumedUser}
    >
      <Button>{t("apply-for-loan")}</Button>
    </Dropdown>
  );
}
