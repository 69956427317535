import "@a/globals.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorBoundary from "@/components/error-boundary";
import App from "./app";
import "./lib/i18n";
import PageLoader from "./layouts/application-layouts/PageLoader";
import * as serviceWorker from "./registerServiceWorker";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<PageLoader />}>
          <App />
          {/* <ReactQueryDevtools position="bottom-left" initialIsOpen={false} /> */}
        </Suspense>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

serviceWorker.register();
