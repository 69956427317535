import { useState } from "react";
import {
  CDocInput,
  EquityPaymentContainer,
  TableContainer,
  TextContainer,
  ViewButton,
} from "@/layouts/application-layouts/styles";
import storage from "@/lib/storage";
import { Form, message, InputNumber, Input, DatePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import client from "@/lib/axios-client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EquityInputNumber } from "@/pages/auth/styles/sign-in";
import { Loader } from "@/pages/auth/styles/loader";
import UploadCustomsFile from "./UploadCustomsFile";

export default function CustomsDocumentForm() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const country = storage.getCountry();
  const { t } = useTranslation("loan-form");
  const queryParam = new URLSearchParams(window.location.search);
  const car_id = queryParam.get("carId") as string;
  const loan_id = queryParam.get("loanId");
  const [loading, setLoading] = useState(false);
  const [fetchDocuments, setFetchDocuments] = useState([]);
  const isAssumedUser = storage.getAssumedUser();
  const { pathname } = useLocation();
  const isCustomDocPath = pathname === "/applications/custom-document";

  const { isLoading: docsLoading } = useQuery({
    queryKey: ["fetch_customs_document", country, car_id],
    queryFn: () => {
      const params = new URLSearchParams({
        country,
        car_id,
      });
      return client.get(`/v1/inventory/documents`, { params });
    },
    onSuccess: (res: any) => {
      const parsedData = JSON.parse(res?.data);
      setFetchDocuments(parsedData?.customsDocuments);
    },
    enabled: !!car_id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleSubmitCustomsDocument = async (values: any) => {
    setLoading(true);

    const payload = {
      documents: [
        {
          name: "customs-document",
          file_url: values.customs_document_upload[0].response.file.url,
          password: values.password,
          description: "",
          c_number: values.cNumber,
          port_of_entry: values.portOfEntry,
          date_of_entry: moment(values.dateOfEntry.$d).format("YYYY-MM-DD"),
        },
      ],
      car_id: car_id,
      country: country,
      loan_id: loan_id,
    };

    try {
      const response = await client.post(`/v1/inventory/documents`, payload);
      if (response) {
        message.success("Custom document submitted");
        setLoading(false);
        form.resetFields();
        queryClient.invalidateQueries(["fetch_customs_document"]);
        queryClient.invalidateQueries(["loans_by_id"]);
        navigate(`/applications/loans/${loan_id}`);
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  if (docsLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {fetchDocuments?.filter(
        (item: Record<string, any>) => item?.approvalStatus === "Valid"
      )?.length > 0 ? (
        <></>
      ) : (
        <EquityPaymentContainer className="px-0 pt-6 lg:pt-6 pb-4">
          <Form
            name="customs-document-upload-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmitCustomsDocument}
            autoComplete="off"
          >
            <UploadCustomsFile
              name="customs_document_upload"
              label="Upload customs document"
              messageText="Please upload customs document"
              rule={true}
            />

            <div className="text-[#30345e] font-bold text-lg mb-4">
              Additional Information
            </div>

            <Form.Item
              label="C Number"
              name="cNumber"
              rules={[{ required: true, message: "Please enter C number" }]}
            >
              <EquityInputNumber>
                <InputNumber style={{ width: "100%" }} keyboard={false} />
              </EquityInputNumber>
            </Form.Item>

            <CDocInput>
              <Form.Item
                label="Port of Entry"
                name="portOfEntry"
                rules={[
                  { required: true, message: "Please enter port of entry" },
                ]}
              >
                <Input />
              </Form.Item>
            </CDocInput>

            <Form.Item
              label="Date of Entry"
              name="dateOfEntry"
              rules={[
                { required: true, message: "Please enter date of entry" },
              ]}
            >
              <DatePicker className="w-full h-[45px] rounded-sm" />
            </Form.Item>

            <Form.Item label="Password (Optional)" name="password">
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <div className="button-container w-[50%] my-0 mx-auto">
                <button
                  className="text-xs lg:text-sm mt-6 w-full h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                  disabled={!!isAssumedUser}
                >
                  {loading ? <Loader variant={"secondary"} /> : t("Submit")}
                </button>
              </div>
            </Form.Item>
          </Form>
        </EquityPaymentContainer>
      )}

      <div className="w-full h-auto bg-white my-0 mx-auto rounded-lg mt-2">
        {isCustomDocPath && (
          <TextContainer className="pt-6 pb-4 border-b border-solid border-[#E5E7EB]">
            <div className="top-text">Available Customs Document</div>
          </TextContainer>
        )}
        {fetchDocuments?.length > 0 ? (
          <div className={`flex flex-col gap-3.5 px-0 pb-6`}>
            {fetchDocuments?.map((item: Record<string, any>) => (
              <div
                key={item?.id}
                className="flex flex-col divide-y border rounded-lg"
              >
                <div className="flex justify-between items-center p-3">
                  <div className="flex gap-x-2 items-center">
                    <img
                      src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Vector.svg"
                      alt="document"
                    />
                    <div>
                      <p className="text-base font-bold text-[#30345E] mb-1">
                        Customs document
                      </p>
                      <p className="-mt-1 text-xs text-[#30345E]">
                        {item?.createdAt
                          ? moment(item?.createdAt).format("LLLL")
                          : "---"}{" "}
                      </p>
                    </div>
                  </div>
                  <a href={item?.fileUrl} target="_blank" rel="noreferrer">
                    <ViewButton className="px-5">View</ViewButton>
                  </a>
                </div>
                <div className="flex justify-between items-center p-3">
                  <p className="text-base font-bold text-[#30345E]">
                    Approval status
                  </p>
                  <div
                    className={`${
                      item?.approvalStatus === "Valid"
                        ? "text-[#22C55E]"
                        : "text-[#F87171]"
                    } font-bold capitalize`}
                  >
                    {item?.approvalStatus}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col w-full h-[250px] items-center justify-center border">
            <div>
              <img
                src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                alt="caution"
              />
            </div>
            <div className="text-base lg:text-lg text-[#6b7280]">
              No customs documents available.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
