import { Tabs } from "antd";
import EditCarDetailsForm from "@/pages/applications/edit/EditCarDetailsForm";
import AddCarImagesForm from "@/pages/applications/edit/AddCarImagesForm";
import EditCarFeaturesForm from "@/pages/applications/edit/EditCarFeaturesForm";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { EditCarFeaturesTab } from "./styles";

function EditInventory({ car, media, features, refetchCar }: any) {
  const { t } = useTranslation("inventory");

  const items = [
    {
      key: "1",
      label: t("car-details"),
      children: car && <EditCarDetailsForm refetch={refetchCar} car={car} />,
    },
    {
      key: "2",
      label: t("car-images"),
      children: <AddCarImagesForm id={car?.id} media={media} />,
    },
    {
      key: "3",
      label: t("car-features"),
      children: <EditCarFeaturesForm id={car?.id} carFeatures={features} />,
    },
  ];

  return (
    <EditCarFeaturesTab>
      <Tabs tabPosition={isMobile ? "top" : "left"} items={items} />
    </EditCarFeaturesTab>
  );
}

export default EditInventory;
