import { useEffect } from "react";
import storage from "@/lib/storage";
import tw from "tailwind-styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

export type TSubmitPath =
  | "loan-limit"
  | "master-agreement"
  | "dealer-financing";
interface IPath {
  path: TSubmitPath;
}

const Button = tw.button`bg-[#FFB619] text-[#30345E] text-sm font-semibold w-full lg:w-[50%] h-[48px] rounded-full`;
const Title = tw.div`text-[#31355B] text-lg lg:text-xl text-center font-semibold mb-2`;
const Content = tw.div`text-[#6B7280] text-sm lg:text-base font-normal text-center mb-5`;

export default function SubmitConfirmation({ path }: IPath) {
  const role = storage.getRole();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const dealerId = searchParams.get("dealer_id");

  const toFinanceDashboard = () => {
    navigate(dealerId ? `/financing` : `/dealer/financing`);
  };

  useEffect(() => {
    document.title = `Dealer Plus | Confirmation [${role}]`;
  }, [role]);

  const defaultContent = (
    <>
      <Title>Application received</Title>
      <Content>
        Congratulations, your application has been received. <br /> We will
        provide feedback within 24 hours
      </Content>
    </>
  );

  let content;

  if (path === "loan-limit") {
    content = (
      <>
        <Title>Application received</Title>
        <Content>
          Congratulations, your application has been received. <br /> We will
          provide feedback within 24 hours
        </Content>
      </>
    );
  } else if (path === "master-agreement") {
    content = (
      <>
        <Title>Document received</Title>
        <Content>
          Your documents have been received. <br /> You can now start applying
          for loans
        </Content>
      </>
    );
  } else if (path === "dealer-financing") {
    content = (
      <>
        <Title>Application submitted</Title>
        <Content>
          Congratulations, your application has been <br /> received. We will
          provide feedback shortly
        </Content>
      </>
    );
  } else {
    content = defaultContent;
  }

  return (
    <main className="bg-white flex flex-col justify-center items-center w-full lg:w-[40%] min-h-[600px] lg:min-h-[calc(100vh-200px)] h-auto my-0 mx-auto p-6 rounded-md">
      <div>
        <img
          src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/Frame%20(1).png?updatedAt=1704669394093"
          alt="Submit"
          width="205px"
          className="my-0 mx-auto"
        />
      </div>

      {content}

      <Button
        onClick={() => {
          queryClient.invalidateQueries({
            queryKey: ["fetch_dealer_limit", "fetch_notifications"],
          });
          toFinanceDashboard();
        }}
      >
        Go to Financing Dashboard
      </Button>
    </main>
  );
}
