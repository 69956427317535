import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { IDealerLimitResult } from "@/interface/dealer";
import { LIMIT_REQUEST_STATUS } from "@/utils/finance-status";
import storage from "@/lib/storage";

interface INoLoanLimitCard {
  dealerLimit?: IDealerLimitResult;
  limitArray?: IDealerLimitResult[];
}

const Button = tw.button`bg-[#FFB619] w-full lg:w-auto py-[16px] lg:py-[8px] px-[24px] lg:px-[16px] rounded-[60px] text-[#30345E] text-base font-bold`;
const Main = tw.div`flex flex-col lg:flex-row lg:justify-between items-center gap-1 border border-solid border-[#e5e7eb] rounded-lg bg-white py-4 lg:py-2 px-3 lg:px-2.5`;

export default function NoLoanLimitCard({
  dealerLimit,
  limitArray,
}: Readonly<INoLoanLimitCard>) {
  const navigate = useNavigate();
  const isAssumedUser = storage.getAssumedUser();

  const resumeApplicationLink = (data: any) => {
    return limitArray && limitArray?.length > 1
      ? `/dealer/loan-limit?loan_limit=${data?.id}&form_type=enhancement`
      : `/dealer/loan-limit?loan_limit=${data?.id}`;
  };

  const toLoanLimitForm = () => {
    if (dealerLimit?.status === "limit_request_not_completed") {
      navigate(resumeApplicationLink(dealerLimit));
    } else {
      navigate(`/dealer/loan-limit?form_type=new`);
    }
  };

  const getMessageForDealerLimit = () => {
    if (!dealerLimit) {
      return "Do you know you can apply for a loan to buy additional inventory?";
    }

    if (dealerLimit.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED) {
      return "Thank you for applying, we are currently reviewing your application";
    } else if (
      dealerLimit.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_REJECTED
    ) {
      return "Unfortunately, Your loan limit application has not been approved, kindly reapply";
    } else {
      return "Do you know you can apply for a loan to buy additional inventory?";
    }
  };

  const message = getMessageForDealerLimit();

  return (
    <Main>
      <div className="flex flex-col lg:flex-row lg:items-center lg:gap-2">
        <img
          src="https://media.autochek.africa/file/publicAssets/coin-svgrepo.com.png"
          alt="Bank"
          width="48px"
          height="48px"
          className="my-0 mx-auto"
        />
        <div className="text-[#30345E] text-lg font-bold text-center mb-2 lg:mb-0">
          {message}
        </div>
      </div>
      {dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED ? (
        ""
      ) : (
        <Button disabled={!!isAssumedUser} onClick={() => toLoanLimitForm()}>
          {dealerLimit?.status === "limit_request_not_completed"
            ? "Resume Application"
            : "Start Application"}
        </Button>
      )}
    </Main>
  );
}
