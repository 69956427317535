import React from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`bg-white rounded-lg w-full py-4 px-3 h-[8.9375rem] md:h-[8rem] border border-solid border-[#e5e7eb]`;

interface IBenefitCard {
  medalUrl: string;
  dealerStage: string;
  stageDesc: string;
}

export default function BenefitCard({
  medalUrl,
  dealerStage,
  stageDesc,
}: IBenefitCard) {
  return (
    <div>
      <Container className="">
        <div>
          <img
            src={medalUrl}
            className=" w-[2.875rem] h-[2.875rem] "
            alt="medal"
          />
        </div>
        <div className="pt-2">
          <p className="uppercase font-bold text-[#30345E] text-sm leading-relaxed">
            {dealerStage}
          </p>
          <p className="text-xs text-[#6B7280]">{stageDesc}</p>
        </div>
      </Container>
    </div>
  );
}
