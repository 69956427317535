import React, { useState } from "react";
import { Segmented, Tabs } from "antd";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import type { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";

import DealerFinancingList from "./DealerFinancingList";
import CustomerFinancingTable from "./CustomerFinancingTable";
import OutlineButton from "./OutlineButton";
import {
  AppTabContainer,
  CustomDealerLayout,
  CustomSegmentButton,
  ParentTabHeader,
} from "@/layouts/application-layouts/styles";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

export default function DealerHomeFinancing() {
  const { t } = useTranslation("dashboard");
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const dealerQuery: any = queryString.parse(search);
  const country = storage.getCountry();
  const user = storage.getUser();
  const [value, setValue] = useState<string | number>("customerFinancing");
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [fetchFilteredLoans, setFetchFilteredLoans]: any = useState([]);
  const [fetchCarForCashLoans, setFetchCarForCashLoans]: any = useState([]);
  const [fetchDealerLoans, setFetchDealerLoans]: any = useState([]);
  const [parentTabValue, setParentTabValue] = useState("1");
  const [childTabValue, setChildTabValue] = useState("1");
  const [dealerTabValue, setDealerTabValue] = useState("1");
  const [fetchLoanTransferLoans, setFetchLoanTransferLoans]: any = useState([]);

  const handleParentTabChange = (key: string) => {
    setParentTabValue(key);
  };

  const handleChildTabChange = (key: string) => {
    setChildTabValue(key);
  };

  const handleDealerTabChange = (key: string) => {
    setDealerTabValue(key);
  };

  const productNameByLoanType = () => {
    if (parentTabValue === String(1)) {
      return "Decision Engine Loan";
    } else if (parentTabValue === String(2)) {
      return "Logbook Finance";
    } else {
      return "Dealer WhiteLabel";
    }
  };

  const productResultByLoanType = (data: any) => {
    if (parentTabValue === String(1)) {
      setFetchFilteredLoans(data);
    } else if (parentTabValue === String(2)) {
      setFetchCarForCashLoans(data);
    } else {
      setFetchLoanTransferLoans(data);
    }
  };

  let childFinancingTabStatus = "";
  switch (childTabValue) {
    case "1":
      childFinancingTabStatus = "";
      break;
    case "2":
      childFinancingTabStatus = "PENDING_DOCS";
      break;
    case "3":
      childFinancingTabStatus = "IN_PROGRESS";
      break;
    case "4":
      childFinancingTabStatus = "DISBURSED";
      break;
    default:
      childFinancingTabStatus =
        "REJECTED_FINAL_OFFER,REJECTED_CONDITIONAL_OFFER,DECLINED_FINAL_OFFER,DECLINED_CONDITIONAL_OFFER,FINAL_OFFER_REJECTED,CONDITIONAL_OFFER_REJECTED";
      break;
  }

  let dealerFinancingTabStatus = "";
  switch (dealerTabValue) {
    case "1":
      dealerFinancingTabStatus = "";
      break;
    case "2":
      dealerFinancingTabStatus = "DOCUMENT_PENDING,DOCUMENTS_REJECTED";
      break;
    case "3":
      dealerFinancingTabStatus =
        "APPLICATION_COMPLETED,APPLICATION_NOT_COMPLETED,CONDITIONAL_OFFER_RECEIVED,FINAL_OFFER_RECEIVED,REFERRED_CONDITIONAL_OFFER,REFERRED_FINAL_OFFER,DOCUMENT_SUBMITTED,INSPECTION_PENDING,INSPECTION_COMPLETED,INSPECTION_IN_PROGRESS,SIGNED_LOAN_DOCUMENTS_SUBMITTED";
      break;
    case "4":
      dealerFinancingTabStatus = "DISBURSED";
      break;
    default:
      dealerFinancingTabStatus =
        "REJECTED_FINAL_OFFER,REJECTED_CONDITIONAL_OFFER,DECLINED_FINAL_OFFER,DECLINED_CONDITIONAL_OFFER,FINAL_OFFER_REJECTED,CONDITIONAL_OFFER_REJECTED";
      break;
  }

  const { isLoading: dealerFinancingLoading } = useQuery({
    queryKey: [
      "fetch_dealer_financing_table",
      dealerQuery,
      dealerTabValue,
      user?.company?.id,
      dealerFinancingTabStatus,
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        current_page: activePage.currentPage.toString(),
        page_size: activePage.pageSize.toString(),
        status: dealerFinancingTabStatus,
        ...dealerQuery,
      });
      return client.get(`/v1/dealer/${user?.company?.id}/loans`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setFetchDealerLoans(parsedLoans);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: filteredLoanLoading } = useQuery({
    queryKey: [
      "customer_financing_loans",
      country,
      query,
      activePage,
      user,
      parentTabValue,
      childTabValue,
      childFinancingTabStatus,
      productNameByLoanType(),
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        user_id: user?.id,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        hasCommission: "all",
        order: "DESC",
        sort_by: "createdAt",
        source: "",
        productName: productNameByLoanType(),
        ...(parentTabValue === String(1)
          ? {
              status: childFinancingTabStatus,
            }
          : ""),
        ...query,
      });
      return client.get(`/v1/origination/dig/loans`, { params });
    },
    staleTime: 1000,
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      productResultByLoanType(parsedLoans);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const childFinancingTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t(`all`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "2",
      label: t(`pending-docs`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "3",
      label: t(`in-progress`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "4",
      label: t(`disbursed`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "5",
      label: t(`rejected`),
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
  ];

  const parentFinancingTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: <ParentTabHeader>{t("car-loan")}</ParentTabHeader>,
      children: (
        <Tabs
          defaultActiveKey={childTabValue}
          items={childFinancingTabItems}
          onChange={handleChildTabChange}
        />
      ),
    },
    {
      key: "2",
      label: <ParentTabHeader>{t("cash-loan")}</ParentTabHeader>,
      children: (
        <CustomerFinancingTable
          activePage={activePage}
          setActivePage={setActivePage}
          query={query}
          fetchFilteredLoans={fetchCarForCashLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    ...(["NG", "KE"].includes(country)
      ? [
          {
            key: "3",
            label: <ParentTabHeader>{t("Dealer Whitelabel")}</ParentTabHeader>,
            children: (
              <CustomerFinancingTable
                activePage={activePage}
                setActivePage={setActivePage}
                query={query}
                fetchFilteredLoans={fetchLoanTransferLoans}
                filteredLoanLoading={filteredLoanLoading}
                parentTabValue={parentTabValue}
              />
            ),
          },
        ]
      : []),
  ];

  const dealerItems: TabsProps["items"] = [
    {
      key: "1",
      label: t(`all`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "2",
      label: t(`pending-docs`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "3",
      label: t(`in-progress`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "4",
      label: t(`disbursed`),
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
    {
      key: "5",
      label: "Rejected",
      children: (
        <DealerFinancingList
          activePage={activePage}
          setActivePage={setActivePage}
          query={dealerQuery}
          fetchDealerLoans={fetchDealerLoans}
          dealerFinancingLoading={dealerFinancingLoading}
        />
      ),
    },
  ];

  return (
    <main>
      <div className="mb-2.5 text-[#30345E] text-xl font-semibold">
        {t("financing")}
      </div>
      <CustomSegmentButton>
        <Segmented
          value={value}
          onChange={setValue}
          options={[
            {
              label: <div className="p-1">{t("customer-financing")}</div>,
              value: "customerFinancing",
            },
            {
              label: <div className="p-1">{t("dealer-financing")}</div>,
              value: "dealerFinancing",
            },
          ]}
        />
      </CustomSegmentButton>

      {value === "customerFinancing" ? (
        <>
          <div className="flex justify-between items-center mt-5 md:mt-4 mb-3 md:mb-5">
            <div className="text-base text-[#30345E] font-semibold">
              {t("latest-applications")}
            </div>
            <OutlineButton path="/dealer/applications">
              {t("view-all")}
            </OutlineButton>
          </div>
          <CustomDealerLayout>
            <AppTabContainer style={{ padding: "0px" }}>
              <Tabs
                defaultActiveKey={parentTabValue}
                items={parentFinancingTabItems}
                onChange={handleParentTabChange}
              />
            </AppTabContainer>
          </CustomDealerLayout>
        </>
      ) : (
        <div>
          <div className="flex justify-between items-center mt-5 md:mt-4 mb-3">
            <div className="text-base text-[#30345E] font-semibold">
              {t("latest-applications")}
            </div>
            <OutlineButton path="/dealer/applications">
              {t("view-all")}
            </OutlineButton>
          </div>
          <CustomDealerLayout>
            <AppTabContainer>
              <Tabs
                defaultActiveKey={dealerTabValue}
                items={dealerItems}
                onChange={handleDealerTabChange}
              />
            </AppTabContainer>
          </CustomDealerLayout>
        </div>
      )}
    </main>
  );
}
