import { useState } from "react";
import { Tag, Table } from "antd";
import type { TableProps } from "antd";
import { convertCamelCaseString } from "@/utils/convertCamelCase";
import {
  ICustomsDocument,
  IEquityPayment,
  IPpiReport,
  IUploadedDocument,
  IValuationReport,
} from "@/interface/dealer";
import NewDocsUploadModal from "@/layouts/application-layouts/NewDocumentUpload";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { handleFormatDateToDays } from "@/utils/formatDateToDays";
import tw from "tailwind-styled-components";
import UploadSignedDocModal from "@/layouts/application-layouts/UploadSignedDocModal";
import storage from "@/lib/storage";
import formatInt from "@/utils/format-int";

type IDocType =
  | "required"
  | "optional"
  | "loan"
  | "customs"
  | "ppi"
  | "valuation"
  | "equity";

interface IUploadDocCard {
  docuFields: IUploadedDocument[];
  id: string;
  documentList: any;
  loansById: any;
  loanDocs: any;
  type: IDocType;
  loanRequiredDocument: any;
  loanCustomsDoc: any;
  loanPpiDoc: any;
  loanValuationDoc: any;
  equityDoc: IEquityPayment[];
}

const TableContainer = styled.div`
  overflow-x: auto;
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 600;
    background: 0 0;
    border-top: none;
    font-family: switzerFont;
    color: #475467;
    font-size: 14px;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }
  .ant-table-wrapper {
    font-size: 14px;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #f9fafb;
  }
  .ant-pagination .ant-pagination-item-active {
    background-color: #ffffff;
    border: 1px solid #d0d5dd;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #344054;
    font-weight: 500;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    padding-left: 14px;
  }
  /* .ant-table-wrapper .ant-table-cell,
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td,
  .ant-table-wrapper tfoot > tr > th,
  .ant-table-wrapper tfoot > tr > td {
    white-space: nowrap;
  } */
`;

const ViewButton = tw.button`text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed`;

export default function UploadDocCard({
  docuFields,
  id,
  documentList,
  loansById,
  loanDocs,
  type,
  loanRequiredDocument,
  loanCustomsDoc,
  loanPpiDoc,
  loanValuationDoc,
  equityDoc,
}: Readonly<IUploadDocCard>) {
  const [openNewDocModal, setOpenNewDocModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openUploadSignedDoc, setOpenUploadSignedDoc] = useState(false);
  const [modalSignedData, setModalSignedData] = useState({});
  const isAssumedUser = storage.getAssumedUser();

  const showNewDocUploadModal = (data: any) => {
    setOpenNewDocModal(true);
    setModalData(data);
  };

  const handleOpenNewDocModal = () => {
    setOpenNewDocModal(false);
  };

  const handleCloseNewDocModal = () => {
    setOpenNewDocModal(false);
  };

  const showUploadSignedDocModal = (data: any) => {
    setOpenUploadSignedDoc(true);
    setModalSignedData(data);
  };

  const handleOpenUploadSignedDocModal = () => {
    setOpenUploadSignedDoc(true);
  };

  const handleCloseUploadSignedDocModal = () => {
    setOpenUploadSignedDoc(false);
  };

  const getStatusColor = (status: string | undefined): string => {
    switch (status) {
      case "IN-REVIEW":
        return "processing";
      case "APPROVED":
        return "green";
      default:
        return "orange";
    }
  };

  const columns: TableProps<IUploadedDocument>["columns"] = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {convertCamelCaseString(doc?.label)} {doc?.required ? "*" : ""}
        </div>
      ),
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {doc?.password ? doc?.password : "---"}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, doc) => (
        <div className="flex flex-col gap-1.5">
          <Tag color={getStatusColor(doc?.status)} className="uppercase w-fit">
            {doc?.status?.replaceAll("-", " ") || "PENDING"}
          </Tag>
          {doc?.reason && doc?.status === "REJECTED" && (
            <div className="flex gap-2 items-center">
              <ExclamationCircleOutlined />
              <div className="text-sm text-[#8f8f8f] font-semibold">
                {doc?.reason}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, doc) => (
        <div className="flex flex-row gap-2 items-start justify-end">
          {doc?.templateUrl && (
            <a href={doc?.templateUrl} target="_blank" rel="noreferrer">
              <button
                className="text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                disabled={
                  !doc?.templateUrl ||
                  !!loansById?.salesOppId?.trim() ||
                  !!isAssumedUser
                }
              >
                Template
              </button>
            </a>
          )}
          <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
            <button
              className="text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
              disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
            >
              View
            </button>
          </a>
          <button
            onClick={() => showNewDocUploadModal(doc)}
            className="text-sm w-fit bg-[#ffb619] font-semibold text-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
            disabled={
              documentList?.length === 0 ||
              !!loansById?.salesOppId?.trim() ||
              doc?.status === "APPROVED" ||
              !!isAssumedUser
            }
          >
            Upload
          </button>
        </div>
      ),
    },
  ];

  const loanColumns: TableProps<any>["columns"] = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">{doc?.name}</div>
      ),
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {convertCamelCaseString(doc?.partner)}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, doc) => (
        <div className="flex flex-col gap-1.5">
          <Tag color={getStatusColor(doc?.status)} className="uppercase w-fit">
            {doc?.status?.replaceAll("-", " ") || "PENDING"}
          </Tag>
          {doc?.reason && doc?.status === "REJECTED" && (
            <div className="flex gap-2 items-center">
              <ExclamationCircleOutlined />
              <div className="text-sm text-[#8f8f8f] font-semibold">
                {doc?.reason}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "File URL",
      key: "url",
      dataIndex: "url",
      render: (_, doc) => (
        <div className="flex flex-row gap-3 lg:gap-4 items-start">
          <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
            <button
              className="text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] underline p-0 disabled:text-black/25 disabled:underline disabled:cursor-not-allowed"
              disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
            >
              View URL
            </button>
          </a>
          <a href={doc?.signedFileUrl} target="_blank" rel="noreferrer">
            <button
              className="text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] underline p-0 disabled:text-black/25 disabled:underline disabled:cursor-not-allowed"
              disabled={!doc?.signedFileUrl || !!loansById?.salesOppId?.trim()}
            >
              View Signed URL
            </button>
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, doc) => (
        <button
          onClick={() => showUploadSignedDocModal(doc)}
          className="text-sm w-fit bg-[#ffb619] font-semibold text-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
          disabled={
            doc?.status === "APPROVED" ||
            !!loansById?.salesOppId?.trim() ||
            !!isAssumedUser
          }
        >
          Upload
        </button>
      ),
    },
  ];

  const customsColumns: TableProps<ICustomsDocument>["columns"] = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document",
      render: () => (
        <div className="text-sm text-[#8f8f8f] font-semibold">Customs</div>
      ),
    },
    {
      title: "Date created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {doc?.createdAt ? moment(doc?.createdAt).format("LLLL") : "---"}{" "}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, doc) => (
        <div className="flex flex-col gap-1.5">
          <Tag
            color={doc?.approvalStatus === "Valid" ? "success" : "error"}
            className="uppercase w-fit"
          >
            {doc?.approvalStatus}
          </Tag>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, doc) => (
        <div className="flex flex-row gap-3 lg:gap-4 items-start">
          <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
            <button
              className="text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
              disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
            >
              View
            </button>
          </a>
        </div>
      ),
    },
  ];

  const ppiColumns: TableProps<IPpiReport>["columns"] = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document",
      render: () => (
        <div className="text-sm text-[#8f8f8f] font-semibold">PPI report</div>
      ),
    },
    {
      title: "Date created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {doc?.createdAt ? moment(doc?.createdAt).format("LLLL") : "---"}{" "}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, doc) => (
        <div className="flex flex-col gap-1.5">
          {doc?.warrantyResult ? (
            <Tag
              color={doc?.warrantyResult === "fail" ? "error" : "success"}
              className="uppercase w-fit px-2"
            >
              {doc?.warrantyResult}
            </Tag>
          ) : (
            "---"
          )}
        </div>
      ),
    },
    {
      title: "Validity",
      key: "validity",
      dataIndex: "validity",
      render: (_, doc) => (
        <div className="flex flex-col gap-1.5">
          {doc?.pdfReport ? (
            <Tag
              color={
                handleFormatDateToDays(doc?.createdAt) >= 21
                  ? "error"
                  : "success"
              }
              className="uppercase w-fit px-2"
            >
              {handleFormatDateToDays(doc?.createdAt) >= 21
                ? "Expired"
                : "Valid"}
            </Tag>
          ) : (
            "---"
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, doc) => (
        <div className="flex flex-row gap-3 lg:gap-4 items-start">
          <a href={doc?.pdfReport} target="_blank" rel="noreferrer">
            <button
              className="text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
              disabled={!doc?.pdfReport || !!loansById?.salesOppId?.trim()}
            >
              View
            </button>
          </a>
        </div>
      ),
    },
  ];

  const valuationColumns: TableProps<IValuationReport>["columns"] = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document",
      render: () => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          Valuation proof
        </div>
      ),
    },
    {
      title: "Date created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {doc?.createdAt ? moment(doc?.createdAt).format("LLLL") : "---"}{" "}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, doc) => (
        <div className="flex flex-row gap-3 lg:gap-4 items-start">
          <a href={doc?.valuationProofUrl} target="_blank" rel="noreferrer">
            <button
              className="text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
              disabled={
                !doc?.valuationProofUrl || !!loansById?.salesOppId?.trim()
              }
            >
              View
            </button>
          </a>
        </div>
      ),
    },
  ];

  const equityColumns: TableProps<IEquityPayment>["columns"] = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document",
      render: () => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          Payment proof
        </div>
      ),
    },
    {
      title: "Date created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {doc?.createdAt ? moment(doc?.createdAt).format("LLLL") : "---"}{" "}
        </div>
      ),
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {doc?.password || "---"}
        </div>
      ),
    },
    {
      title: "Amount paid",
      key: "amountPaid",
      dataIndex: "amountPaid",
      render: (_, doc) => (
        <div className="text-sm text-[#8f8f8f] font-semibold">
          {formatInt(doc?.amountPaid, true)}
        </div>
      ),
    },
    {
      title: "Paid to dealer",
      key: "paidToDealer",
      dataIndex: "paidToDealer",
      render: (_, doc) => (
        <Tag color="processing" className="uppercase w-fit px-2">
          {doc?.paidToDealer ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, doc) => (
        <div className="flex flex-row gap-3 lg:gap-4 items-start">
          <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
            <button
              className="text-xs lg:text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
              disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
            >
              View
            </button>
          </a>
        </div>
      ),
    },
  ];

  const renderTableColumns = () => {
    switch (type) {
      case "required":
      case "optional":
        return columns;
      case "loan":
        return loanColumns;
      case "customs":
        return customsColumns;
      case "ppi":
        return ppiColumns;
      case "valuation":
        return valuationColumns;
      case "equity":
        return equityColumns;
      default:
        return [];
    }
  };

  const renderTableData = () => {
    switch (type) {
      case "required":
      case "optional":
        return docuFields;
      case "loan":
        return loanDocs;
      case "customs":
        return loanCustomsDoc;
      case "ppi":
        return loanPpiDoc;
      case "valuation":
        return loanValuationDoc;
      case "equity":
        return equityDoc;
      default:
        return [];
    }
  };

  const renderLoanDocumentOnMobile = () => {
    return (
      <div className="flex flex-col gap-4">
        {loanDocs?.map((doc: any) => (
          <div
            key={loanDocs?.indexOf(doc)}
            className="flex flex-col bg-white border border-solid rounded-md divide-y"
          >
            <div className="text-sm text-[#8f8f8f] font-semibold p-2">
              {convertCamelCaseString(doc?.name)}
            </div>
            <div className="flex flex-col md:flex-row gap-2 justify-between px-2 py-3">
              <div>
                <div className="text-sm text-[#8f8f8f] mb-1 font-semibold">
                  Partner
                </div>
                <Tag color="processing" className="uppercase">
                  {convertCamelCaseString(doc?.partner)}
                </Tag>
              </div>
              <div>
                <div className="text-sm text-[#8f8f8f] mb-1 font-semibold">
                  Status
                </div>
                <Tag
                  color={getStatusColor(doc?.status)}
                  className="uppercase w-fit"
                >
                  {doc?.status?.replaceAll("-", " ") || "PENDING"}
                </Tag>
              </div>
            </div>
            {doc?.reason && doc?.status === "REJECTED" && (
              <div className="px-2 py-3">
                <div className="flex items-center gap-1 text-sm mb-1 text-[#8f8f8f] font-semibold">
                  <ExclamationCircleOutlined className="text-[red]" /> Rejection
                  Reason
                </div>
                <div className="text-sm text-[#8f8f8f] font-semibold">
                  {doc?.reason}
                </div>
              </div>
            )}
            <div className="flex flex-row gap-2 items-start px-2 py-3">
              <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
                <button
                  className="text-xs w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                  disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
                >
                  View URL
                </button>
              </a>
              <a href={doc?.signedFileUrl} target="_blank" rel="noreferrer">
                <button
                  className="text-xs w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                  disabled={
                    !doc?.signedFileUrl || !!loansById?.salesOppId?.trim()
                  }
                >
                  View Signed URL
                </button>
              </a>
            </div>
            <div className="flex justify-end px-2 py-3">
              <button
                onClick={() => showUploadSignedDocModal(doc)}
                className="text-sm w-fit bg-[#ffb619] font-semibold text-[#30345e] px-6 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                disabled={
                  doc?.status === "APPROVED" ||
                  !!loansById?.salesOppId?.trim() ||
                  !!isAssumedUser
                }
              >
                Upload
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCustomsDocumentOnMobile = () => {
    return (
      <div className="flex flex-col gap-4">
        {loanCustomsDoc?.map((item: Record<string, any>) => (
          <div
            key={item?.id}
            className="flex flex-col divide-y bg-white border rounded-lg"
          >
            <div className="flex justify-between items-center p-3">
              <div className="flex gap-x-2 items-center">
                <div>
                  <p className="text-sm font-bold text-[#30345E] mb-1">
                    Customs document
                  </p>
                  <p className="-mt-1 text-xs text-[#30345E]">
                    {item?.createdAt
                      ? moment(item?.createdAt).format("LLLL")
                      : "---"}{" "}
                  </p>
                </div>
              </div>
              <a href={item?.fileUrl} target="_blank" rel="noreferrer">
                <ViewButton
                  disabled={!!loansById?.salesOppId?.trim()}
                  className="px-5"
                >
                  View
                </ViewButton>
              </a>
            </div>
            <div className="flex justify-between items-center p-3">
              <p className="text-sm font-bold text-[#30345E]">
                Approval status
              </p>
              <div
                className={`${
                  item?.approvalStatus === "Valid"
                    ? "text-[#22C55E]"
                    : "text-[#F87171]"
                } font-bold capitalize`}
              >
                {item?.approvalStatus}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderPpiDocumentOnMobile = () => {
    return (
      <div>
        {loanPpiDoc?.map((inspectionReport: Record<string, any>) => (
          <div
            key={inspectionReport?.id}
            className="flex flex-col divide-y border rounded-lg bg-white"
          >
            <div className="flex justify-between items-center p-3">
              <div className="flex gap-x-2 items-center">
                <div>
                  <p className="text-sm font-bold text-[#30345E] mb-1">
                    PPI report
                  </p>
                  <p className="-mt-1 text-xs text-[#30345E]">
                    {inspectionReport?.createdAt
                      ? moment(inspectionReport?.createdAt).format("LLLL")
                      : "---"}
                  </p>
                </div>
              </div>
              <a
                href={inspectionReport?.pdfReport}
                target="_blank"
                rel="noreferrer"
              >
                <ViewButton
                  disabled={!!loansById?.salesOppId?.trim()}
                  className="px-5"
                >
                  View
                </ViewButton>
              </a>
            </div>
            <div className="flex justify-between items-center p-3">
              <p className="text-sm font-bold text-[#30345E]">PPI status</p>
              <div>
                {inspectionReport?.warrantyResult === "fail" ? (
                  <div className="flex items-center gap-x-4">
                    <div className="flex gap-x-2">
                      <img
                        src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/Icon.svg"
                        alt="check"
                      />
                      <span className="text-[#F87171] font-bold capitalize">
                        fail
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center gap-x-1">
                    <img
                      src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/check-circle.svg"
                      alt="check"
                    />
                    <span className="text-[#22C55E] font-bold capitalize">
                      pass
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center p-3">
              <p className="text-sm font-bold text-[#30345E]">Validity</p>
              {handleFormatDateToDays(inspectionReport?.createdAt) >= 21 ? (
                <div className="text-[#F87171] font-bold capitalize">
                  Expired
                </div>
              ) : (
                <div className="text-[#22C55E] font-bold capitalize">Valid</div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderValuationDocumentOnMobile = () => {
    return (
      <div>
        {loanValuationDoc?.map((fetchValuations: Record<string, any>) => (
          <div
            key={loanValuationDoc.indexOf(fetchValuations)}
            className="border border-solid border-[#E5E7EB] bg-white px-2 py-3 rounded-md flex flex-col gap-3"
          >
            <div className="flex justify-between items-center w-full gap-2">
              <div className="flex flex-col items-start gap-1">
                <p className="text-sm text-[#6B7280] font-semibold">
                  Valuation proof received
                </p>
                <div className="flex gap-4">
                  <p className="text-sm text-[#6B7280] font-semibold">
                    {fetchValuations?.createdAt
                      ? moment(fetchValuations?.createdAt).format("LLLL")
                      : "---"}{" "}
                  </p>
                </div>
              </div>
              <a
                href={fetchValuations?.valuationProofUrl}
                target="_blank"
                rel="noreferrer"
              >
                <ViewButton
                  disabled={!!loansById?.salesOppId?.trim()}
                  className="px-5"
                >
                  View
                </ViewButton>
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderDocumentsOnMobile = () => {
    return (
      <div className="flex flex-col gap-4">
        {docuFields?.map((doc) => (
          <div
            key={docuFields?.indexOf(doc)}
            className="flex flex-col bg-white divide-y border border-solid rounded-md"
          >
            <div className="text-sm text-[#8f8f8f] font-semibold p-2">
              {convertCamelCaseString(doc?.label)} {doc?.required ? "*" : ""}
            </div>
            <div className="flex justify-between px-2 py-3">
              <div>
                <div className="text-sm text-[#8f8f8f] mb-1 font-semibold">
                  Status
                </div>
                <Tag
                  color={getStatusColor(doc?.status)}
                  className="uppercase w-fit"
                >
                  {doc?.status?.replaceAll("-", " ") || "PENDING"}
                </Tag>
              </div>
              <div>
                <div className="text-sm mb-1 text-[#8f8f8f] font-semibold">
                  Password
                </div>
                <div className="text-sm text-[#8f8f8f] font-semibold">
                  {doc?.password ? doc?.password : "---"}
                </div>
              </div>
            </div>
            {doc?.reason && doc?.status === "REJECTED" && (
              <div className="px-2 py-3">
                <div className="flex items-center gap-1 text-xs mb-1 text-[#8f8f8f] font-semibold">
                  <ExclamationCircleOutlined /> Rejection Reason
                </div>
                <div className="text-sm text-[#8f8f8f] font-semibold">
                  {doc?.reason}
                </div>
              </div>
            )}
            <div className="flex flex-row justify-end gap-2 px-2 py-3 items-start">
              {doc?.templateUrl && (
                <a href={doc?.templateUrl} target="_blank" rel="noreferrer">
                  <button
                    className="text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                    disabled={
                      !doc?.templateUrl ||
                      !!loansById?.salesOppId?.trim() ||
                      !!isAssumedUser
                    }
                  >
                    Template
                  </button>
                </a>
              )}
              <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
                <button
                  className="text-sm w-fit bg-[#fff] font-semibold text-[#30345e] border border-solid border-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                  disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
                >
                  View
                </button>
              </a>
              <button
                onClick={() => showNewDocUploadModal(doc)}
                className="text-sm w-fit bg-[#ffb619] font-semibold text-[#30345e] px-4 py-1 rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                disabled={
                  documentList?.length === 0 ||
                  !!loansById?.salesOppId?.trim() ||
                  !!isAssumedUser ||
                  doc?.status === "APPROVED"
                }
              >
                Upload
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderEquityDocumentOnMobile = () => {
    return (
      <div className="flex flex-col gap-4">
        {equityDoc?.map((doc: IEquityPayment) => (
          <div
            key={doc?.id}
            className="flex flex-col divide-y border rounded-lg bg-white"
          >
            <div className="flex justify-between items-center p-3">
              <div className="flex gap-x-2 items-center">
                <div>
                  <p className="text-sm font-bold text-[#30345E] mb-1">
                    Payment proof
                  </p>
                  <p className="-mt-1 text-xs text-[#30345E]">
                    {doc?.createdAt
                      ? moment(doc?.createdAt).format("LLLL")
                      : "---"}
                  </p>
                </div>
              </div>
              <a href={doc?.fileUrl} target="_blank" rel="noreferrer">
                <ViewButton
                  disabled={!doc?.fileUrl || !!loansById?.salesOppId?.trim()}
                  className="px-5"
                >
                  View
                </ViewButton>
              </a>
            </div>
            {doc?.password && (
              <div className="flex gap-1 p-3">
                <p className="text-sm font-bold text-[#30345E]">Password:</p>
                <div className="text-sm text-[#8f8f8f] font-semibold">
                  {doc?.password || "---"}
                </div>
              </div>
            )}
            <div className="flex justify-between items-center p-3">
              <div>
                <p className="text-sm font-bold text-[#30345E]">Amount paid</p>
                <div className="text-sm text-[#8f8f8f] font-semibold">
                  {formatInt(doc?.amountPaid, true)}
                </div>
              </div>
              <div>
                <p className="text-sm font-bold text-[#30345E]">
                  Paid to dealer
                </p>
                <Tag color="processing" className="uppercase w-fit px-2">
                  {doc?.paidToDealer ? "Yes" : "No"}
                </Tag>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTableOnMobile = () => {
    if (type === "loan") {
      return renderLoanDocumentOnMobile();
    } else if (type === "customs") {
      return renderCustomsDocumentOnMobile();
    } else if (type === "ppi") {
      return renderPpiDocumentOnMobile();
    } else if (type === "valuation") {
      return renderValuationDocumentOnMobile();
    } else if (type === "equity") {
      return renderEquityDocumentOnMobile();
    } else {
      return renderDocumentsOnMobile();
    }
  };

  return (
    <main>
      <div className="hidden md:block">
        <TableContainer>
          <Table
            columns={renderTableColumns()}
            dataSource={renderTableData()}
            pagination={false}
          />
        </TableContainer>
      </div>

      <div className="block md:hidden">{renderTableOnMobile()}</div>

      <NewDocsUploadModal
        handleOk={handleOpenNewDocModal}
        handleCancel={handleCloseNewDocModal}
        isModalOpen={openNewDocModal}
        id={id}
        documentData={modalData}
        loanRequiredDocument={loanRequiredDocument}
      />

      <UploadSignedDocModal
        handleOk={handleOpenUploadSignedDocModal}
        handleCancel={handleCloseUploadSignedDocModal}
        isModalOpen={openUploadSignedDoc}
        id={id}
        documentData={modalSignedData}
      />
    </main>
  );
}
