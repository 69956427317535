import EditInventoryHeader from "@/components/blocks/inventory/EditInventoryHeader";
import Button from "@/components/button";
import ImageUploadInput from "@/components/formElements/image-upload";
import client from "@/lib/axios-client";
import { message } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { device } from "@/utils/device";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const GridForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 4rem;
  width: 65%;
  margin-inline: auto;
  margin-block: 3rem;
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 24px 24px;
    margin-block: unset;
  }
`;

function AddCarImagesForm({ media, id }: any) {
  const [images, setImages] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("inventory");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const addImages = (data: any) => {
    const previousData = images;
    previousData.push(data);
    setImages(previousData);
  };

  const getImageUrl = (name: string) => {
    const mediaList = media?.carMediaList;
    for (const mediaItem of mediaList) {
      if (
        mediaItem.name.toLowerCase().trim() === name.toLocaleLowerCase().trim()
      ) {
        return mediaItem;
      }
    }

    return "";
  };

  const onSubmitData = async () => {
    setLoading(true);
    const payload = {
      car_id: id,
      payload: images,
    };
    try {
      const response: any = await client.post(
        `/v1/inventory/car_media`,
        payload
      );
      if (response) {
        message.success("Car updated successfully");
        setLoading(false);
        queryClient.invalidateQueries(["car_media_by_id"]);
        navigate(`/inventory/${id}`, { replace: true });
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || "Error updating car"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <EditInventoryHeader title={t("car-images")} />
      <GridForm>
        <ImageUploadInput
          name="car Left Front"
          image={getImageUrl("car Left Front")}
          onUploaded={addImages}
          label="Upload left front image"
        />
        <ImageUploadInput
          name="car Right Front"
          image={getImageUrl("car Right Front")}
          onUploaded={addImages}
          label="Upload right front image"
        />
        <ImageUploadInput
          name="car Front"
          image={getImageUrl("car Front")}
          onUploaded={addImages}
          label="Upload front image "
        />
        <ImageUploadInput
          name="car Back"
          image={getImageUrl("car Back")}
          onUploaded={addImages}
          label="Upload back image"
        />
        <ImageUploadInput
          name="car Left Back"
          image={getImageUrl("car Left Back")}
          onUploaded={addImages}
          label="Upload left back image"
        />
        <ImageUploadInput
          name="car Right Back"
          image={getImageUrl("car Right Back")}
          onUploaded={addImages}
          label="Upload right back image"
        />
      </GridForm>
      <div
        style={{
          display: "grid",
          placeContent: "center",
          margin: "24px 0 48px",
        }}
      >
        <Button
          variant="secondary"
          onClick={onSubmitData}
          width={"w-60"}
          isLoading={loading}
          style={{ borderRadius: "60px" }}
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
}

export default AddCarImagesForm;
