import { useState, useEffect } from "react";
import {
  Card,
  Slider,
  Button,
  Radio,
  RadioChangeEvent,
  InputNumber,
} from "antd";
import {
  LoanCalcInputField,
  LoanCalculatorContainer,
} from "@/layouts/application-layouts/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import client from "@/lib/axios-client";
import { Alert } from "@mui/material";
import formatInt from "@/utils/format-int";
import storage from "@l/storage";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useQuery } from "@tanstack/react-query";
import { generateArrayOfYears } from "@/utils/yearsArray";
import { TelInputContainer } from "@/pages/auth/styles/sign-in";
import formatCurrency from "@u/formatCurrency";
import { Loader } from "@/pages/auth/styles/loader";

interface ICarById {
  car: any;
  loading: boolean;
  carId: string | null;
}

interface UpfrontSliderProps {
  minimum: number;
  maximum: number;
}

interface CalculationResponse {
  totalMonthlyInstallment: number;
  purchasePrice: number;
  totalUpfrontPayment: number;
  term: string;
  valueAddedProducts: string[];
  totalMonthlyBenefitsValue: number;
}

interface ICarModels {
  name: string;
  id: number;
}

interface ICarMakes {
  name: string;
  id: number;
}

type IPayload = {
  car_id: string;
  car_price: number;
  vehicle_use: string;
  term: string;
  equity_contribution: number;
  country: string;
  channel: string;
  requested_dealer_discount_percentage: number | string;
};

type IPayload_ = {
  marketplacePrice: number;
  sellingCondition: string;
  make: string;
  model: string;
  engineType: string;
  year: number;
  bodyType: string;
  gradeScore: number;
  requested_dealer_discount_percentage: number | string;
  equity_contribution: number;
  term: string;
  country: string;
};

const LoanCalculator = ({ car, loading, carId }: ICarById) => {
  const location = useLocation();
  const navigate = useNavigate();
  const country = storage.getCountry();
  const { t } = useTranslation("component");
  const { t: t1 } = useTranslation("inventory");
  const isLoanCalculatorPath = location.pathname === "/loan-calculator";
  const [carMakeId, setCarMakeId] = useState("");
  const [carMakeName, setCarMakeName] = useState("");
  const [carModelId, setCarModelId] = useState("");
  const [carYear, setCarYear] = useState("");
  const [carMakes, setCarMakes] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [bodyTypeId, setBodyTypeId] = useState("");
  const [carCondition, setCarCondition] = useState("");
  const [carMarketplacePrice, setCarMarketplacePrice] = useState(0);
  const [engineType, setEngineType] = useState("");
  const [upfrontSliderCalculated, setUpfrontSliderCalculated] =
    useState<UpfrontSliderProps>({ minimum: 0, maximum: 0 });
  const [upfrontSliderValue, setUpfrontSliderValue] = useState(0);
  const [loanTerm, setLoanTerm] = useState("24");
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isCalculatorCovered, setIsCalculatorCovered] = useState(true);
  const [dealerDiscount, setDealerDiscount] = useState("");
  const [calculationResponse, setCalculationResponse] =
    useState<CalculationResponse>({
      totalMonthlyInstallment: 95554,
      purchasePrice: 275417000,
      totalUpfrontPayment: 1700000,
      term: "60",
      valueAddedProducts: [],
      totalMonthlyBenefitsValue: 0,
    });
  const carPrice = carId ? car?.marketplacePrice : carMarketplacePrice;
  const formatter = (value: any) => Math.trunc(value);
  const isAssumedUser = storage.getAssumedUser();

  const toLoan = (id: string) => {
    navigate(`/applications/loans?car_id=${id}&form_type=new`);
  };

  const toSelectCar = () => {
    navigate(`/applications/select-cars`);
  };

  const yearsArray = generateArrayOfYears().map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  const engineTypeArray = [
    {
      label: t("2-cylinder(I2)"),
      value: "2-cylinder(I2)",
    },
    {
      label: t("3-cylinder(I3)"),
      value: "3-cylinder(I3)",
    },
    {
      label: t("4-cylinder(I4)"),
      value: "4-cylinder(I4)",
    },
    {
      label: t("5-cylinder(I5)"),
      value: "5-cylinder(I5)",
    },
    {
      label: t("6-cylinder(I6)"),
      value: "6-cylinder(I6)",
    },
    {
      label: t("6-cylinder(V6)"),
      value: "6-cylinder(V6)",
    },
    {
      label: t("8-cylinder(V8)"),
      value: "8-cylinder(V8)",
    },
    {
      label: t("10-cylinder(V10)"),
      value: "10-cylinder(V10)",
    },
    {
      label: t("12-cylinder(V12)"),
      value: "12-cylinder(V12)",
    },
    {
      label: t("4-cylinder(H4)"),
      value: "4-cylinder(H4)",
    },
    {
      label: t("6-cylinder(H6)"),
      value: "6-cylinder(H6)",
    },
  ];

  const { isLoading: loadCarMakes } = useQuery({
    queryKey: ["get_cars_makes", country],
    queryFn: () => {
      const params = new URLSearchParams({
        // country_code: country,
        // has_inventory: "true",
        page_size: "1000",
      });
      return client.get(`/v1/inventory/make`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carMakes = parsedResults?.makeList?.map((make: ICarMakes) => {
        return {
          label: make?.name,
          value: make?.id,
        };
      });
      setCarMakes(carMakes);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadCarModel } = useQuery({
    queryKey: ["get_cars_models", country, carMakeId],
    queryFn: () => {
      const params = new URLSearchParams({
        country_code: country,
        page_size: "1000",
        make_id: carMakeId,
      });
      return client.get(`/v1/inventory/model`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carModels = parsedResults?.modelList?.map((model: ICarModels) => {
        return {
          label: model?.name,
          value: model?.id,
        };
      });
      setCarModels(carModels);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!carMakeId,
  });

  const { isLoading: loadBodyType } = useQuery({
    queryKey: ["get_body_type"],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
      });
      return client.get(`/v1/inventory/body_type`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const bodyType = parsedResults?.bodyTypeList?.map((type: any) => {
        return {
          label: type?.name,
          value: type?.id,
        };
      });
      setBodyType(bodyType);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const upfrontSliderMinimum = 0.2 * carPrice;
    const upfrontSliderMaximum = 0.7 * carPrice;

    setUpfrontSliderCalculated({
      minimum: upfrontSliderMinimum,
      maximum: upfrontSliderMaximum,
    });

    setUpfrontSliderValue(upfrontSliderMinimum);
  }, [carPrice]);

  const onLoanTermChange = (e: RadioChangeEvent) => {
    setLoanTerm(e.target.value);
  };

  const handleBrandSearch = (e: any, newValue: any) => {
    setCarMakeId(newValue?.value);
    setCarMakeName(newValue?.label);
  };

  const handleModelSearch = (e: any, newValue: any) => {
    setCarModelId(newValue?.label);
  };

  const handleYearChange = (e: any, newValue: any) => {
    setCarYear(newValue?.value);
  };

  const handleBodyType = (e: any, newValue: any) => {
    setBodyTypeId(newValue?.label);
  };

  const handleCarCondition = (e: any, newValue: any) => {
    setCarCondition(newValue?.value);
  };

  const handleCarPrice = (value: any) => {
    setCarMarketplacePrice(value);
  };

  const handleEngineType = (e: any, newValue: any) => {
    setEngineType(newValue?.value);
  };

  const handleDealerDiscount = (e: any, newValue: any) => {
    setDealerDiscount(newValue?.value);
  };

  const handleCalculate = async () => {
    setIsCalculating(true);
    setIsCalculatorCovered(false);
    setErrorMessage(null);

    const requiredFields = [
      { name: "Car make", value: carMakeName },
      { name: "Car model", value: carModelId },
      { name: "Car body type", value: bodyTypeId },
      { name: "Car engine type", value: engineType },
      { name: "Car price", value: carMarketplacePrice },
      { name: "Car year", value: carYear },
      { name: "Car selling condition", value: carCondition },
    ];

    const missingField = requiredFields.find((field) => !field.value);

    if (isLoanCalculatorPath && !carId && missingField) {
      setIsCalculating(false);
      setErrorMessage(`${missingField.name} is required`);
      return;
    }

    const payload: IPayload = {
      car_id: car?.id,
      car_price: Number(carPrice),
      vehicle_use: "Private",
      term: loanTerm,
      equity_contribution: upfrontSliderValue,
      country: country,
      channel: "dealerplus",
      requested_dealer_discount_percentage: dealerDiscount
        ? Number(dealerDiscount) * 0.01
        : "",
    };

    const _payload: IPayload_ = {
      marketplacePrice: carMarketplacePrice,
      sellingCondition: carCondition,
      make: carMakeName,
      model: carModelId,
      engineType: engineType,
      year: parseInt(carYear),
      bodyType: bodyTypeId,
      gradeScore: carCondition === "new" ? 5 : 3,
      requested_dealer_discount_percentage: dealerDiscount
        ? Number(dealerDiscount) * 0.01
        : "",
      equity_contribution: upfrontSliderValue,
      term: loanTerm,
      country: country,
    };

    Object.keys(payload).forEach((item) => {
      const key = item as keyof IPayload;
      if (
        payload[key] === undefined ||
        payload[key] === null ||
        payload[key] === ""
      ) {
        delete payload[key];
      }
    });

    Object.keys(_payload).forEach((item) => {
      const key = item as keyof IPayload_;
      if (
        _payload[key] === undefined ||
        _payload[key] === null ||
        _payload[key] === ""
      ) {
        delete _payload[key];
      }
    });

    async function fetchLoanSimulation(): Promise<void> {
      const url =
        isLoanCalculatorPath && !carId
          ? "/v2/origination/marketplace/estimates"
          : "/v2/origination/loan/simulation";

      try {
        const response = await client.post(
          url,
          isLoanCalculatorPath && !carId ? _payload : payload
        );
        const parsedData = JSON.parse(response?.data);

        setCalculationResponse({
          totalMonthlyInstallment:
            parsedData?.totalMonthlyInstallment ||
            parsedData?.totalMonthlyInstalment ||
            "",
          purchasePrice: parsedData?.purchasePrice || "",
          totalUpfrontPayment: parsedData?.totalUpfrontPayment || "",
          term: parsedData?.term || parsedData?.financeTerm || "",
          valueAddedProducts: parsedData?.valueAddedProducts || [],
          totalMonthlyBenefitsValue:
            parsedData?.totalMonthlyBenefitsValue || "",
        });
        setIsCalculating(false);
      } catch (error) {
        //@ts-ignore
        const errorResp = JSON.parse(error?.response?.data)?.message;
        setErrorMessage(errorResp || "Error calculating loan");
        setIsCalculating(false);
      }
    }

    fetchLoanSimulation();
  };

  const getCalculatorLabel = () => {
    if (country === "SN") {
      return t("lease-calculator");
    } else if (country === "CI") {
      return t("rental-calculator");
    } else {
      return t("loan-calculator");
    }
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div>
      <LoanCalculatorContainer>
        <Card
          className={
            isLoanCalculatorPath
              ? "card-three-card route-card-three-card"
              : "card-three-card"
          }
        >
          <div
            className={
              isLoanCalculatorPath
                ? "loan-title"
                : "loan-title route-loan-title"
            }
          >
            {getCalculatorLabel()}
          </div>
          <div
            className={
              isLoanCalculatorPath
                ? "route-range-slider-container"
                : "range-slider-container"
            }
          >
            <div
              className={
                isLoanCalculatorPath
                  ? "range-slider-card"
                  : "range-slider-card inventory-slider-card"
              }
            >
              {errorMessage !== null && (
                <Alert sx={{ marginBottom: "2px" }} severity="error">
                  {errorMessage}
                </Alert>
              )}

              {isLoanCalculatorPath && (
                <div className="loan-detail-name">
                  {isMobile ? t("loan-preference") : t("loan-details")}
                </div>
              )}

              {isLoanCalculatorPath && !carId && (
                <div className="car-detail-container">
                  <LoanCalcInputField>
                    <div className="label">{t1("car-brand")}</div>
                    <Autocomplete
                      fullWidth
                      id="make"
                      disablePortal
                      loading={loadCarMakes}
                      options={carMakes}
                      onChange={handleBrandSearch}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          placeholder={t("select-brand")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">{t1("car-model")}</div>
                    <Autocomplete
                      fullWidth
                      id="model"
                      disablePortal
                      loading={loadCarModel}
                      options={carModels}
                      onChange={handleModelSearch}
                      disabled={!carMakeId}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("select-model")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">{t1("car-body-type")}</div>
                    <Autocomplete
                      fullWidth
                      id="bodyType"
                      disablePortal
                      options={bodyType}
                      loading={loadBodyType}
                      onChange={handleBodyType}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t1("select-body-type")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">{t1("car-engine-type")}</div>
                    <Autocomplete
                      fullWidth
                      id="engineType"
                      disablePortal
                      options={engineTypeArray}
                      onChange={handleEngineType}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t1("select-engine-type")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">{t1("car-price")}</div>
                    <TelInputContainer>
                      <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) => {
                          return formatCurrency(value);
                        }}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                        onChange={handleCarPrice}
                        keyboard={false}
                        placeholder={t1("enter-price")}
                      />
                    </TelInputContainer>
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">{t1("car-year")}</div>
                    <Autocomplete
                      fullWidth
                      id="year"
                      disablePortal
                      options={yearsArray}
                      onChange={handleYearChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t1("select-year")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">{t1("car-selling-condition")}</div>
                    <Autocomplete
                      fullWidth
                      id="year"
                      disablePortal
                      options={[
                        { label: t1("new"), value: "new" },
                        { label: t1("foreign"), value: "foreign" },
                        { label: t1("locally-used"), value: "local" },
                      ]}
                      onChange={handleCarCondition}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t1("select-condition")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                  <LoanCalcInputField>
                    <div className="label">
                      {t("dealer-discount")} ({t("optional")})
                    </div>
                    <Autocomplete
                      fullWidth
                      id="dealerDiscount"
                      disablePortal
                      options={[
                        { label: "3%", value: "3" },
                        { label: "4%", value: "4" },
                        { label: "5%", value: "5" },
                        { label: "6%", value: "6" },
                        { label: "7%", value: "7" },
                        { label: "8%", value: "8" },
                        { label: "9%", value: "9" },
                        { label: "10%", value: "10" },
                        { label: "11%", value: "11" },
                        { label: "12%", value: "12" },
                        { label: "13%", value: "13" },
                      ]}
                      onChange={handleDealerDiscount}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("select-dealer-discount")}
                        />
                      )}
                    />
                  </LoanCalcInputField>
                </div>
              )}

              {carId && (
                <div
                  className={
                    isLoanCalculatorPath
                      ? "route-car-instal-container"
                      : "car-instal-container"
                  }
                >
                  <div className="installment-amount">
                    <div className="amount">{t("car-value")}</div>
                    <div className="instalment">
                      {formatInt(carPrice, true)}
                    </div>
                  </div>
                </div>
              )}

              <div className="instal-container">
                <div className="installment-loan-amount">
                  <div className="amount">{t("loan-term-optional")}</div>
                </div>
                <Radio.Group
                  onChange={onLoanTermChange}
                  defaultValue={loanTerm}
                >
                  <Radio.Button value="6">{t("6-months")}</Radio.Button>
                  <Radio.Button value="12">{t("12-months")}</Radio.Button>
                  <Radio.Button value="24">{t("24-months")}</Radio.Button>
                  <Radio.Button value="36">{t("36-months")}</Radio.Button>
                  <Radio.Button value="48">{t("48-months")}</Radio.Button>
                </Radio.Group>
              </div>

              <div className="instal-container">
                <div className="installment-loan-amount">
                  <div className="amount">{t("upfront-payment-optional")}</div>
                  <div className="instalment">
                    {formatInt(upfrontSliderValue, true)}
                  </div>
                </div>
                <Slider
                  trackStyle={{ height: 6, top: 5 }}
                  railStyle={{ height: 6, top: 5 }}
                  min={upfrontSliderCalculated?.minimum}
                  max={upfrontSliderCalculated?.maximum}
                  onChange={setUpfrontSliderValue}
                  value={upfrontSliderValue}
                  tooltip={{ formatter }}
                  disabled={!carPrice}
                />
                <div className="installment-loan-amount">
                  <div className="term">{t("min-20-of-car-value")}</div>
                  <div className="term">{t("max-70-of-car-value")}</div>
                </div>
              </div>

              {carId && (
                <LoanCalcInputField>
                  <div className="label">
                    {t("dealer-discount")} ({t("optional")})
                  </div>
                  <Autocomplete
                    fullWidth
                    id="dealerDiscount"
                    disablePortal
                    options={[
                      { label: "3%", value: "3" },
                      { label: "4%", value: "4" },
                      { label: "5%", value: "5" },
                      { label: "6%", value: "6" },
                      { label: "7%", value: "7" },
                      { label: "8%", value: "8" },
                      { label: "9%", value: "9" },
                      { label: "10%", value: "10" },
                      { label: "11%", value: "11" },
                      { label: "12%", value: "12" },
                      { label: "13%", value: "13" },
                    ]}
                    onChange={handleDealerDiscount}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("select-dealer-discount")}
                      />
                    )}
                  />
                </LoanCalcInputField>
              )}

              <Button
                className="submit-btn"
                onClick={handleCalculate}
                disabled={!!isAssumedUser}
              >
                {isCalculating ? (
                  <Loader variant={"secondary"} />
                ) : (
                  t("calculate")
                )}
              </Button>
            </div>

            <div
              className={
                isLoanCalculatorPath
                  ? "estimated-amount-card"
                  : "estimated-amount-card inventory-amount-card"
              }
            >
              {isCalculating || errorMessage !== null || isCalculatorCovered ? (
                <div className="blurry-background"></div>
              ) : (
                <></>
              )}
              <div>
                <div className="estimated-month">
                  {t("estimated-monthly-payment")}
                </div>
                <div className="estimated-amount">
                  {formatInt(
                    calculationResponse?.totalMonthlyInstallment,
                    true
                  )}
                </div>
              </div>
              <div className="key-value-container">
                <div className="key-value-card">
                  <div className="key">{t("car-price")}</div>
                  <div className="value">
                    {formatInt(calculationResponse?.purchasePrice, true)}
                  </div>
                </div>
                <div className="key-value-card">
                  <div className="key">{t("down-payment")}</div>
                  <div className="value">
                    {formatInt(calculationResponse?.totalUpfrontPayment, true)}
                  </div>
                </div>
                <div className="key-value-card">
                  <div className="key">{t("loan-tenure")}</div>
                  <div className="value">
                    {calculationResponse?.term} {t("months")}
                  </div>
                </div>
              </div>
              <div>
                <div className="monthly-includes">
                  {t("monthly-installment-includes")}
                </div>
                <div className="monthly-sentence">
                  {calculationResponse?.valueAddedProducts?.map(
                    (item, index) => {
                      const arrkey = `${index}-${JSON.stringify(item)}`;
                      console.log(arrkey);
                      return (
                        <div className="sentence" key={arrkey}>
                          {t(item)}
                          {index !==
                            calculationResponse?.valueAddedProducts?.length -
                              1 && ","}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="key-value-card">
                <div className="key">{t("benefits-worth")}</div>
                <div className="value">
                  {formatInt(
                    calculationResponse?.totalMonthlyBenefitsValue,
                    true
                  )}{" "}
                  / {t("month")}
                </div>
              </div>
              <Button
                className="apply-loan-btn"
                onClick={() => {
                  isLoanCalculatorPath && !carId
                    ? toSelectCar()
                    : toLoan(car?.id);
                }}
              >
                {t("apply-for-loan")}
              </Button>
              <div className="interest-rate">
                {t("interest-rate-available")}
              </div>
            </div>
          </div>
        </Card>
      </LoanCalculatorContainer>
    </div>
  );
};

export default LoanCalculator;
