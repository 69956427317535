import React, { useState, useEffect } from "react";
import {
  CreateCarContainer,
  CustomFormContainer,
  CustomFormField,
  CustomInputMileage,
} from "@/layouts/application-layouts/styles";
import {
  Button,
  message,
  InputNumber,
  Select,
  Input,
  Radio,
  DatePicker,
} from "antd";
import { LoadingOutlined, CheckCircleFilled } from "@ant-design/icons";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import storage from "@l/storage";
import Box from "@mui/material/Box";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import { generateArrayOfYears } from "@/utils/yearsArray";
import { TelInputContainer } from "../../auth/styles/sign-in";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import type { RadioChangeEvent, DatePickerProps } from "antd";
import EditInventoryHeader from "@/components/blocks/inventory/EditInventoryHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader } from "@/pages/auth/styles/loader";
import { ICarMakes, ICarModels } from "@/interface/dealer";

type props = {
  car: any;
  refetch: () => void;
};

type AutocompleteProps = {
  label: string;
  value: string | number;
};

const EditCarDetailsForm = ({ car, refetch }: props) => {
  const { t } = useTranslation("inventory");
  const { Option } = Select;
  const navigate = useNavigate();
  const role = storage.getRole();
  const user = storage.getUser();
  const country = storage.getCountry();
  const [carMakes, setCarMakes]: any = useState([]);
  const [carModels, setCarModels]: any = useState([]);
  const [carMake, setCarMake] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [carModel, setCarModel] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [carYear, setCarYear] = useState({
    label: "",
    value: "",
  } as any);
  const [inputValue, setInputValue] = useState({
    vinNumber: car.vin,
    address: car.address,
    mileage: car.mileage,
    engineCapacity: car.ccMeasurement,
    reasonForSelling: car.reasonForSelling,
    identityNumber: "",
    licensePlate: "",
    engineDisplacement: "",
    regSeries: "",
    franchise: car?.carManagerId || "",
  });
  const [carPrice, setCarPrice] = useState(car.price);
  const [carColors, setCarColors] = useState([]);
  const [interiorColorObject, setInteriorColorObject] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [exteriorColorObject, setExteriorColorObject] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [countryId, setCountryId] = useState<any>({});
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [cityObject, setCityObject] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [stateObject, setStateObject] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [carConditionObject, setCarConditionObject] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [alert, setAlert]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [franchiseDetails, setFranchiseDetails]: any = useState("");
  const [bodyType, setBodyType]: any = useState([]);
  const [bodyTypeObject, setBodyTypeObject] = useState({
    label: "",
    value: "",
  } as AutocompleteProps);
  const [engineTypeObject, setEngineTypeObject] = useState({
    label: "",
    value: "",
  } as any);
  const [carTrimObject, setCarTrimObject] = useState({
    label: "",
    value: "",
  } as any);
  const [fuelTypeObject, setFuelTypeObject] = useState({
    label: "",
    value: "",
  } as any);
  const [carData, setCarData]: any = useState([]);
  const [mileageUnit, setMileageUnit] = useState("km");
  const [regStatus, setRegStatus] = useState(2);
  const [isCarUpgraded, setIsCarUpgraded] = useState(2);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [vinTouched, setVinTouched] = useState(false);
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isFranchise = storage.utilities.isFranchise();
  const [franchiseData, setFranchiseData]: any = useState([]);
  const [franchiseId, setFranchiseId] = useState<any>({
    label: "",
    value: "",
  } as AutocompleteProps);

  useEffect(() => {
    document.title = `Dealer Plus | Inventory [${role}]`;
  }, [role]);

  const engineTypes: AutocompleteProps[] = [
    {
      label: t("2-cylinder(I2)"),
      value: "2-cylinder(I2)",
    },
    {
      label: t("3-cylinder(I3)"),
      value: "3-cylinder(I3)",
    },
    {
      label: t("4-cylinder(I4)"),
      value: "4-cylinder(I4)",
    },
    {
      label: t("5-cylinder(I5)"),
      value: "5-cylinder(I5)",
    },
    {
      label: t("6-cylinder(I6)"),
      value: "6-cylinder(I6)",
    },
    {
      label: t("6-cylinder(V6)"),
      value: "6-cylinder(V6)",
    },
    {
      label: t("8-cylinder(V8)"),
      value: "8-cylinder(V8)",
    },
    {
      label: t("10-cylinder(V10)"),
      value: "10-cylinder(V10)",
    },
    {
      label: t("12-cylinder(V12)"),
      value: "12-cylinder(V12)",
    },
    {
      label: t("4-cylinder(H4)"),
      value: "4-cylinder(H4)",
    },
    {
      label: t("6-cylinder(H6)"),
      value: "6-cylinder(H6)",
    },
  ];

  const carTrimOptions: AutocompleteProps[] = [
    { label: t("manual"), value: "manual" },
    { label: t("automatic"), value: "automatic" },
    { label: t("duplex"), value: "duplex" },
  ];

  const fuelTypeOptions: AutocompleteProps[] = [
    { label: t("petrol"), value: "petrol" },
    { label: t("diesel"), value: "diesel" },
  ];

  const sellingConditionOptions: AutocompleteProps[] = [
    { label: t("new"), value: "new" },
    { label: t("foreign"), value: "foreign" },
    { label: t("locally-used"), value: "local" },
  ];

  const regex = /^[0-9a-zA-Z]{15,17}$/;

  const yearsArray = generateArrayOfYears().map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  // ALL PREFILLED DATA
  const franchiseIdData = franchiseData?.find(
    (elm: any) => elm?.value === car?.carManagerId
  );

  const carMakeData = carMakes?.find(
    (elm: any) => elm?.value === car?.model?.make?.id
  );

  const carModelData = carModels?.find(
    (elm: any) => elm?.value === car?.model?.id
  );

  const carYearData = yearsArray?.find(
    (elm: any) => elm?.value === car?.year?.toString()
  );

  const carBodyTypeData = bodyType?.find(
    (elm: any) => elm?.value === car?.bodyType?.id
  );

  const carEngineTypeData = engineTypes?.find(
    (elm: any) => elm?.value === car?.engineType
  );

  const carTrimData = carTrimOptions?.find(
    (elm: any) => elm?.value === car?.transmission
  );

  const carFuelTypeData = fuelTypeOptions?.find(
    (elm: any) => elm?.value === car?.fuelType
  );

  const carInteriorColorData = carColors?.find(
    (elm: any) => elm?.value === car?.interiorColor
  );

  const carExteriorColorData = carColors?.find(
    (elm: any) => elm?.value === car?.exteriorColor
  );

  const carSellingConditionsData = sellingConditionOptions?.find(
    (elm: any) => elm?.value === car?.sellingCondition
  );

  const carStatesData = states?.find((elm: any) => elm?.label === car?.state);

  const carCitiesData = cities?.find((elm: any) => elm?.label === car?.city);

  useEffect(() => {
    if (car) {
      setFranchiseId(franchiseIdData);
      setCarMake(carMakeData);
      setCarModel(carModelData);
      setCarYear(carYearData);
      setInputValue({
        vinNumber: car.vin,
        address: car.address,
        mileage: car.mileage,
        engineCapacity: car.ccMeasurement,
        reasonForSelling: car.reasonForSelling,
        identityNumber: "",
        licensePlate: "",
        engineDisplacement: "",
        regSeries: "",
        franchise: car?.carManagerId || "",
      });
      setCarPrice(car?.price);
      setBodyTypeObject(carBodyTypeData);
      setEngineTypeObject(carEngineTypeData);
      setCarTrimObject(carTrimData);
      setFuelTypeObject(carFuelTypeData);
      carInteriorColorData && setInteriorColorObject(carInteriorColorData);
      carExteriorColorData && setExteriorColorObject(carExteriorColorData);
      carSellingConditionsData &&
        setCarConditionObject(carSellingConditionsData);
      setStateObject(carStatesData);
      setCityObject(carCitiesData);
      setMileageUnit(car?.mileageUnit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    car,
    carBodyTypeData,
    carMakeData,
    carInteriorColorData,
    carExteriorColorData,
    carStatesData,
    franchiseIdData,
  ]);

  const { isLoading: loadCarMakes } = useQuery({
    queryKey: ["get_cars_makes", country],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
      });
      return client.get(`/v1/inventory/make`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carMakes = parsedResults?.makeList?.map((make: ICarMakes) => {
        return {
          label: make?.name,
          value: make?.id,
        };
      });
      setCarMakes(carMakes);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: true,
  });

  let carManagerText: any;
  if (isAccountManager) {
    carManagerText = "";
  } else if (inputValue?.franchise !== undefined) {
    carManagerText = inputValue?.franchise;
  } else if (car?.carManagerName) {
    carManagerText = car?.carManagerName;
  } else {
    carManagerText = "";
  }

  const { isLoading: loadingFranchise } = useQuery({
    queryKey: ["fetch_franchise", car, inputValue?.franchise],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        search: carManagerText,
        page_size: "500",
        dsa_id: isDealerDSA ? user.id : "",
      });
      return client.get(`/v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedFranchise = JSON.parse(res?.data);
      const franchises = parsedFranchise?.result.map((franchise: any) => {
        return {
          label: franchise?.name,
          value: franchise?.id,
        };
      });
      setFranchiseData(franchises);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: true,
  });

  const { isLoading: loadCarModel } = useQuery({
    queryKey: ["get_cars_models", country, carMake?.value],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
        make_id: carMake?.value as string,
      });
      return client.get(`/v1/inventory/model`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carModels = parsedResults?.modelList?.map((model: ICarModels) => {
        return {
          label: model?.name,
          value: model?.id,
        };
      });
      setCarModels(carModels);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!carMake?.value,
  });

  const { isLoading: loadCarColors } = useQuery({
    queryKey: ["get_cars_colors"],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/marketplace/colors`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const carColors = parsedResults?.colors?.map((color: string) => {
        return {
          label: color,
          value: color,
        };
      });
      setCarColors(carColors);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_country_id", country],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/country/${country}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCountryId(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadStates } = useQuery({
    queryKey: ["get_states", countryId],
    queryFn: () => {
      const params = new URLSearchParams({
        country_id: countryId?.id,
        page_size: "1000",
      });
      return client.get(`/v1/state`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const states = parsedResults?.states?.map((state: any) => {
        return {
          label: state?.name,
          value: state?.id,
        };
      });
      setStates(states);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!countryId,
  });

  const { isLoading: loadCities } = useQuery({
    queryKey: ["get_cities", stateObject?.value],
    queryFn: () => {
      const params = new URLSearchParams({
        state_id: stateObject?.value as string,
        page_size: "1000",
      });
      return client.get(`/v1/city`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const cities = parsedResults?.cities?.map((city: any) => {
        return {
          label: city?.name,
          value: city?.id,
        };
      });
      setCities(cities);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!stateObject?.value,
  });

  useQuery({
    queryKey: ["get_franchise_by_ids"],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/franchise/${user?.company?.id}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setFranchiseDetails(parsedResults);
    },
    enabled: !!user?.company?.id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadBodyType } = useQuery({
    queryKey: ["get_body_type"],
    queryFn: () => {
      const params = new URLSearchParams({
        page_size: "1000",
      });
      return client.get(`/v1/inventory/body_type`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const bodyType = parsedResults?.bodyTypeList?.map((type: any) => {
        return {
          label: type?.name,
          value: type?.id,
        };
      });
      setBodyType(bodyType);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: loadingVin } = useQuery({
    queryKey: ["fetch_inventory_cars_for_vin"],
    queryFn: () => {
      const params = new URLSearchParams({
        vin: inputValue?.vinNumber,
        page_size: "10",
      });
      return client.get(`/v1/inventory/car`, { params });
    },
    onSuccess: (res: any) => {
      const parsedCars = JSON.parse(res?.data);
      setCarData(parsedCars);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: vinTouched,
  });

  const handleExteriorColor = (e: React.ChangeEvent<{}>, newValue: any) => {
    setExteriorColorObject(newValue);
  };

  const handleStatesChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setStateObject(newValue);
  };

  const handleCitiesChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCityObject(newValue);
  };

  const handleMileageUnitChange = (value: any) => {
    setMileageUnit(value);
  };

  const handleRegStatusChange = (e: RadioChangeEvent) => {
    setRegStatus(e.target.value);
    if (e.target.value === 2) {
      inputValue["licensePlate"] = "";
    }
  };

  const handleCarUpgradedChange = (e: RadioChangeEvent) => {
    setIsCarUpgraded(e.target.value);
    if (e.target.value === 2) {
      setFromDate("");
      setToDate("");
    }
  };

  const handleFromDate: DatePickerProps["onChange"] = (date, dateString) => {
    setFromDate(dateString);
  };

  const handleToDate: DatePickerProps["onChange"] = (date, dateString) => {
    setToDate(dateString);
  };

  const handleCarConditionChange = (
    e: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setCarConditionObject(newValue);
  };

  const handleInteriorColorChange = (
    e: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setInteriorColorObject(newValue);
  };

  const handleFuelTypeChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setFuelTypeObject(newValue);
  };

  const handleCarTrimChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarTrimObject(newValue);
  };

  const handleEngineTypeChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setEngineTypeObject(newValue);
  };

  const handleBodyTypeChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setBodyTypeObject(newValue);
  };

  const handleYearChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarYear(newValue);
  };

  const handleModelChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarModel(newValue);
  };

  const handleBrandChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setCarMake(newValue);
  };

  const handleFranchiseChange = (e: React.ChangeEvent<{}>, values: any) => {
    setFranchiseId(values);
  };

  const handleInputValue =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        [name]: e.target.value,
      });
    };

  const handleCarPriceChange = (value: any) => {
    setCarPrice(value);
  };

  const selectAfter = (
    <Select onChange={handleMileageUnitChange} value={mileageUnit}>
      <Option value="km">Km</Option>
      <Option value="miles">Miles</Option>
    </Select>
  );

  const handleSubmitForm = async () => {
    setLoading(true);
    if (!regex.test(inputValue.vinNumber)) {
      setAlert(t("vin-must-not-be-less-than-15"));
      setLoading(false);
      return;
    }

    const payload: any = {
      year: parseInt(carYear?.value),
      mileage: parseInt(inputValue?.mileage),
      vin: inputValue?.vinNumber,
      price: parseInt(carPrice),
      model_id: carModel?.value,
      owner_id:
        isAccountManager || isDealerDSA
          ? franchiseId?.value
          : franchiseDetails?.ownerInfo?.id,
      city: cityObject?.label,
      state: stateObject?.label,
      country: country,
      address: inputValue?.address,
      selling_condition: carConditionObject?.value,
      interior_color: interiorColorObject.value,
      exterior_color: exteriorColorObject.value,
      franchise_id:
        isAccountManager || isDealerDSA
          ? franchiseId?.value
          : user?.company?.id,
      body_type_id: bodyTypeObject?.value,
      cc_measurement: parseInt(inputValue?.engineCapacity),
      engine_type: engineTypeObject?.value,
      fuel_type: fuelTypeObject?.value,
      reason_for_selling: inputValue?.reasonForSelling,
      transmission: carTrimObject?.value,
      mileage_unit: mileageUnit,
      license_plate: inputValue?.licensePlate,
      engine_number: inputValue?.identityNumber,
      car_manager_id:
        isAccountManager || isDealerDSA
          ? franchiseId?.value
          : user?.company?.id,
      is_upgraded: isCarUpgraded === 1,
      upgraded_from: fromDate && parseInt(fromDate),
      upgraded_to: toDate && parseInt(toDate),
      engine_displacement:
        inputValue?.engineDisplacement &&
        parseInt(inputValue?.engineDisplacement),
      registration_series: inputValue?.regSeries,
    };

    Object.keys(payload).forEach((item: any) => {
      if (
        payload[item] === undefined ||
        payload[item] === null ||
        payload[item] === ""
      ) {
        delete payload[item];
      }
    });

    try {
      const response: any = await client.put(
        `/v1/inventory/car/${car.id}`,
        payload
      );
      if (response) {
        refetch();
        message.success("Car Updated successfully");
        setLoading(false);
        navigate(`/inventory/${car.id}`, { replace: true });
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || "Error updating car"
      );
      setLoading(false);
    }
  };

  let vinIcon;

  if (inputValue?.vinNumber && vinTouched) {
    if (loadingVin) {
      vinIcon = (
        <div className="display-flex">
          <LoadingOutlined />
        </div>
      );
    } else if (carData?.carList?.length === 1) {
      vinIcon = (
        <div className="display-flex">
          <CheckCircleFilled style={{ color: "red" }} />
        </div>
      );
    } else {
      vinIcon = (
        <div className="display-flex">
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        </div>
      );
    }
  }

  let errorMessage;
  const hasVinLengthError =
    inputValue?.vinNumber?.length > 0 && !regex.test(inputValue?.vinNumber);
  const hasDuplicateVinError = carData?.carList?.length === 1;
  if (hasVinLengthError) {
    errorMessage = t("vin-must-not-be-less-than-15");
  } else if (hasDuplicateVinError) {
    errorMessage = t("car-with-vin-already-exist");
  }

  return (
    <>
      <EditInventoryHeader title={t("car-details")} />
      {car && (
        <CreateCarContainer>
          <div className="form-main-wrapper">
            <div className="form-container-page">
              <CustomFormContainer>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmitForm}
                >
                  <div className="custom-form-container">
                    {!isFranchise && (
                      <CustomFormField>
                        <div className="label">{t("franchise")}</div>
                        <FormControl fullWidth>
                          <Autocomplete
                            fullWidth
                            id="franchise"
                            disablePortal
                            loading={loadingFranchise}
                            options={franchiseData}
                            getOptionLabel={(option) => option.label}
                            onChange={handleFranchiseChange}
                            value={franchiseId}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Franchise"
                              />
                            )}
                          />
                        </FormControl>
                      </CustomFormField>
                    )}
                    {
                      <CustomFormField>
                        <div className="label">{t("brand")}</div>
                        <Autocomplete
                          fullWidth
                          id="make"
                          disablePortal
                          loading={loadCarMakes}
                          options={carMakes}
                          value={carMake}
                          getOptionLabel={(option) => option.label}
                          onChange={handleBrandChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              placeholder={t("select-brand")}
                            />
                          )}
                        />
                      </CustomFormField>
                    }
                    <CustomFormField>
                      <div className="label">{t("model")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="model"
                          disablePortal
                          loading={loadCarModel}
                          options={carModels}
                          onChange={handleModelChange}
                          getOptionLabel={(option) => option.label}
                          // disabled={!carMakeId}
                          value={carModel}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-model")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("year")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="year"
                          disablePortal
                          value={carYear}
                          options={yearsArray}
                          onChange={handleYearChange}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-year")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div
                        className="label"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        {t("car-vin")}
                        {inputValue?.vinNumber && vinTouched && vinIcon}
                      </div>
                      <TextField
                        id="outlined-controlled"
                        fullWidth
                        placeholder="Enter VIN"
                        inputProps={{ pattern: "/^[0-9a-zA-Z]{15,17}$/" }}
                        onChange={handleInputValue("vinNumber")}
                        onBlur={() => setVinTouched(true)}
                        value={inputValue?.vinNumber}
                      />
                      <div className="feedback">{errorMessage}</div>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("car-price")}</div>
                      <TelInputContainer>
                        <InputNumber
                          style={{ width: "100%" }}
                          formatter={(value) => {
                            const numberValue =
                              typeof value === "number" ? value : Number(value);
                            return numberValue.toLocaleString("en-US", {
                              minimumFractionDigits: 0,
                            });
                          }}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                          onChange={handleCarPriceChange}
                          keyboard={false}
                          value={carPrice}
                          placeholder={t("enter-price")}
                        />
                      </TelInputContainer>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("body-type")}</div>
                      <FormControl fullWidth>
                        {bodyType && (
                          <Autocomplete
                            fullWidth
                            id="bodyType"
                            disablePortal
                            options={bodyType}
                            loading={loadBodyType}
                            onChange={handleBodyTypeChange}
                            getOptionLabel={(option) => option.label}
                            value={bodyTypeObject}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t("select-body-type")}
                              />
                            )}
                          />
                        )}
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("engine-type")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="engineType"
                          disablePortal
                          options={engineTypes}
                          onChange={handleEngineTypeChange}
                          getOptionLabel={(option) => option.label}
                          value={engineTypeObject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-engine-type")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("engine-capacity")}</div>
                      <TextField
                        id="outlined-controlled"
                        fullWidth
                        onChange={handleInputValue("engineCapacity")}
                        value={inputValue?.engineCapacity}
                        placeholder={t("enter-engine-capacity")}
                        type="number"
                      />
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("car-transmission")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="carTrim"
                          disablePortal
                          options={carTrimOptions}
                          onChange={handleCarTrimChange}
                          getOptionLabel={(option) => option.label}
                          value={carTrimObject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-car-trim")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("fuel-type")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="fuelType"
                          disablePortal
                          options={fuelTypeOptions}
                          onChange={handleFuelTypeChange}
                          getOptionLabel={(option) => option.label}
                          value={fuelTypeObject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-fuel-type")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                  </div>

                  <div className="custom-form-container">
                    <CustomFormField>
                      <div className="label">{t("interior-color")}</div>
                      <Autocomplete
                        fullWidth
                        id="interiorColor"
                        disablePortal
                        loading={loadCarColors}
                        options={carColors}
                        onChange={handleInteriorColorChange}
                        getOptionLabel={(option) => option.label}
                        value={interiorColorObject}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-color")}
                          />
                        )}
                      />
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("exterior-color")}</div>
                      <Autocomplete
                        fullWidth
                        id="exteriorColor"
                        disablePortal
                        loading={loadCarColors}
                        options={carColors}
                        onChange={handleExteriorColor}
                        getOptionLabel={(option) => option.label}
                        value={exteriorColorObject}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t("select-color")}
                          />
                        )}
                      />
                    </CustomFormField>
                    <CustomInputMileage>
                      <div className="label">{t("mileage")}</div>
                      <Input
                        type="number"
                        onChange={handleInputValue("mileage")}
                        value={inputValue.mileage}
                        addonAfter={selectAfter}
                        placeholder={t("enter-mileage")}
                      />
                    </CustomInputMileage>
                    <CustomFormField>
                      <div className="label">{t("selling-condition")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="year"
                          disablePortal
                          options={sellingConditionOptions}
                          onChange={handleCarConditionChange}
                          getOptionLabel={(option) => option.label}
                          value={carConditionObject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-condition")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("state")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="year"
                          disablePortal
                          loading={loadStates}
                          options={states}
                          value={stateObject}
                          onChange={handleStatesChange}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-state")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("city")}</div>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          id="year"
                          disablePortal
                          loading={loadCities}
                          options={cities}
                          value={cityObject}
                          onChange={handleCitiesChange}
                          getOptionLabel={(option) => option.label}
                          // disabled={!stateId || !stateObject.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("select-city")}
                            />
                          )}
                        />
                      </FormControl>
                    </CustomFormField>
                    <CustomFormField>
                      <div className="label">{t("address")}</div>
                      <TextField
                        id="outlined-controlled"
                        fullWidth
                        onChange={handleInputValue("address")}
                        value={inputValue.address}
                        placeholder={t("enter-address")}
                      />
                    </CustomFormField>
                    {country === "KE" && (
                      <>
                        <CustomFormField>
                          <div className="label">
                            {t("capture-engine-displacement")}
                          </div>
                          <TextField
                            id="outlined-controlled"
                            fullWidth
                            onChange={handleInputValue("engineDisplacement")}
                            value={inputValue.engineDisplacement}
                            placeholder={t("enter-displacement-value")}
                            type="number"
                          />
                        </CustomFormField>
                        <CustomFormField>
                          <div className="label">
                            {t("capture-registration-series")}
                          </div>
                          <TextField
                            id="outlined-controlled"
                            fullWidth
                            onChange={handleInputValue("regSeries")}
                            value={inputValue.regSeries}
                            placeholder={t("enter-series-value")}
                            type="text"
                          />
                        </CustomFormField>
                      </>
                    )}
                  </div>

                  {country === "NG" && (
                    <div style={{ marginBottom: "24px" }}>
                      <CustomFormField style={{ marginBottom: "16px" }}>
                        <div className="label" style={{ marginBottom: "4px" }}>
                          {t("has-the-vehicle-been-upgraded")}
                        </div>
                        <Radio.Group
                          onChange={handleCarUpgradedChange}
                          value={isCarUpgraded}
                        >
                          <Radio value={1}>{t("yes")}</Radio>
                          <Radio value={2}>{t("no")}</Radio>
                        </Radio.Group>
                      </CustomFormField>
                      {isCarUpgraded === 1 && (
                        <div className="custom-form-container">
                          <CustomFormField>
                            <div className="label">{t("from-date")}</div>
                            <DatePicker
                              picker="year"
                              placeholder={t("select-year")}
                              onChange={handleFromDate}
                            />
                          </CustomFormField>
                          <CustomFormField>
                            <div className="label">{t("to-date")}</div>
                            <DatePicker
                              picker="year"
                              placeholder={t("select-year")}
                              onChange={handleToDate}
                            />
                          </CustomFormField>
                        </div>
                      )}
                    </div>
                  )}

                  <div style={{ marginBottom: "24px" }}>
                    <CustomFormField style={{ marginBottom: "16px" }}>
                      <div className="label" style={{ marginBottom: "4px" }}>
                        {t("registration-status")}
                      </div>
                      <Radio.Group
                        onChange={handleRegStatusChange}
                        value={regStatus}
                      >
                        <Radio value={1}>{t("yes")}</Radio>
                        <Radio value={2}>{t("no")}</Radio>
                      </Radio.Group>
                    </CustomFormField>
                    {regStatus === 1 && (
                      <div className="custom-form-container">
                        <CustomFormField>
                          <div className="label">{t("license-plate")}</div>
                          <TextField
                            id="outlined-controlled"
                            fullWidth
                            onChange={handleInputValue("licensePlate")}
                            value={inputValue?.licensePlate}
                            placeholder={t("enter-license-plate")}
                            type="text"
                          />
                        </CustomFormField>
                      </div>
                    )}
                  </div>

                  <CustomFormField>
                    <div className="loading-btn-container">
                      <Button
                        className="loading-btn"
                        type="primary"
                        onClick={handleSubmitForm}
                      >
                        {loading ? <Loader variant={"secondary"} /> : t("save")}
                      </Button>
                    </div>
                  </CustomFormField>
                </Box>
              </CustomFormContainer>
            </div>
          </div>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!alert}
            autoHideDuration={5000}
            onClose={() => setAlert("")}
          >
            <Alert severity="error" variant="filled">
              {alert}
            </Alert>
          </Snackbar>
        </CreateCarContainer>
      )}
    </>
  );
};

export default EditCarDetailsForm;
