import client from "@/lib/axios-client";

export const fetchCarDocuments = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/documents`, { params });
};

export const fetchInspectionReport = (
  params: URLSearchParams,
  carId: string
) => {
  return client.get(`/v1/inventory/car/${carId}/inspection/marketplace`, {
    params,
  });
};

export const fetchValuationReport = (
  params: URLSearchParams,
  loanId: string
) => {
  return client.get(`/v2/origination/${loanId}/valuations`, {
    params,
  });
};

export const postTrackerInfo = (data: any) => {
  return client.post("/v1/inventory/trackers", data);
};

export const fetchTrackerInfo = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/trackers`, {
    params,
  });
};

export const updateTrackerInfo = (data: any, id: number) => {
  return client.put(`/v1/inventory/trackers/${id}`, data);
};

export const postCarDocument = (data: any) => {
  return client.post("/v1/inventory/car_document", data);
};

export const fetchCarDocument = (params: URLSearchParams) => {
  return client.get(`/v1/inventory/car_document`, {
    params,
  });
};

export const editCarDocument = (data: any, carId: string) => {
  return client.put(`/v1/inventory/car_document/${carId}`, data);
};

export const fetchLoanUtility = (id: string) => {
  return client.get(`/v1/dealer/loan-utility/${id}`);
};
