import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { TabsProps } from "antd";
import { Tabs, Collapse, Tag, Button } from "antd";
import formatInt from "@/utils/format-int";
import {
  DealerAppTabContainer,
  CustomerCarInfo,
  CollapseContainer,
  TableContainer,
  CustomerDocument,
  GeneralEmptyState,
} from "@/layouts/application-layouts/styles";
import DealerLoanSteps from "@/layouts/application-layouts/DealerLoanSteps";
import { isMobile } from "react-device-detect";
import { useQuery } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import ResumeLoanApplication from "@/layouts/application-layouts/ResumeApplication";
import NoLoanOffers from "@/layouts/application-layouts/NoLoanOffer";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { convertCamelCaseString } from "@/utils/convertCamelCase";
import AdditionalDealerDocModal from "@/layouts/application-layouts/AddedDealerDocsModal";
import DealerLoanOffers from "@/components/DealerLoansOffers";
import { Loader } from "../auth/styles/loader";
import { LoanLimitItem } from "@/interface/dealer";
import { LIMIT_REQUEST_STATUS } from "@/utils/finance-status";

type IDocType = "CAR_DOCUMENTS" | "EXCLUDE_CAR_DOCUMENTS";
type LoanStatus =
  | "CONDITIONAL_OFFER_RECEIVED"
  | "DOCUMENT_SUBMITTED"
  | "INSPECTION_IN_PROGRESS"
  | "INSPECTION_COMPLETED"
  | "FINAL_OFFER_RECEIVED";

type LoansById = {
  status?: LoanStatus;
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DealerLoanInfo = styled.div`
  background: #ffffff;
  height: auto;
  padding: 16px;
  margin-bottom: 24px;
`;

const DealDetails = () => {
  const { id }: any = useParams();
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("loan-form");
  const { t: t2 } = useTranslation("dealer-gamification");
  const { Panel } = Collapse;
  const [loansById, setLoansById] = useState<any>([]);
  const navigate = useNavigate();
  const role = storage.getRole();
  const country = storage.getCountry();
  const [uploadMoreDocs, setUploadMoreDocs] = useState(false);
  const [carDocType, setCarDocType] = useState<string>();
  const [carVin, setCarVin] = useState<string>("");
  const [dealerLimitDocument, setDealerLimitDocument] =
    useState<LoanLimitItem>();
  const user = storage.getUser();
  const { hash } = useLocation();
  const [loansTab, setLoansTab] = useState("1");
  const [tabKey, setTabKey] = useState("1");
  const [dealerConditionalOfferLoans, setDealerConditionalOfferLoans] =
    useState([]);
  const [dealerFinalOfferLoans, setDealerFinalOfferLoans] = useState([]);
  const isAssumedUser = storage.getAssumedUser();

  const handleLoanTabs = (key: string) => {
    navigate({ hash: `${key}` });
    setLoansTab(key);
  };

  useEffect(() => {
    if (hash === "#2") {
      setLoansTab("2");
    }
  }, [loansTab, hash]);

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const { isFetching: loansByDealerLoading } = useQuery({
    queryKey: ["dealer_loans_by_id", country, id],
    queryFn: () => {
      return client.get(`/v1/dealer/loans/${id}`);
    },
    onSuccess: (res: any) => {
      const parsedDealerLoanDetails = JSON.parse(res?.data);
      const conditionalOfferI = parsedDealerLoanDetails?.offers?.filter(
        function (item: any) {
          return item?.offerType === "CONDITIONAL_OFFER";
        }
      );
      const finalOfferI = parsedDealerLoanDetails?.offers?.filter(function (
        item: any
      ) {
        return item?.offerType === "FINAL_OFFER";
      });
      setDealerConditionalOfferLoans(conditionalOfferI);
      setDealerFinalOfferLoans(finalOfferI);
      setLoansById(parsedDealerLoanDetails);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["fetch_dashboard_dealer_limit"],
    queryFn: () => {
      return client.get(`/v1/dealer/${user?.company?.id}`);
    },
    onSuccess: (res: any) => {
      const parsedResponseData: any = JSON.parse(res?.data);
      const filteredParsedData = parsedResponseData?.results?.find(
        (item: any) => item?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
      );
      const limitDocument = filteredParsedData?.loanLimits?.find(
        (item: any) => item?.limitDocuments?.length > 0
      );
      setDealerLimitDocument(limitDocument);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const showMoreDocs = (type: string, vin: string) => {
    setUploadMoreDocs(true);
    setCarDocType(type);
    setCarVin(vin);
  };

  const handleUploadMoreDocs = () => {
    setUploadMoreDocs(false);
  };

  const handleCancelUploadDoc = () => {
    setUploadMoreDocs(false);
  };

  let content;
  if (
    loansById?.productStatus?.name === "APPLICATION_COMPLETED" &&
    loansById?.offers?.length === 0
  ) {
    content = <NoLoanOffers />;
  } else if (loansById?.productStatus?.name === "APPLICATION_NOT_COMPLETED") {
    content = <ResumeLoanApplication loansById={loansById} />;
  } else {
    content = (
      <DealerLoanOffers
        tabKey={tabKey}
        setTabKey={setTabKey}
        id={id}
        loansById={loansById}
        initialOffer={dealerConditionalOfferLoans}
        finalOffer={dealerFinalOfferLoans}
      />
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`loan-details`),
      children: content,
    },
    {
      key: "2",
      label: t(`application-stage`),
      children: <DealerLoanSteps id={id} />,
    },
  ];

  const getInspectionStatusColor = (car: Record<string, any>) => {
    let color = "orange";
    if (car?.inspectionStatus === "completed") {
      color = "green";
    } else if (car?.inspectionStatus === "cancelled") {
      color = "red";
    }
    return color;
  };

  const getDocumentStatus = (car: Record<string, any>) => {
    if (car?.isDocComplete === true) {
      return t2("document-completed");
    } else if (car?.isDocComplete === false) {
      return t2("incomplete-document");
    } else {
      return t2("document-pending");
    }
  };

  if (loansByDealerLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  const docsIsFalse = loansById?.car?.find(
    (item: Record<string, any>) =>
      !item?.isDocComplete && item?.inspectionStatus !== "cancelled"
  );

  const renderNextAction = (
    loansById?: LoansById,
    docsIsFalse?: any
  ): string | null => {
    const messages: { [key in LoanStatus]?: string } = {
      CONDITIONAL_OFFER_RECEIVED: t2("you-have-been-given-a-conditional-offer"),
      DOCUMENT_SUBMITTED: t2(
        "offer-accepted-your-document-is-currently-being-reviewed"
      ),
      INSPECTION_IN_PROGRESS: t2("document-has-been-reviewed"),
      INSPECTION_COMPLETED: docsIsFalse
        ? t2("inspection-completed-please-upload-car-document")
        : t2("inspection-is-completed-you-will-be-sent-a-final-offer-soon"),
      FINAL_OFFER_RECEIVED: t2("you-have-been-given-a-final-offer"),
    };

    const status = loansById?.status;
    return status ? messages[status] ?? null : null;
  };

  const ALLOW_STATUSES: string[] = [
    "CONDITIONAL_OFFER_RECEIVED",
    "DOCUMENT_SUBMITTED",
    "INSPECTION_IN_PROGRESS",
    "INSPECTION_COMPLETED",
    "FINAL_OFFER_RECEIVED",
  ];

  return (
    <Main>
      <div className="flex items-center justify-between gap-6 bg-white px-[12px] py-[16px] md:p-[16px] rounded-lg border-b-[1px] border-[#e5e7eb]">
        <button
          className="flex items-center gap-2 font-semibold text-[#30345e] text-[16px] md:text-[20px] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
            alt="arrow-back"
            className="w-8 h-8 cursor-pointer"
          />{" "}
          <div className="name">
            <span className="capitalize tracking-[0.00938rem] font-bold">
              {t1("back")}
            </span>
          </div>
        </button>
      </div>

      {ALLOW_STATUSES.includes(loansById?.status) && (
        <div className="bg-white border-b border-[#e5e7eb] rounded-lg min-h-[52px] h-auto p-3 md:p-4 flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-0 justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/emoji/checkestepIcon.svg?updatedAt=1711358779069"
                alt="Status"
              />
              <div className="text-sm md:text-base font-semibold text-[#30345e]">
                {renderNextAction(loansById, docsIsFalse)}
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate(`/dealer/applications/loans/${id}/#2`)}
            className="bg-white text-sm border border-solid border-[#30345e] rounded-[60px] h-9 px-3 font-semibold ml-9 md:ml-0"
          >
            {t2("view-all-stages")}
          </button>
        </div>
      )}

      <div>
        <DealerAppTabContainer>
          <div className="flex flex-col xl:flex-row gap-7 xl:gap-6 w-full">
            {loansById?.product?.name === "Repo Finance" && (
              <>
                {isMobile ? (
                  <CollapseContainer>
                    <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
                      <Panel header={t("car-info")} key="1">
                        <CustomerCarInfo>
                          <div className="car-info">
                            <div style={{ marginBottom: "10px" }}>
                              <img
                                src={
                                  loansById?.car[0]?.carDetails?.imageUrl ||
                                  `https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg`
                                }
                                alt="car"
                                width="404px"
                                height="246px"
                                style={{
                                  borderRadius: "7px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div>
                              <div className="detail">
                                <div>{t("car-name")}:</div>
                                <div>
                                  {loansById?.car[0]?.carDetails.model?.make
                                    ?.name
                                    ? `${loansById?.car[0]?.carDetails?.model?.make?.name} ${loansById?.car[0]?.carDetails?.model?.name} ${loansById?.car[0]?.carDetails?.year}`
                                    : "---"}
                                </div>
                              </div>
                              <div className="detail">
                                <div>{t("vin")}:</div>
                                <div>{loansById?.car[0]?.vin || "---"}</div>
                              </div>
                              <div className="detail">
                                <div>{t("rating")}:</div>
                                <div>
                                  {loansById?.car[0]?.gradeScore?.toFixed(1) ||
                                    "---"}
                                </div>
                              </div>
                              <div className="detail">
                                <div>{t("car-price")}:</div>
                                <div>
                                  {formatInt(loansById?.car[0]?.price, true) ||
                                    "---"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </CustomerCarInfo>
                      </Panel>
                    </Collapse>
                  </CollapseContainer>
                ) : (
                  <div className="flex-1">
                    <CustomerCarInfo
                      className="mb-6"
                      style={{ minHeight: "auto" }}
                    >
                      <div className="car-info">
                        <div style={{ marginBottom: "10px" }}>
                          <img
                            src={
                              loansById?.car[0]?.carDetails?.imageUrl ||
                              `https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg`
                            }
                            alt="car"
                            width="404px"
                            height="246px"
                            style={{
                              borderRadius: "7px",
                              width: "100%",
                              height: "246px",

                              ...(loansById?.car[0]?.carDetails?.imageUrl
                                ? {}
                                : { padding: "40px", objectFit: "contain" }),
                            }}
                          />
                        </div>
                        <div className="detail">
                          <div>{t("car-name")}</div>
                          <div>
                            {loansById?.car[0]?.carDetails.model?.make?.name
                              ? `${loansById?.car[0]?.carDetails?.model?.make?.name} ${loansById?.car[0]?.carDetails?.model?.name} ${loansById?.car[0]?.carDetails?.year}`
                              : "---"}
                          </div>
                        </div>
                        <div className="detail">
                          <div>{t("vin")}</div>
                          <div>{loansById?.car[0]?.vin || "---"}</div>
                        </div>
                        <div className="detail mb-2">
                          <div>{t("rating")}</div>
                          <div>
                            {loansById?.car[0]?.gradeScore?.toFixed(1) || "---"}
                          </div>
                        </div>

                        <div className="car-price">
                          <div>
                            {t("car-price")}:{" "}
                            {formatInt(loansById?.car[0]?.price, true)}
                          </div>
                        </div>
                      </div>
                    </CustomerCarInfo>
                  </div>
                )}
              </>
            )}

            <div className="xl:w-[70%] w-full h-full mt-4 md:mt-0">
              <DealerLoanInfo>
                <Tabs
                  activeKey={loansTab}
                  items={items}
                  onChange={handleLoanTabs}
                />
              </DealerLoanInfo>
              <CustomerDocument style={{ marginBottom: 0 }}>
                <div className="border-[1px] border-[rgb(229, 231, 235)] divide-y rounded">
                  <header style={{ padding: 14 }}>
                    <div className="text-sm md:text-base text-[#30345e] font-semibold">
                      {t("customer-documents")}
                    </div>
                    <Button
                      onClick={() =>
                        showMoreDocs("EXCLUDE_CAR_DOCUMENTS" as IDocType, "")
                      }
                      className="upload"
                      disabled={!!isAssumedUser}
                    >
                      {t("upload-additional-document")}
                    </Button>
                  </header>
                  <div>
                    {loansById?.personalDocs?.length === 0 ? (
                      <div className="min-h-[200px] flex items-center justify-center">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                          alt="empty-page"
                        />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x">
                        <div className="p-4">
                          <div className="text-sm text-[#30345e] font-semibold mb-3">
                            {t("preview-uploaded-documents")}
                          </div>
                          <div className="flex flex-wrap gap-1">
                            {loansById?.personalDocs?.map((el: any) => (
                              <div key={el?.code}>
                                <a
                                  className="text-xs md:text-sm font-medium text-[#6b7280]"
                                  href={el?.fileUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DescriptionIcon
                                    sx={{
                                      marginRight: "4px",
                                      color: "#7F83A8",
                                    }}
                                  />
                                  <span>
                                    {t1(convertCamelCaseString(el?.name))}
                                  </span>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>

                        {Boolean(
                          dealerLimitDocument?.limitDocuments?.length ?? 0
                        ) && (
                          <div className="p-4">
                            <div className="text-sm text-[#30345e] font-semibold mb-3">
                              {t2("preview-master-agreement")}
                            </div>
                            <div className="flex flex-wrap gap-1">
                              {dealerLimitDocument?.limitDocuments?.map(
                                (el: any) => (
                                  <div key={el?.code}>
                                    <a
                                      className="text-xs md:text-sm font-medium text-[#6b7280]"
                                      href={el?.signedFileUrl ?? el?.fileUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <DescriptionIcon
                                        sx={{
                                          marginRight: "4px",
                                          color: "#7F83A8",
                                        }}
                                      />
                                      <span>
                                        {t1(convertCamelCaseString(el?.code))}
                                      </span>
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </CustomerDocument>
            </div>

            {loansById?.product?.name !== "Repo Finance" && (
              <div className="flex-1">
                {loansById?.car?.length > 0 ? (
                  <div className="w-full h-auto bg-white rounded-lg border-b-[1px] border-[#e5e7eb] py-4">
                    <div className="text-xl text-[#30345E] font-bold px-3 mb-1">
                      {t2("collaterals")}
                    </div>
                    <div className="grid grid-cols-1 divide-y">
                      {loansById?.car?.map((car: any, index: number) => (
                        <div
                          key={car?.id}
                          className="flex flex-col md:flex-row gap-2 py-3 px-3"
                        >
                          <div className="w-[97px] h-[64px] rounded-[7px] overflow-hidden">
                            <img
                              src={
                                car?.carDetails?.imageUrl ||
                                "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                              }
                              alt="Car"
                              width="97px"
                            />
                          </div>
                          <div className="flex-1">
                            <div className="flex flex-col gap-2 w-full">
                              <div className="flex flex-row items-start md:items-end gap-1 md:gap-0 justify-start md:justify-end">
                                {car?.inspectionStatus && (
                                  <Tag
                                    color={getInspectionStatusColor(car)}
                                    className="capitalizes text-[11px] font-bold"
                                  >
                                    {t2(
                                      `Inspection ${car?.inspectionStatus?.replaceAll(
                                        "-",
                                        ""
                                      )}`
                                    )}
                                  </Tag>
                                )}
                                {car?.inspectionStatus === "cancelled" ? (
                                  <></>
                                ) : (
                                  <Tag
                                    color={
                                      car?.isDocComplete === true
                                        ? "green"
                                        : "orange"
                                    }
                                    className="text-[11px] font-bold"
                                  >
                                    {getDocumentStatus(car)}
                                  </Tag>
                                )}
                              </div>
                              <div className="text-base text-[#30345E] font-bold">
                                {car?.carDetails?.model?.make?.name
                                  ? `${car?.carDetails?.year} ${car?.carDetails?.model?.make?.name} ${car?.carDetails?.model?.name}`
                                  : "---"}
                              </div>
                              <div className="flex justify-between items-center w-full">
                                <div>
                                  <div className="text-xs text-[#6B7280]">
                                    {t2("value")}
                                  </div>
                                  <div className="text-sm text-[#30345E] font-bold">
                                    {formatInt(car?.price, true)}
                                  </div>
                                </div>
                                <div>
                                  <div className="text-xs text-[#6B7280]">
                                    {t2("rating")}
                                  </div>
                                  <div className="text-sm text-[#30345E] font-bold">
                                    {car?.carDetails?.gradeScore
                                      ? car?.carDetails?.gradeScore?.toFixed(1)
                                      : "---"}
                                  </div>
                                </div>
                              </div>
                              {car?.inspectionStatus === "completed" &&
                                !car?.isDocComplete && (
                                  <div className="flex justify-end">
                                    <button
                                      onClick={() =>
                                        showMoreDocs(
                                          "CAR_DOCUMENTS" as IDocType,
                                          car?.vin
                                        )
                                      }
                                      disabled={!!isAssumedUser}
                                      className="bg-[#ffb619] text-xs text-[#30345e] font-semibold rounded-md px-3 w-fit h-8 disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
                                    >
                                      {t2("upload-car-docs")}
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <GeneralEmptyState
                    style={{
                      minHeight: 300,
                      background: "#fff",
                      border: "1px solid #e5e7eb",
                    }}
                  >
                    <div className="content">
                      <div>
                        <img
                          src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                          alt="caution"
                        />
                      </div>
                      <div>
                        <div className="sub-heading">
                          {t2("you-dont-have-a-collateral-yet")}
                        </div>
                      </div>
                    </div>
                  </GeneralEmptyState>
                )}
              </div>
            )}
          </div>
        </DealerAppTabContainer>
      </div>

      <AdditionalDealerDocModal
        handleOk={handleUploadMoreDocs}
        handleCancel={handleCancelUploadDoc}
        isModalOpen={uploadMoreDocs}
        id={id}
        type={carDocType}
        vin={carVin}
      />
    </Main>
  );
};

export default DealDetails;
