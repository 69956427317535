import { useState, useEffect, useRef } from "react";
import {
  DatePicker,
  InputNumber,
  Button,
  Slider,
  Drawer,
  Col,
  Row,
  Radio,
} from "antd";
import storage from "@l/storage";
import {
  FilterLoanValue,
  MobileFilterContainer,
} from "@/layouts/application-layouts/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import tw from "tailwind-styled-components";
import type { RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";
import formatCurrency from "@u/formatCurrency";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const CustomFieldsFilter = ({ query, loading }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const country = storage.getCountry();
  const [displayFilter, setDisplayFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const filterRef = useRef<any>(null);
  const [minMaxValue, setMinMaxValue] = useState<any>({
    min: "",
    max: "",
  });
  const [minMaxInput, setMinMaxInput] = useState<any>({
    min: "",
    max: "",
  });
  const [startMobileDate, setStartMobileDate] = useState<string | any>("");
  const [endMobileDate, setEndMobileDate] = useState<string | any>("");
  const [statusValue, setStatusValue] = useState("");
  const { t } = useTranslation("dashboard");
  const { t: t1 } = useTranslation("component");
  const { t: t2 } = useTranslation("inventory");
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  const checkMobileView = () => {
    const breakpoint: number = 768;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  useEffect(() => {
    window.onclick = (event: any) => {
      if (
        event.target.contains(filterRef.current) &&
        event.target !== filterRef.current
      ) {
        setDisplayFilter(false);
      }
    };
  }, []);

  const handleDisplayFilter = () => {
    if (displayFilter === false) {
      setDisplayFilter(true);
    } else {
      setDisplayFilter(false);
    }
  };

  const handleMobileMinValue = (value: any) => {
    if (minMaxInput.max > value) {
      setMinMaxInput({ min: value, max: minMaxInput.max });
    }
    setMinMaxInput({ min: value, max: minMaxInput.max });
  };

  const handleMobileMaxValue = (value: any) => {
    if (minMaxInput.min < value) {
      setMinMaxInput({ min: minMaxInput.min, max: value });
    }
  };

  const handleMobileRangeSlider = (value: any) => {
    if (value[0] < value[1]) {
      setMinMaxInput({
        min: value[0],
        max: value[1],
      });
    }
  };

  const handleStartDateFilter = (date: string | any, dateString: string) => {
    setStartMobileDate(dateString);
  };

  const handleEndDateFilter = (date: string | any, dateString: string) => {
    setEndMobileDate(dateString);
  };

  const handleStatusChange = (e: RadioChangeEvent) => {
    setStatusValue(e.target.value);
  };

  const handleApplyMobileFilter = () => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        from_date: startMobileDate,
        to_date: endMobileDate,
        min_loan_value: String(minMaxInput.min),
        max_loan_value: String(minMaxInput.max),
        status: statusValue,
      })}`,
    );
    setOpen(false);
  };

  const handleResetMobileFilter = () => {
    setStartMobileDate("");
    setEndMobileDate("");
    setMinMaxInput({
      min: "",
      max: "",
    });
    setStatusValue("");
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        from_date: "",
        to_date: "",
        min_loan_value: "",
        max_loan_value: "",
        status: "",
      })}`,
    );
    setOpen(false);
  };

  const handleMinValue = (value: any) => {
    if (minMaxValue.max > value) {
      setMinMaxValue({ min: value, max: minMaxValue.max });
    }
    setMinMaxValue({ min: value, max: minMaxValue.max });
  };

  const handleMaxValue = (value: any) => {
    if (minMaxValue.min < value) {
      setMinMaxValue({ min: minMaxValue.min, max: value });
    }
  };

  const handleRangeSlider = (value: any) => {
    if (value[0] < value[1]) {
      setMinMaxValue({
        min: value[0],
        max: value[1],
      });
    }
  };

  const onAfterChange = (value: any) => {};
  const onMobileAfterChange = (value: any) => {};

  const handleResetFilter = () => {
    setMinMaxValue({
      min: 0,
      max: 0,
    });
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        min_loan_value: "",
        max_loan_value: "",
      })}`,
    );
  };

  const handleApplyFilter = () => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        min_loan_value: String(minMaxValue.min),
        max_loan_value: String(minMaxValue.max),
      })}`,
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  const showFilterDrawer = () => {
    setOpen(true);
  };

  return (
    <div className="filter-fields">
      <FilterLoanValue>
        {isMobileView ? (
          <img
            src="https://media.autochek.africa/file/publicAssets/filter-lines.svg"
            alt="filter-icon"
            onClick={showFilterDrawer}
          />
        ) : (
          <div>
            <Button
              className="header"
              onClick={handleDisplayFilter}
              ref={filterRef}
              type="link"
            >
              {location.pathname === "/inventory"
                ? t("filter-by-loan-value")
                : "MORE FILTERS"}
            </Button>
            {displayFilter && (
              <div className="filter-loan-value">
                <div className="filter-loan-container">
                  <div className="filter-calculator">
                    <div className="title">
                      <div className="name">{t("loan-value")}</div>
                      <div>
                        <img
                          src="https://media.autochek.africa/file/publicAssets/zap.svg"
                          alt="loan-icon"
                        />
                      </div>
                    </div>
                    <div className="min-max-loan-value">
                      <div>
                        <div className="label">{t1("min-loan-value")}</div>
                        <InputNumber
                          formatter={(value) => {
                            return (
                              getCountryCurrency(country) +
                              " " +
                              formatCurrency(value)
                            );
                          }}
                          onChange={handleMinValue}
                          value={minMaxValue.min}
                        />
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <img
                          src="https://media.autochek.africa/file/publicAssets/Line-1.svg"
                          alt="line-icon"
                        />
                      </div>
                      <div>
                        <div className="label">{t1("max-loan-value")}</div>
                        <InputNumber
                          formatter={(value) => {
                            return (
                              getCountryCurrency(country) +
                              " " +
                              formatCurrency(value)
                            );
                          }}
                          onChange={handleMaxValue}
                          value={minMaxValue.max}
                        />
                      </div>
                    </div>
                    <div className="range-value">
                      <Slider
                        onChange={handleRangeSlider}
                        range
                        min={0}
                        max={200000000}
                        step={50000}
                        defaultValue={[minMaxValue.min, minMaxValue.max]}
                        value={[minMaxValue.min, minMaxValue.max]}
                        onAfterChange={onAfterChange}
                      />
                    </div>
                    <div className="filter-button-container">
                      <Button className="reset" onClick={handleResetFilter}>
                        {t1("reset-filter")}
                      </Button>
                      <Button className="apply" onClick={handleApplyFilter}>
                        {loading ? (
                          <Loader variant={"secondary"} />
                        ) : (
                          t1("apply")
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </FilterLoanValue>

      <Drawer
        title={
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            {t1("filter")}
          </div>
        }
        placement="bottom"
        onClose={onClose}
        open={open}
        height="auto"
        className="filter-drawer"
        headerStyle={{
          borderBottom: "none",
        }}
        closeIcon={
          <img
            src="https://media.autochek.africa/file/publicAssets/Icon-2.svg"
            alt="close-icon"
          />
        }
      >
        <MobileFilterContainer>
          <div className="filter-calculator">
            <div className="filter-name">{t2("filter-by-date-uploaded")}</div>
            <div className="date-value">
              <div className="input-field">
                <DatePicker
                  allowClear
                  placeholder={t1("select-date")}
                  onChange={handleStartDateFilter}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Line-1.svg"
                  alt="line-icon"
                />
              </div>
              <div className="input-field">
                <DatePicker
                  allowClear
                  onChange={handleEndDateFilter}
                  placeholder={t1("select-date")}
                />
              </div>
            </div>
            <div className="title">
              <div className="name">{t("loan-value")}</div>
            </div>
            <div className="min-max-loan-value">
              <div>
                <div className="label">{t1("min-loan-value")}</div>
                <InputNumber
                  formatter={(value) => {
                    return (
                      getCountryCurrency(country) + " " + formatCurrency(value)
                    );
                  }}
                  placeholder="0"
                  onChange={handleMobileMinValue}
                  value={minMaxInput.min}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Line-1.svg"
                  alt="line-icon"
                />
              </div>
              <div>
                <div className="label">{t1("max-loan-value")}</div>
                <InputNumber
                  formatter={(value) => {
                    return (
                      getCountryCurrency(country) + " " + formatCurrency(value)
                    );
                  }}
                  placeholder="0"
                  onChange={handleMobileMaxValue}
                  value={minMaxInput.max}
                />
              </div>
            </div>
            <div className="range-value">
              <Slider
                range
                onChange={handleMobileRangeSlider}
                min={0}
                max={200000000}
                step={50000}
                defaultValue={[minMaxInput.min, minMaxInput.max]}
                value={[minMaxInput.min, minMaxInput.max]}
                onAfterChange={onMobileAfterChange}
              />
            </div>
            <div className="filter-by-status">
              <div className="name">{t("filter-by-status")}</div>
              <Radio.Group
                onChange={handleStatusChange}
                value={statusValue}
                style={{ width: "100%" }}
              >
                <Row style={{ width: "100%" }}>
                  <Col span={12}>
                    <Radio value={"All"}>{t("all")}</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={"Pending Inspection"}>
                      {t2("pending-inspection")}
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={"Listed"}>{t2("listed")}</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={"De-listed"}>{t2("de-listed")}</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </div>
            <div className="filter-button-container">
              <Button className="reset" onClick={handleResetMobileFilter}>
                {t1("reset-filter")}
              </Button>
              <Button className="apply" onClick={handleApplyMobileFilter}>
                {loading ? <Loader variant={"secondary"} /> : t1("apply")}
              </Button>
            </div>
          </div>
        </MobileFilterContainer>
      </Drawer>
    </div>
  );
};

export default CustomFieldsFilter;
