import { useState } from "react";
import DealerLoanApplications from "@/components/DealerLoanApplications";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const Financing = () => {
  const user = storage.getUser();
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const queryClient = useQueryClient();
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [fetchDealerLoans, setFetchDealerLoans]: any = useState([]);
  const [value, setValue] = useState<string | number>("loanApplications");
  const { t } = useTranslation("dealer-gamification");

  const { isLoading: dealerFinancingLoading } = useQuery({
    queryKey: ["finance_fetch", query, user?.company?.id, value],
    queryFn: () => {
      const params = new URLSearchParams({
        current_page: activePage.currentPage.toString(),
        page_size: activePage.pageSize.toString(),
        status: value === "loanApplications" ? "" : "DISBURSED",
        user_id: user?.id,
        ...query,
      });
      return client.get(`/v1/dealer/loans`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setFetchDealerLoans(parsedLoans);
      queryClient.invalidateQueries({
        queryKey: ["fetch_notifications"],
      });
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const renderFinancingCard = () => {
    return (
      <div>
        <div className="font-bold custom-font-bold text-xl lg:text-2xl lg:text-center text-[#30345E]">
          {t("available-financing-options")}
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-2 grid-rows-auto w-full max-w-full lg:max-w-[90%] my-0 mx-auto lg:gap-x-4 gap-x-2 gap-y-4 mt-4">
          <FinancingOptionsCard
            text={t("import-financing")}
            body={t(
              "worry-less-about-how-to-import-that-dream-car-for-your-customer"
            )}
            link="/financing/select-dealer?form_type=new&product_type=import"
          />
          <FinancingOptionsCard
            text={t("local-financing")}
            body={t(
              "get-your-customer-that-car-they-handpicked-even-if-you-do-not-own-it"
            )}
            link="/financing/select-dealer?form_type=new&product_type=local"
          />
          <FinancingOptionsCard
            text={t("duty-financing")}
            body={t("dont-wait-till-you-have-the-funds-to-clear-your-shipment")}
            link="/financing/select-dealer?form_type=new&product_type=duty"
          />
        </div>
      </div>
    );
  };

  return (
    <main>
      <div>
        <div className="flex items-center justify-between mb-3">
          <div className="font-outfitBold custom-font-bold font-bold text-xl lg:text-2xl text-[#30345E]">
            {t("financing")}
          </div>
        </div>

        <div className="mt-8">{renderFinancingCard()}</div>

        <div className="pt-8 lg:pt-12">
          <DealerLoanApplications
            activePage={activePage}
            setActivePage={setActivePage}
            query={query}
            fetchDealerLoans={fetchDealerLoans}
            dealerFinancingLoading={dealerFinancingLoading}
            value={value}
            setValue={setValue}
          />
        </div>
      </div>
    </main>
  );
};

export default Financing;

const FinancingOptionsCard = ({
  text,
  body,
  link,
}: {
  text: string;
  body: string;
  link: string;
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg w-full p-4 lg:h-[11rem] min-h-[13rem] h-auto border border-solid border-[#e5e7eb]">
      <div className="lg:mb-4 mb-2">
        <p className="capitalize font-bold text-[#30345E] text-base lg:text-lg custom-font-bold">
          {text}
        </p>
        <p className="text-sm lg:text-base text-[#30345E] leading-tight pt-2">
          {body}
        </p>
      </div>
      <button
        onClick={() => navigate(link)}
        className="flex items-center justify-center px-2 py-1 text-[#30345E] border border-solid border-[#30345E] rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
      >
        <span className="text-sm font-bold tracking-[0.01563rem] capitalize">
          Apply now
        </span>
      </button>
    </div>
  );
};
