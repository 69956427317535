import { useState } from "react";
import Chart from "@/components/chart";
import { Button, Row, Col, Collapse, message } from "antd";
import { useNavigate } from "react-router-dom";
import EmailCustomer, { LoanInformation } from "./EmailCustomer";
import {
  ActionButtons,
  AdditionalFeeContent,
  BreakdownContainer,
  CollapseContainer,
  DownloadLoanButton,
  FootNoteInfo,
  LoanBreakdownContent,
  PreviewUploadedDoc,
} from "./styles";
import { isMobile } from "react-device-detect";
import formatInt from "@/utils/format-int";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import axios from "axios";
import { convertCamelCase } from "@/utils/convertCamelCase";
import ReferredOfferLayout from "./ReferredOffer";
import NoLoanOffers from "./NoLoanOffer";
import { useTranslation } from "react-i18next";
import DeclinationReasonLayout from "@/components/DeclinationReason";
import storage from "@/lib/storage";
import moment from "moment";

const FinalOfferLayout = ({ id, loanDetails, loansById }: any) => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [emailModal, setEmailModal] = useState(false);
  const { t } = useTranslation("application");
  const { t: t1 } = useTranslation("loan-form");
  const isAssumedUser = storage.getAssumedUser();

  const showEmailModal = () => {
    setEmailModal(true);
  };

  const handleOpenEmail = () => {
    setEmailModal(false);
  };

  const handleCloseEmail = () => {
    setEmailModal(false);
  };

  const toUploadDocuments = () => {
    navigate(
      `/applications/upload-signed-documents?loan_id=${id}&offer_id=${
        loanDetails?.id
      }&loan_doc=${JSON.stringify(loanDetails?.loanDocuments)}&partner=${
        loanDetails?.partner?.name
      }`
    );
  };

  const handleShortenUrl = () => {
    const url = `https://api-ssl.bitly.com/v4/shorten`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
    };

    const payload = {
      group_guid: "Bm610av6Tt3",
      domain: "bit.ly",
      long_url: loanDetails?.pdfUrl,
    };

    axios
      .post(url, payload, {
        headers: headers,
      })
      .then((res: any) => {
        if (res?.data?.link) {
          window.open(
            `https://wa.me/${loansById?.phone}?text=Hi, there is now a final offer for you. Click ${res?.data?.link} to download`,
            "_blank",
            "noreferrer"
          );
        }
      })
      .catch((err: any) => {
        message.error("Error generating link");
      });
  };

  const renderFinalOffer = () => {
    if (
      loanDetails?.status === "OFFER_REFERRED" ||
      loansById?.status === "REFERRED_FINAL_OFFER"
    ) {
      return <ReferredOfferLayout loanDetails={loanDetails} />;
    } else if (REJECTION_STATUSES.includes(loanDetails?.status as string)) {
      return <DeclinationReasonLayout offers={loanDetails} loan={loansById} />;
    } else if (loanDetails === undefined || loansById?.offers?.length === 0) {
      return <NoLoanOffers />;
    } else {
      return (
        <div>
          {downloadFinalOffer()}
          {getFinalLoanDetailsSection()}
        </div>
      );
    }
  };

  const hasSignedDocument = loanDetails?.loanDocuments?.some(
    (doc: any) => doc.documentSigned
  );

  const downloadFinalOffer = () => {
    return (
      !isMobile && (
        <DownloadLoanButton>
          <Button
            type="link"
            href={loanDetails?.pdfUrl}
            target="_blank"
            disabled={
              !loanDetails?.pdfUrl ||
              !!loansById?.salesOppId?.trim() ||
              !!isAssumedUser
            }
            download>
            {t("download-loan-offer")}
          </Button>
        </DownloadLoanButton>
      )
    );
  };

  const validTillDate = loanDetails?.createdAt
    ? moment(loanDetails?.createdAt).add(15, "days").format("Do MMMM YYYY")
    : "---";

  const getStatusPill = (state: any) => {
    switch (state) {
      case "ACTIVE":
        return (
          <span
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "2px 10px",
              borderRadius: "12px",
            }}>
            {t("active")}
          </span>
        );
      case "REGENERATED":
        return (
          <span
            style={{
              backgroundColor: "#2563EB",
              color: "white",
              padding: "2px 10px",
              borderRadius: "12px",
            }}>
            {t("regenerated")}
          </span>
        );
      case "EXPIRED":
        return (
          <span
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "2px 10px",
              borderRadius: "12px",
            }}>
            {t("expired")}
          </span>
        );
      default:
        return null;
    }
  };

  const getFinalLoanDetailsSection = () => (
    <Row gutter={[18, 18]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={5}>
        <Chart loanDetails={loanDetails} loansById={loansById} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={19}>
        {isMobile ? (
          <CollapseContainer>
            <Collapse defaultActiveKey={["0"]} expandIconPosition="end">
              <Panel header={t("loan-breakdown")} key="1">
                <LoanInformation>
                  <ul>
                    <li>
                      <div className="name">{t("car-price")}</div>
                      <div className="value">
                        {formatInt(loansById?.car?.marketplacePrice, true)}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("loan-amount")}</div>
                      <div className="value">
                        {formatInt(loanDetails?.approvedAmount, true)}
                      </div>
                    </li>
                    <li>
                      {loansById?.productType === "Logbook Finance" ? (
                        <>
                          <div className="name">{t("Disbursed Amount")}</div>
                          <div className="value">
                            {formatInt(loansById?.disburseAmount, true)}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="name">{t("Equity")}</div>
                          <div className="value">
                            {formatInt(loanDetails?.equity, true)}
                          </div>
                        </>
                      )}
                    </li>
                    <li>
                      <div className="name">{t("monthly-repayment")}</div>
                      <div className="value">
                        {formatInt(loanDetails?.monthlyRepayment, true)}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("tenure")}</div>
                      <div className="value">
                        {loanDetails?.tenure || "---"} {t("months")}
                      </div>
                    </li>
                    <li>
                      <div className="name">{t("interest")}</div>
                      <div className="value">{t("available-on-request")}</div>
                    </li>
                  </ul>
                </LoanInformation>
              </Panel>
              <Panel header={t("additional-fees")} key="2">
                <LoanInformation>
                  <ul>
                    {loanDetails?.additionalPayments?.map((el: any) => (
                      <li key={loanDetails?.additionalPayments?.indexOf(el)}>
                        <div className="name">{t(el?.name)}</div>
                        <div className="value">
                          {formatInt(el?.value, true)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </LoanInformation>
              </Panel>
            </Collapse>
          </CollapseContainer>
        ) : (
          <BreakdownContainer>
            <LoanBreakdownContent>
              <div className="header">{t("loan-breakdown")}</div>
              <div className="breakdown-container">
                <div className="content">
                  <div className="name">{t("loan-amount")}</div>
                  <div className="value">
                    {formatInt(loanDetails?.approvedAmount, true)}
                  </div>
                </div>
                <div className="content">
                  {loansById?.productType === "Logbook Finance" ? (
                    <>
                      <div className="name">{t("Disbursed Amount")}</div>
                      <div className="value">
                        {formatInt(loansById?.disburseAmount, true)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="name">{t("Equity")}</div>
                      <div className="value">
                        {formatInt(loanDetails?.equity, true)}
                      </div>
                    </>
                  )}
                </div>
                <div className="content">
                  <div className="name">{t("tenure")}</div>
                  <div className="value">
                    {loanDetails?.tenure || "---"} months
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("monthly-repayment")}</div>
                  <div className="value">
                    {formatInt(loanDetails?.monthlyRepayment, true)}
                  </div>
                </div>
                <div className="content">
                  <div className="name">{t("interest-rate")}</div>
                  <div className="value">{t("available-on-request")}</div>
                </div>
              </div>
            </LoanBreakdownContent>
            <AdditionalFeeContent>
              <div className="header">{t("additional-payments")}</div>
              <div className="breakdown-container">
                {loanDetails?.additionalPayments?.map((el: any) => (
                  <div
                    className="content"
                    key={loanDetails?.additionalPayments?.indexOf(el)}>
                    <div className="name">{t(el?.name)}</div>
                    <div className="value">{formatInt(el?.value, true)}</div>
                  </div>
                ))}
              </div>
            </AdditionalFeeContent>
          </BreakdownContainer>
        )}
        {isMobile && (
          <DownloadLoanButton>
            <Button
              type="link"
              href={loanDetails?.pdfUrl}
              target="_blank"
              disabled={
                !loanDetails?.pdfUrl ||
                !!loansById?.salesOppId?.trim() ||
                !!isAssumedUser
              }
              download>
              {t("download-loan-offer")}
            </Button>
          </DownloadLoanButton>
        )}
        {loanDetails && loanDetails?.loanDocuments?.length > 0 && (
          <PreviewUploadedDoc>
            <div className="header">
              {loansById?.status === "SIGNED_LOAN_DOCUMENTS_SUBMITTED"
                ? t("preview-signed-loan-documents")
                : t("download-and-sign")}
            </div>
            <div className="list-container">
              {loanDetails?.loanDocuments?.map((el: any) => (
                <div key={loanDetails?.loanDocuments?.indexOf(el)}>
                  <a
                    href={el?.signedFileUrl ?? el?.fileUrl}
                    target="_blank"
                    rel="noreferrer">
                    <DescriptionIcon
                      sx={{ marginRight: "4px", color: "#7F83A8" }}
                    />
                    <span>{convertCamelCase(t1(el?.name)) || "---"}</span>
                  </a>
                </div>
              ))}
            </div>
          </PreviewUploadedDoc>
        )}
        <FootNoteInfo>{t("once-you-have-signed")}</FootNoteInfo>
        <FootNoteInfo>
          {loanDetails?.state === "EXPIRED" ? (
            <>
              {t("this-offer-is")} {getStatusPill(loanDetails?.state)}
            </>
          ) : (
            <>
              {t("note-this-offer-is-valid-till")} <b>{validTillDate}</b>{" "}
              {getStatusPill(loanDetails?.state)}
            </>
          )}
        </FootNoteInfo>
        <ActionButtons>
          <div className="grouping">
            <Button
              type="link"
              onClick={handleShortenUrl}
              disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}
              className="px-0">
              <img
                src="https://media.autochek.africa/file/publicAssets/logos_whatsapp-1.svg"
                alt="Chat on WhatsApp"
              />
            </Button>

            <Button
              className="email"
              onClick={showEmailModal}
              disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}>
              {t("email-customer")}
            </Button>

            {loanDetails?.loanDocuments && !hasSignedDocument && (
              <button
                onClick={toUploadDocuments}
                className="hidden w-fit px-4 py-1.5 bg-[#ffb619] text-[#30345e] hover:text-[#30345e] text-sm font-bold rounded-[60px] border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}>
                {isMobile
                  ? t("upload-signed-docs")
                  : t("upload-signed-documents")}
              </button>
            )}
          </div>
        </ActionButtons>
      </Col>
    </Row>
  );

  const REJECTION_STATUSES = ["REJECTED", "OFFER_DECLINED", "OFFER_REJECTED"];

  return (
    <div>
      {renderFinalOffer()}

      <EmailCustomer
        emailModal={emailModal}
        handleOpenEmail={handleOpenEmail}
        handleCloseEmail={handleCloseEmail}
        loansById={loansById}
        loanDetails={loanDetails}
        id={id}
      />
    </div>
  );
};

export default FinalOfferLayout;
