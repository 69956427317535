import { Skeleton } from "@mui/material";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col justify-center bg-white w-[168px] border border-solid border-[#e5e7eb] sm:w-full h-[96px] p-3 rounded-lg`;
const Title = tw.div`text-[#30345E] text-base font-semibold`;

interface ICustomBenefits {
  key: number;
  icon: string;
  title: string;
  loading: boolean;
}

export default function CustomBenefitsCard({
  icon,
  title,
}: Readonly<ICustomBenefits>) {
  return (
    <div>
      <Container className="custom-card">
        <img src={icon} alt="benefit-bag" width="32" height="32" />
        <Title className="">{title}</Title>
      </Container>
    </div>
  );
}

export const CustomBenefitsLoadingCard = () => (
  <Container className="custom-card">
    {[0, 1, 2].map((i) => (
      <Skeleton height={10} key={i} />
    ))}
  </Container>
);
