import tw from "tailwind-styled-components";
import BenefitCard from "@/components/BenefitsCard";
import { useTranslation } from "react-i18next";

const Title = tw.div`text-[#30345E] text-base md:text-xl font-bold leading-relaxed`;
const SubTitle = tw.div`text-[#6B7280] text-sm md:text-base`;

export default function NewDealerBenefits() {
  const { t } = useTranslation("dealer-gamification");
  return (
    <main className="flex flex-col gap-4">
      <div>
        <Title>{t("unlock-a-dealership-level")}</Title>
        <SubTitle>{t("unlock-a-dealership-level-subtitle")}</SubTitle>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3">
        <BenefitCard
          medalUrl={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png?updatedAt=1700655816759`}
          dealerStage={`${t("bronze-stage")}`}
          stageDesc={`${t("sell-one-200")}`}
        />
        <BenefitCard
          medalUrl={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png?updatedAt=1700655883625`}
          dealerStage={`${t("silver-stage")}`}
          stageDesc={`${t("sell-three-600")}`}
        />
        <BenefitCard
          medalUrl={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png?updatedAt=1700655844751`}
          dealerStage={`${t("gold-stage")}`}
          stageDesc={`${t("sell-six-1200")}`}
        />
        <BenefitCard
          medalUrl={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png?updatedAt=1700655864864`}
          dealerStage={`${t("platinum-stage")}`}
          stageDesc={`${t("sell-twelve-2400")}`}
        />
      </div>
    </main>
  );
}
