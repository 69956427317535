import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import tw from "tailwind-styled-components";
import { Button, Form, Upload, message } from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import storage from "@l/storage";
import type { UploadChangeParam } from "antd/es/upload";
import { ImageLoader } from "@/utils/ImageLoader";
import { useTranslation } from "react-i18next";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

interface ICustomUpload {
  imageUrl: string;
  loadingUpload: boolean;
  setLoadingUpload: (a: boolean) => void;
  setImageUrl: (a: string) => void;
  label: string;
  name: string;
}

const CustomFileUploader = ({
  imageUrl,
  loadingUpload,
  setLoadingUpload,
  setImageUrl,
  label,
  name,
}: ICustomUpload) => {
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("car-upload");

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t1("you-can-only-upload-jgp"));
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(t1("image-must-smaller-than-5mb"));
    }
    return isJpgOrPng && isLt2M;
  };

  const generateCompressedUrl = async (url: string) => {
    try {
      const img = new Image();
      img.src = url;

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const compressedUrl = ImageLoader({
        src: url,
        width: img.naturalWidth > 1200 ? 1200 : img.naturalWidth,
        quality: 100,
      });

      setImageUrl(compressedUrl);
      return compressedUrl;
    } catch (error) {
      return "";
    }
  };

  const handleUploadChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoadingUpload(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadingUpload(false);
      message.success(`${info.file.name} ${t1("file-uploaded-successfully")}.`);
      generateCompressedUrl(info.file.response.file.url);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <div>
      {loadingUpload ? <Loader variant={"secondary"} /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("upload")}
      </div>
    </div>
  );

  return (
    <div>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action={`${API_URL}image/upload`}
          headers={{
            authorization: `bearer ${token}`,
          }}
          multiple={false}
          maxCount={1}
          onChange={handleUploadChange}
          beforeUpload={beforeUpload}
          accept=".png, .jpg"
        >
          {imageUrl && (
            <div className="loading-text">
              <h1>
                {t("processing-file-for-upload")}
                <span className="dot-one"> .</span>
                <span className="dot-two"> .</span>
                <span className="dot-three"> .</span>
              </h1>
            </div>
          )}
          {imageUrl ? (
            <img
              // src={ImageLoader({
              //   src: `${imageUrl}`,
              //   width: 400,
              //   quality: 100,
              // })}
              src={imageUrl}
              alt="avatar"
              style={{ width: "100%", objectFit: "contain", zIndex: 3 }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label={
          <>
            <div>
              {t1(label)}*{" "}
              {imageUrl && loadingUpload ? <LoadingOutlined /> : ""}
            </div>
            &nbsp;
            {imageUrl && (
              <Button
                onClick={() => setImageUrl("")}
                className="delete-btn"
                type="text"
                icon={<DeleteTwoTone />}
              ></Button>
            )}
          </>
        }
      />
    </div>
  );
};

export default CustomFileUploader;
