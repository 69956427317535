import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { Input, DatePicker, Table, InputNumber, Button, Slider } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { PaginationProps } from "antd";
import storage from "@l/storage";
import {
  FilterContainer,
  FilterLoanValue,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import formatInt from "@u/format-int";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import tw from "tailwind-styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import formatCurrency from "@u/formatCurrency";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const DashboardDigLoans = ({
  activePage,
  setActivePage,
  setStartDate,
  setEndDate,
  query,
  fetchFilteredLoans,
  filteredLoanLoading,
  parentTabValue,
}: any) => {
  const { t, i18n } = useTranslation(["dashboard", "loan-status"]);
  const { t: t1 } = useTranslation("component");
  const navigate = useNavigate();
  const filterRef = useRef<any>(null);
  const { RangePicker } = DatePicker;
  const country = storage.getCountry();
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [fetchLoanStatuses, setFetchLoanStatuses] = useState<any>([]);
  const [minMaxValue, setMinMaxValue] = useState<any>({
    min: "",
    max: "",
  });
  const [rawStatus, setRawStatus] = useState<any>([]);

  useEffect(() => {
    window.onclick = (event: any) => {
      if (
        event.target.contains(filterRef.current) &&
        event.target !== filterRef.current
      ) {
        setDisplayFilter(false);
      }
    };
  }, []);

  const handleDisplayFilter = () => {
    if (displayFilter === false) {
      setDisplayFilter(true);
    } else {
      setDisplayFilter(false);
    }
  };

  useEffect(() => {
    if (query.current_page && typeof query.current_page == "string") {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query?.current_page),
      });
    }
  }, [activePage, query.current_page, setActivePage]);

  const { isLoading: fLoanLoading } = useQuery({
    queryKey: ["pending_loan_statuses_1", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
      });
      return client.get(`/v1/origination/loans/statuses`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoanStatus = JSON.parse(res?.data);
      setRawStatus(parsedLoanStatus?.statuses);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const filteredLoanStatus = rawStatus?.map((status: ILoanStatuses) => {
      return {
        label: t(status?.value, { ns: "loan-status" }),
        value: status?.value,
      };
    });
    setFetchLoanStatuses(filteredLoanStatus);
  }, [rawStatus, t]);

  const handlePageChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current,
    });
    navigate(
      `/dashboard${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize,
      })}`
    );
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `/dashboard${paramsObjectToQueryString({
        ...query,
        search: value,
      })}`
    );
  };

  const handleFilterStatus = (e: any, newValue: any | null) => {
    navigate(
      `/dashboard${paramsObjectToQueryString({
        ...query,
        status: newValue?.value,
      })}`
    );
  };

  const handleDateFilter = (dateString: string | any) => {
    setStartDate(dateString ? Date.parse(dateString[0]) : "");
    setEndDate(dateString ? Date.parse(dateString[1]) : "");
    navigate(
      `/dashboard${paramsObjectToQueryString({
        ...query,
        from_date: dateString ? Date.parse(dateString[0]) : "",
        to_date: dateString ? Date.parse(dateString[1]) : "",
      })}`
    );
  };

  const handleMinValue = (value: any) => {
    if (minMaxValue.max > value) {
      setMinMaxValue({ min: value, max: minMaxValue.max });
    }
  };

  const handleMaxValue = (value: any) => {
    if (minMaxValue.min < value) {
      setMinMaxValue({ min: minMaxValue.min, max: value });
    }
  };

  const handleRangeSlider = (value: any) => {
    if (value[0] < value[1]) {
      setMinMaxValue({
        min: value[0],
        max: value[1],
      });
    }
  };

  const onAfterChange = (value: any) => {};

  const handleResetFilter = () => {
    setMinMaxValue({
      min: 0,
      max: 0,
    });
    navigate(
      `/dashboard${paramsObjectToQueryString({
        ...query,
        min_loan_value: "",
        max_loan_value: "",
      })}`
    );
  };

  const handleApplyFilter = () => {
    navigate(
      `/dashboard${paramsObjectToQueryString({
        ...query,
        min_loan_value: String(minMaxValue.min),
        max_loan_value: String(minMaxValue.max),
      })}`
    );
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
          }}>
          {i18n.language === "ar" ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
          }}>
          {i18n.language === "ar" ? <LeftOutlined /> : <RightOutlined />}
        </Button>
      );
    }
    return originalElement;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (_, el) => (
        <span>
          {el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "---"}
        </span>
      ),
      width: 120,
    },
    {
      title: t("customer-name"),
      dataIndex: "customerName",
      key: "customerName",
      render: (_, el) => (
        <span>
          {el?.firstName ? `${el?.firstName} ${el?.lastName}` : "---"}
        </span>
      ),
    },
    {
      title: t("phone-no"),
      dataIndex: "phoneNo",
      key: "phoneNo",
      render: (_, el) => <span>{el?.phone || "---"}</span>,
    },
    {
      title: t("email"),
      key: "email",
      dataIndex: "email",
      render: (_, el) => <span>{el?.email || "---"}</span>,
    },
    {
      title: parentTabValue === "1" ? t("selected-car") : t("collateral"),
      dataIndex: "selectedCar",
      key: "selectedCar",
      render: (_, el) => <span>{el?.carName || "---"}</span>,
    },
    {
      title: t("loan-value"),
      dataIndex: "loanValue",
      key: "loanValue",
      render: (_, el) => <span>{formatInt(el?.loanValue, true)}</span>,
    },
    {
      title: (
        <p
          style={{
            textAlign: "center",
          }}>
          Expired Offers
        </p>
      ),
      dataIndex: "expiredOffer",
      key: "expiredOffer",
      render: (_, el) => (
        <p
          style={{
            textAlign: "center",
          }}>
          {el?.expiredOfferCount ?? "---"}
        </p>
      ),
    },
    {
      title: t("loan-stage"),
      dataIndex: "loanStage",
      key: "loanStage",
      render: (_, el) => (
        <Button
          className={el?.version === 2 ? "status-button" : "old-status-button"}>
          {el?.status ? t(el?.status, { ns: "loan-status" }) : "---"}
        </Button>
      ),
    },
  ];

  return (
    <div>
      <FilterContainer>
        <div className="filter-field">
          <div className="input-field">
            <div className="label mobile-input-field">{t("quick-search")}</div>
            <Input
              placeholder={t("search-customer")}
              onChange={(val) => handleFilterSearch(val)}
              prefix={
                <img
                  src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                  alt="search-icon"
                />
              }
              allowClear
            />
          </div>
          <div className="input-field mobile-input-field">
            <div className="label">{t("filter-by-date")}</div>
            <RangePicker
              onChange={handleDateFilter}
              placeholder={[t("start-date"), t("end-date")]}
              allowClear
            />
          </div>
          <FilterLoanValue>
            <div>
              <Button
                style={{ marginTop: "30px" }}
                className="header"
                onClick={handleDisplayFilter}
                ref={filterRef}
                type="link">
                {t("filter-by-loan-value")}
              </Button>

              {displayFilter && (
                <div className="filter-loan-value">
                  <div className="filter-loan-container">
                    <div className="filter-calculator">
                      <div className="title">
                        <div className="name">{t("loan-value")}</div>
                        <div>
                          <img
                            src="https://media.autochek.africa/file/publicAssets/zap.svg"
                            alt="loan-icon"
                          />
                        </div>
                      </div>
                      <div className="min-max-loan-value">
                        <div>
                          <div className="label">{t1("min-loan-value")}</div>
                          <InputNumber
                            formatter={(value) => {
                              return (
                                getCountryCurrency(country) +
                                " " +
                                formatCurrency(value)
                              );
                            }}
                            onChange={handleMinValue}
                            value={minMaxValue.min}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <img
                            src="https://media.autochek.africa/file/publicAssets/Line-1.svg"
                            alt="line-icon"
                          />
                        </div>
                        <div>
                          <div className="label">{t1("max-loan-value")}</div>
                          <InputNumber
                            formatter={(value) => {
                              return (
                                getCountryCurrency(country) +
                                " " +
                                formatCurrency(value)
                              );
                            }}
                            onChange={handleMaxValue}
                            value={minMaxValue.max}
                          />
                        </div>
                      </div>
                      <div className="range-value">
                        <Slider
                          onChange={handleRangeSlider}
                          range
                          min={0}
                          max={200000000}
                          step={50000}
                          defaultValue={[minMaxValue.min, minMaxValue.max]}
                          value={[minMaxValue.min, minMaxValue.max]}
                          onAfterChange={onAfterChange}
                        />
                      </div>
                      <div className="filter-button-container">
                        <Button className="reset" onClick={handleResetFilter}>
                          {t1("reset-filter")}
                        </Button>
                        <Button className="apply" onClick={handleApplyFilter}>
                          {filteredLoanLoading ? (
                            <Loader variant={"secondary"} />
                          ) : (
                            t1("apply")
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </FilterLoanValue>
        </div>
        <div className="filter-field">
          <div className="input-field mobile-input-field">
            <div className="label">{t("filter-by-status")}</div>
            <FormControl fullWidth style={{ width: 250 }}>
              <Autocomplete
                fullWidth
                id="filter_loans"
                disablePortal
                loading={fLoanLoading}
                options={fetchLoanStatuses}
                onChange={handleFilterStatus}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("select-status")} />
                )}
              />
            </FormControl>
          </div>
        </div>
      </FilterContainer>
      <TableContainer>
        <Table
          style={{ cursor: "pointer" }}
          columns={columns}
          dataSource={fetchFilteredLoans?.loanLeads}
          onChange={handlePageChange}
          loading={filteredLoanLoading}
          pagination={{
            current: activePage?.currentPage,
            pageSize: activePage?.pageSize,
            total: fetchFilteredLoans?.pagination?.total,
            showSizeChanger: false,
            itemRender: itemRender,
          }}
          rowKey="id"
          onRow={(data) => {
            return {
              onClick: () => {
                navigate(`/applications/loans/${data?.id}`);
              },
            };
          }}
        />
      </TableContainer>
    </div>
  );
};

export default DashboardDigLoans;
