import { useState } from "react";
import { Button } from "antd";
import { IDealerLimitResult } from "@/interface/dealer";
import { Skeleton } from "@mui/material";
import formatInt from "@/utils/format-int";
import { useNavigate } from "react-router-dom";
import { LIMIT_REQUEST_STATUS } from "@/utils/finance-status";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";

interface ILoanLimitCard {
  dealerLimit?: IDealerLimitResult;
  loading: boolean;
}

export default function LoanLimitCard({
  dealerLimit,
  loading,
}: Readonly<ILoanLimitCard>) {
  const navigate = useNavigate();
  const user = storage.getUser();
  const [loanLimitData, setLoanLimitData]: any = useState({});
  const { t } = useTranslation("dealer-gamification");

  const toCreditLimit = () => {
    navigate(`/dealer/credit-limit?limit_id=${dealerLimit?.id}`);
  };

  const declinedStatus: any = dealerLimit?.loanLimits.find(
    (item: any) => item.status === "DECLINED"
  );

  useQuery({
    queryKey: ["loan_limit_utility", user?.company?.id],
    queryFn: () => {
      return client.get(`/v1/dealer/loan-utility/${user?.company?.id}`);
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setLoanLimitData(parsedLoans);
    },
    enabled: !!user?.company?.id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const getMessageForDealerLimit = () => {
    if (!dealerLimit) {
      return "";
    }

    switch (dealerLimit.status) {
      case LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED:
        return t("to-start-applying-for-loans-activate-your-credit-limit");
      case LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED:
        if (
          declinedStatus?.actionRequired === "REUPLOAD" ||
          declinedStatus?.actionRequired === "SIGN"
        ) {
          return (
            declinedStatus?.reasonForDecline ||
            t("your-application-declined-please-reapply")
          );
        }
        return t("review-in-progress-we-are-activating-your-credit-limit");
      case LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW:
        return t("review-in-progress-we-are-activating-your-credit-limit");
      case LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED:
        return t("start-applying-for-loans");
      default:
        return "";
    }
  };

  const message = getMessageForDealerLimit();

  return (
    <main className="flex flex-col lg:flex-row items-center gap-3 dfc-gradient lg:gap-12 w-full py-[16px] lg:py-[12px] px-[12px] rounded-[8px]">
      {loading ? (
        [0, 1, 2, 3, 4].map((i) => <Skeleton height={10} key={i} />)
      ) : (
        <>
          <div className="flex lg:flex-row-reverse justify-between items-center w-full gap-4">
            <div className="flex-1">
              <div className="text-white text-xl lg:text-lg font-bold custom-font-bold leading-tight">
                {t("available-limit")} :{" "}
                {formatInt(
                  dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
                    ? dealerLimit?.limitValue
                    : loanLimitData?.amountLeft,
                  true
                )}
              </div>

              <div className="text-sm lg:text-base font-medium text-[#EEEFFF] max-w-[17rem] md:max-w-full">
                {message}
              </div>
              {/* <Progress
                percent={0}
                size="small"
                trailColor="#EEEFFF"
                strokeColor="#FFB619"
                showInfo={false}
              /> */}
              {/* <div className="flex justify-between">
                <div className="text-xs lg:text-sm text-white">
                  {formatInt(0, true)} used
                </div>
                <div className="text-xs lg:text-sm text-white">
                  {formatInt(0, true)} left
                </div>
              </div> */}
            </div>
            <div>
              <img
                src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/Group%2064.svg?updatedAt=1703695717912"
                alt="Finance Card"
              />
            </div>
          </div>
          {dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED ||
          (dealerLimit?.status ===
            LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED &&
            declinedStatus?.actionRequired === "REUPLOAD") ||
          (dealerLimit?.status ===
            LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED &&
            declinedStatus?.actionRequired === "SIGN") ? (
            <Button
              onClick={() => toCreditLimit()}
              className="bg-[#FFB619] py-1 rounded-full border-none flex items-center justify-center min-w-[170px] w-auto px-4 h-[1.895rem]"
            >
              <span className="text-[#30345E] capitalize text-sm lg:text-base font-semibold">
                {t("activate-credit-limit")}
              </span>
            </Button>
          ) : (
            ""
          )}

          {dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED && (
            <div className="bg-[#EEEFFF] flex justify-between w-full rounded px-2 py-2">
              <div className="text-[#30345E] text-xs lg:text-sm font-bold">
                {t("amount-used")} <br />{" "}
                {formatInt(loanLimitData?.utiliseAmount, true)}
              </div>
              <div className="text-[#30345E] text-xs lg:text-sm font-bold text-right">
                {t("amount-approved")} <br />{" "}
                {formatInt(loanLimitData?.loanLimitAmount, true)}
              </div>
            </div>
          )}
        </>
      )}
    </main>
  );
}

export const DealerLimitLoadingCard = () => (
  <main className="bg-[#C7C7C7] flex flex-col lg:flex-row gap-3 lg:gap-12 w-full py-[16px] lg:py-[12px] px-[12px] rounded-[8px]">
    <div className="flex lg:flex-row-reverse justify-between items-center w-full gap-4">
      <div className="flex-1">
        {[0, 1, 2].map((i) => (
          <Skeleton
            height={10}
            width={"100%"}
            sx={{
              background: "#e3e3e3",
            }}
            key={i}
          />
        ))}
      </div>
      <div>
        <Skeleton
          height={80}
          width={80}
          sx={{
            background: "#e3e3e3",
          }}
        />
      </div>
    </div>
  </main>
);
