import UploadCustomsFile from "./UploadCustomsFile";
import React, { useState } from "react";
import { Form, message } from "antd";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { postCarDocument } from "@/hooks/use-api";
import { useQueryClient } from "@tanstack/react-query";
import { Loader } from "@/pages/auth/styles/loader";
import { ICarDocumentList } from "@/types";

interface PayloadItem {
  name: string;
  url: string;
}

interface IPayload {
  car_id: string;
  payload: PayloadItem[];
}

interface IDocForm {
  carDocuments?: ICarDocumentList[];
}

export default function WhitelabelDocForm({ carDocuments }: IDocForm) {
  const [form] = Form.useForm();
  const { t } = useTranslation("loan-form");
  const queryParam = new URLSearchParams(window.location.search);
  const carId = queryParam.get("carId") as string;
  const [loading, setLoading] = useState(false);
  const isAssumedUser = storage.getAssumedUser();
  const country = storage.getCountry();
  const queryClient = useQueryClient();

  console.log("carDocuments-->", carDocuments);

  const hasInsurance = carDocuments?.some((doc) => doc.name === "insurance");
  const hasLicense = carDocuments?.some((doc) => doc.name === "vehicleLicence");
  const hasLogbook = carDocuments?.some((doc) => doc.name === "logbookUrl");

  const handleSubmitDocuments = async (values: any) => {
    setLoading(true);

    const payload: IPayload = {
      car_id: carId,
      payload: [
        ...(values?.insurance
          ? [
              {
                name: "insurance",
                url: values?.insurance[0]?.response?.file?.url,
              },
            ]
          : []),
        ...(values?.vehicleLicence
          ? [
              {
                name: "vehicleLicence",
                url: values?.vehicleLicence[0]?.response?.file?.url,
              },
            ]
          : []),
        ...(values?.logbookUrl
          ? [
              {
                name: "logbookUrl",
                url: values?.logbookUrl[0]?.response?.file?.url,
              },
            ]
          : []),
      ],
    };

    try {
      const response = await postCarDocument(payload);
      if (response) {
        message.success("Documents submitted successfully");
        form.resetFields();
        queryClient.invalidateQueries(["fetch_car_documents"]);
        setLoading(false);
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  return (
    <main>
      <Form
        name="whitelabel_car_docs"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmitDocuments}
        autoComplete="off"
      >
        <div className="pb-2 pt-3">
          {!hasInsurance && (
            <UploadCustomsFile
              name="insurance"
              label={t("Upload insurance document")}
              messageText={t("Please upload insurance document")}
              rule={true}
            />
          )}

          {country === "NG" && !hasLicense && (
            <UploadCustomsFile
              name="vehicleLicence"
              label={t("Upload vehicle license document")}
              messageText={t("Please upload vehicle license document")}
              rule={true}
            />
          )}

          {country === "KE" && !hasLogbook && (
            <UploadCustomsFile
              name="logbookUrl"
              label={t("Upload logbook document")}
              messageText={t("Please upload logbook document")}
              rule={true}
            />
          )}

          <Form.Item>
            <div className="button-container w-[40%] my-0 mx-auto">
              <button
                className="text-xs lg:text-base mt-4 w-full h-[45px] rounded-[60px] bg-[#ffb619] font-semibold text-[#30345e] px-4 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                disabled={!!isAssumedUser}
              >
                {loading ? <Loader variant={"secondary"} /> : t("Submit")}
              </button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </main>
  );
}
