import React from 'react'
import styled from 'styled-components'

const HeaderContainer = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px 8px 16px;
    gap: 8px;
    width: 100%;
    height: 64px;
    background: #FFFFFF;
    border-bottom: 1px solid #E5E7EB;
    border-radius: 8px 8px 0px 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
const Title = styled.h6`
    /* Headline/h6 */

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #30345E;
`
type props  = {
    title: string
}
function EditInventoryHeader({title}: props) {
    return (
        <HeaderContainer>
            <Title>{title}</Title>
        </HeaderContainer>
    )
}

export default EditInventoryHeader