import React, { useState, useEffect } from "react";
import { Input, Table, List, Button, Card, DatePicker } from "antd";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import type { PaginationProps } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import french from "antd/es/date-picker/locale/fr_FR";
import arabic from "antd/es/date-picker/locale/ar_EG";
import english from "antd/es/date-picker/locale/en_US";
import storage from "@l/storage";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";

import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { LeadTypeIcon, MailIcon, PhoneIcon } from "@/assets/svgs";
import { NewFilterContainer } from "@/layouts/application-layouts/styles";
import CustomFieldsFilter from "@/layouts/application-layouts/CustomFieldsFilter";
import formatInt from "@/utils/format-int";
import getInitial from "@/utils/get-initial";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

interface ICustomerFinancing {
  activePage: IActivePage;
  setActivePage: React.Dispatch<React.SetStateAction<IActivePage>>;
  query: any;
  fetchFilteredLoans: any;
  filteredLoanLoading: boolean;
  parentTabValue: string;
}

const CustomList = styled.div`
  .ant-list-split .ant-list-item {
    border-block-end: none;
  }
  .ant-list-split.ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item:last-child {
    border-block-end: none;
  }
  .ant-list .ant-list-item {
    padding: 0 0;
    margin-bottom: 16px;
  }
  .ant-card-small > .ant-card-body {
    padding: 12px 12px;
  }
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    background: #ffb619;
    border-radius: 44px;
    border: 0;
    padding: 0px 16px;
    font-weight: 600;
  }
`;

const CustomTable = styled.div`
  padding: 0px 16px 16px;
  table {
    border: 1px solid #f0f0f0;
    border-bottom: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: 0 0;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }
  .status-button {
    background: #ffed8e;
    border-radius: 4px;
    color: #30345e !important;
    letter-spacing: -0.25px;
    border: 0;
  }
  button {
    border: 0;
  }
`;

export default function CustomerFinancingTable({
  activePage,
  setActivePage,
  query,
  fetchFilteredLoans,
  filteredLoanLoading,
  parentTabValue,
}: Readonly<ICustomerFinancing>) {
  const navigate = useNavigate();
  const location = useLocation();
  const { RangePicker } = DatePicker;
  const locale = storage.getLocale();
  const { t, i18n } = useTranslation("dashboard");
  const { t: t1 } = useTranslation("loan-status");
  const country = storage.getCountry();
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [rawStatus, setRawStatus] = useState<any>([]);
  const [fetchLoanStatuses, setFetchLoanStatuses] = useState<any>([]);

  const icons = {
    leadIcon: <LeadTypeIcon />,
    mail: <MailIcon />,
    phone: <PhoneIcon />,
  };

  useEffect(() => {
    if (query.current_page && typeof query.current_page == "string") {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query.current_page),
      });
    }
  }, [activePage, query.current_page, setActivePage]);

  const checkMobileView = () => {
    const breakpoint: number = 768;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const { isLoading: fLoanLoading } = useQuery({
    queryKey: ["pending_loan_statuses_1", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
      });
      return client.get(`/v1/origination/loans/statuses`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoanStatus = JSON.parse(res?.data);
      setRawStatus(parsedLoanStatus?.statuses);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    const filteredLoanStatus = rawStatus?.map((status: ILoanStatuses) => {
      return {
        label: t(status?.value, { ns: "loan-status" }),
        value: status?.value,
      };
    });
    setFetchLoanStatuses(filteredLoanStatus);
  }, [rawStatus, t]);

  const handlePageChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current,
    });
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize,
      })}`
    );
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        query: value,
      })}`
    );
  };

  const handleDateFilter = (dateString: string | any[]): void => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        from_date: dateString
          ? moment(dateString[0].$d).format("YYYY-MM-DD")
          : "",
        to_date: dateString
          ? moment(dateString[1].$d).format("YYYY-MM-DD")
          : "",
      })}`
    );
  };

  const handleFilterStatus = (e: any, newValue: Record<string, any> | null) => {
    navigate(
      `${location.pathname}${paramsObjectToQueryString({
        ...query,
        status: newValue?.value,
      })}`
    );
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <a href="#link">{t("previous")}</a>;
    }
    if (type === "next") {
      return <a href="#link">{t("next")}</a>;
    }
    return originalElement;
  };

  const itemTableRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
          }}>
          {i18n.language === "ar" ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button
          type="text"
          style={{
            borderColor: "transparent",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
          }}>
          {i18n.language === "ar" ? <LeftOutlined /> : <RightOutlined />}
        </Button>
      );
    }
    return originalElement;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (_, el) => (
        <span>
          {el?.createdAt ? moment(el?.createdAt).format("DD-MM-YYYY") : "---"}
        </span>
      ),
      width: 120,
    },
    {
      title: t("customer-name"),
      dataIndex: "customerName",
      key: "customerName",
      render: (_, el) => (
        <span>
          {el?.firstName ? `${el?.firstName} ${el?.lastName}` : "---"}
        </span>
      ),
    },
    {
      title: t("phone-no"),
      dataIndex: "phoneNo",
      key: "phoneNo",
      render: (_, el) => <span>{el?.phone || "---"}</span>,
    },
    {
      title: t("email"),
      key: "email",
      dataIndex: "email",
      render: (_, el) => <span>{el?.email || "---"}</span>,
    },
    {
      title: parentTabValue === "1" ? t("selected-car") : t("collateral"),
      dataIndex: "selectedCar",
      key: "selectedCar",
      render: (_, el) => <span>{el?.carName || "---"}</span>,
    },
    {
      title: t("loan-value"),
      dataIndex: "loanValue",
      key: "loanValue",
      render: (_, el) => <span>{formatInt(el?.loanValue, true)}</span>,
    },
    {
      title: (
        <p
          style={{
            textAlign: "center",
          }}>
          Expired Offers
        </p>
      ),
      dataIndex: "expiredOffer",
      key: "expiredOffer",
      render: (_, el) => (
        <p
          style={{
            textAlign: "center",
          }}>
          {el?.expiredOfferCount ?? "---"}
        </p>
      ),
    },
    {
      title: t("loan-status"),
      dataIndex: "loanStage",
      key: "loanStage",
      render: (_, el) => (
        <Button
          className={el?.version === 2 ? "status-button" : "old-status-button"}>
          {el?.status ? t(el?.status, { ns: "loan-status" }) : "---"}
        </Button>
      ),
    },
  ];

  let selectedLocale;
  if (locale === "fr") {
    selectedLocale = french;
  } else if (locale === "ar") {
    selectedLocale = arabic;
  } else {
    selectedLocale = english;
  }

  return (
    <main>
      {location.pathname === "/dealer/applications" && (
        <NewFilterContainer>
          <div className="filter-field new-filter-field">
            <div className="input-field">
              <div className="label mobile-input-field">
                {t1("search-applications")}
              </div>
              <Input
                placeholder="Search applications"
                onChange={(val) => handleFilterSearch(val)}
                prefix={
                  <img
                    src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                    alt="search-icon"
                  />
                }
                allowClear
              />
            </div>
            <div className="input-field mobile-input-field">
              <div className="label">{t1("filter-by-upload-date")}</div>
              <RangePicker
                locale={selectedLocale}
                //@ts-ignore
                onChange={handleDateFilter}
                allowClear
              />
            </div>
            <CustomFieldsFilter query={query} loading={filteredLoanLoading} />
          </div>
          <div className="filter-field">
            <div className="input-field mobile-input-field">
              <div className="label">{t("filter-by-status")}</div>
              <FormControl fullWidth style={{ width: 250 }}>
                <Autocomplete
                  fullWidth
                  id="filter_loans"
                  disablePortal
                  loading={fLoanLoading}
                  options={fetchLoanStatuses}
                  onChange={handleFilterStatus}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("select-status")} />
                  )}
                />
              </FormControl>
            </div>
          </div>
        </NewFilterContainer>
      )}

      {isMobileView ? (
        <CustomList>
          <List
            bordered={false}
            loading={filteredLoanLoading}
            dataSource={fetchFilteredLoans?.loanLeads}
            pagination={{
              current: activePage?.currentPage,
              pageSize: activePage?.pageSize,
              total: fetchFilteredLoans?.pagination?.total,
              simple: true,
              onChange(page, pageSize) {
                setActivePage({
                  ...activePage,
                  currentPage: page,
                });
                navigate(
                  `${location.pathname}${paramsObjectToQueryString({
                    ...query,
                    current_page: page,
                    page_size: pageSize,
                  })}`
                );
              },
              itemRender: itemRender,
            }}
            renderItem={(item: Record<string, any>) => (
              <List.Item className="">
                <Card
                  bordered={true}
                  size="small"
                  className="w-full"
                  onClick={() => navigate(`/applications/loans/${item?.id}`)}>
                  <div className="grid grid-cols-1 divide-y">
                    <div className="flex flex-col gap-2 pb-3">
                      <div className="flex items-center justify-between">
                        <div className="text-[11px] text-[#202020] bg-[#EEEFFF] rounded-[4px] font-normal px-1.5 py-1 w-fit">
                          Customer&apos;s contact
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-1 justify-between">
                        <div className="flex grow items-center gap-2">
                          <div className="text-sm text-[#30345E] bg-[#CCDDFF] w-10 h-10 flex justify-center items-center rounded-full font-normal">
                            {getInitial(
                              `${item?.firstName} ${item?.lastName}`
                            ) || "---"}
                          </div>
                          <div>
                            <div className="text-base text-[#30345E] font-semibold">
                              {item.firstName
                                ? `${item?.firstName} ${item?.lastName}`
                                : "---"}
                            </div>
                            <div className="flex items-center gap-1 text-[11px] text-[#6B7280] font-normal">
                              {icons.mail} {item?.email || "---"}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-end gap-1 text-[11px] text-[#6B7280] font-normal">
                          {icons.phone} {item?.phone || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 py-3">
                      <div className="flex items-center justify-between">
                        <div className="text-[11px] text-[#202020] bg-[#EEEFFF] rounded-[4px] px-1.5 py-1 w-fit font-normal">
                          Car Details
                        </div>
                        <div className="text-xs text-[#30345E] bg-[#FFED8E] px-2 py-1 rounded font-normal">
                          {item?.status ? t1(item?.status) : "---"}
                        </div>
                      </div>
                      <div className="flex gap-1.5">
                        <div className="w-[75px] h-[71px] bg-slate-300 overflow-hidden rounded">
                          <img
                            src={
                              item?.car?.imageUrl
                                ? item?.car?.imageUrl
                                : "https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                            }
                            alt="Car"
                            className={`w-[75px] h-[71px] ${
                              item?.car?.imageUrl
                                ? "object-cover"
                                : "object-contain"
                            }`}
                          />
                        </div>
                        <div className="flex flex-col justify-between grow">
                          <div className="text-base text-[#30345E] font-semibold">
                            {item?.carName || "---"}
                          </div>
                          <div className="flex justify-between gap-0.5">
                            <div>
                              <div className="text-xs uppercase text-[#6B7280] font-normal">
                                CAR PRICE
                              </div>
                              <div className="text-sm text-[#202020] font-semibold">
                                {formatInt(item?.car?.marketplacePrice, true)}
                              </div>
                            </div>
                            <div>
                              <div className="text-xs uppercase text-[#6B7280] font-normal">
                                {t("loan-value")?.toUpperCase()}
                              </div>
                              <div className="text-[#202020] text-sm font-semibold">
                                {formatInt(item?.loanValue, true)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-between pt-3">
                      <div className="text-[#30345E] text-xs font-semibold uppercase">
                        Dealer: {item?.sourceData?.toUpperCase() || "---"}
                      </div>
                      <div className="text-[#30345E] text-xs font-semibold uppercase">
                        PM:{" "}
                        <span className="uppercase text-[#6B7280] text-xs font-semibold">
                          {"---"}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </CustomList>
      ) : (
        <CustomTable>
          <Table
            style={{ cursor: "pointer" }}
            columns={columns}
            dataSource={fetchFilteredLoans?.loanLeads}
            onChange={handlePageChange}
            loading={filteredLoanLoading}
            pagination={
              location.pathname === "/dealer/dashboard"
                ? false
                : {
                    current: activePage?.currentPage,
                    pageSize: activePage?.pageSize,
                    total: fetchFilteredLoans?.pagination?.total,
                    showSizeChanger: false,
                    itemRender: itemTableRender,
                  }
            }
            rowKey="id"
            onRow={(data) => {
              return {
                onClick: () => {
                  navigate(`/applications/loans/${data?.id}`);
                },
              };
            }}
          />
        </CustomTable>
      )}
    </main>
  );
}
