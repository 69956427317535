import EditInventoryHeader from "@/components/blocks/inventory/EditInventoryHeader";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { FiSearch } from "react-icons/fi";
import TextField from "@/components/formElements/text-field";
import { message, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import Button from "@/components/button";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { EditFeaturesCheckGroup } from "@/layouts/application-layouts/styles";
import { useNavigate } from "react-router-dom";

type props = {
  carFeatures: any[];
  id: string;
};

interface IAllFeatures {
  label: string;
  value: number;
}

const EditCarFeaturesForm = ({ carFeatures, id }: props) => {
  const { t } = useTranslation("inventory");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [allFeatures, setAllFeatures] = useState([] as IAllFeatures[]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [featureCheck, setFeatureCheck] = useState<any[]>(carFeatures);

  useQuery({
    queryKey: ["car_feature_list", searchValue],
    queryFn: () => {
      const params = new URLSearchParams({
        page_number: "1",
        page_size: "200",
        ...(searchValue && { search: searchValue }),
      });
      return client.get(`/v1/inventory/feature`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults: CarFeatureResponse = JSON.parse(res?.data);
      const _featuresList: { label: string; value: number }[] =
        parsedResults?.featureList?.map((feature) => ({
          label: feature.name,
          value: feature.id,
        }));
      setAllFeatures(_featuresList);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleDebouncedSearch = (value: any) => {
    setSearchValue(value.target.value);
  };

  const handleEditFeatureCheck = (checkedValues: CheckboxValueType[]) => {
    setFeatureCheck(checkedValues);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const payload = {
      car_id: id,
      payload: featureCheck,
    };

    try {
      const response: any = await client.post(
        `/v1/inventory/car_feature`,
        payload
      );
      if (response) {
        message.success("Car updated successfully");
        setLoading(false);
        queryClient.invalidateQueries(["car_media_by_id"]);
        navigate(`/inventory/${id}`, { replace: true });
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error || "Error updating car"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <EditInventoryHeader title={t("car-features")} />
      <div className="flex flex-col gap-6 px-3.5 md:px-4 py-6">
        <TextField
          name="searchFeatures"
          value={searchValue}
          onChange={handleDebouncedSearch}
          placeholder={t("search-features")}
          logo={<FiSearch />}
          required
        />

        <EditFeaturesCheckGroup>
          <Checkbox.Group
            options={allFeatures}
            value={featureCheck}
            onChange={handleEditFeatureCheck}
          />
        </EditFeaturesCheckGroup>

        <div className="mt-5 flex justify-center">
          <Button
            variant="secondary"
            onClick={handleSubmitForm}
            width={"w-60"}
            isLoading={loading}
            style={{ borderRadius: "60px" }}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditCarFeaturesForm;
