import { fetchLoanUtility } from "@/hooks/use-api";
import formatInt from "@/utils/format-int";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import storage from "@/lib/storage";
import { ILoanLimitUtility } from "@/types";
import { useSearchParams } from "react-router-dom";

export default function LimitValuesCard() {
  const user = storage.getUser();
  const [searchParams] = useSearchParams();
  const dealerId = searchParams.get("dealer_id");

  //states
  const [loanLimitData, setLoanLimitData] = useState<ILoanLimitUtility>();

  useQuery({
    queryKey: ["loan_limit_utility", user?.company?.id || dealerId],
    queryFn: () => {
      return fetchLoanUtility(user?.company?.id || dealerId);
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setLoanLimitData(parsedLoans);
    },
    enabled: !!user?.company?.id || !!dealerId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <div className="w-auto max-w-[500px] flex justify-between mb-4 my-0 mx-auto bg-white rounded-sm p-4 border border-solid border-[#e5e7eb]">
      <div>
        <div className="font-semibold text-[#6B7280] text-xs uppercase">
          Approved Loan Limit
        </div>
        <div className="font-semibold text-[#30345E] text-lg">
          {formatInt(Number(loanLimitData?.loanLimitAmount), true)}
        </div>
      </div>
      <div>
        <div className="font-semibold text-[#6B7280] text-xs uppercase">
          Available Loan Limit
        </div>
        <div className="font-semibold text-[#30345E] text-lg">
          {formatInt(Number(loanLimitData?.amountLeft), true)}
        </div>
      </div>
      <div>
        <div className="font-semibold text-[#6B7280] text-xs uppercase">
          Utilized Loan Limit
        </div>
        <div className="font-semibold text-[#30345E] text-lg">
          {formatInt(Number(loanLimitData?.utiliseAmount), true)}
        </div>
      </div>
    </div>
  );
}
