import storage from "@/lib/storage";
import { currencyFormat, isPrecededByMinus } from "@/utils/helpers";
import { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
const Container = tw.div`flex items-center gap-1 sm:gap-2 bg-white border border-solid border-[#e5e7eb] p-2 sm:px-2 sm:py-6 rounded-lg min-h-[70px] md:min-h-[90px]`;
const MainContainer = tw.div`flex justify-between items-center divide-x p-2 sm:px-2 sm:py-6 bg-white border border-solid border-[#e5e7eb] rounded-lg min-h-[70px] md:min-h-[90px]`;

const LoaderCard = () => (
  <Box sx={{ width: "100%" }}>
    <Skeleton width={210} height={10} />
    <Skeleton height={10} />
    <Skeleton height={10} />
    <Skeleton height={10} />
  </Box>
);
export default function DealerPerformance(
  prop: Readonly<{
    commission: number;
    commissionPerc: string;
    revenue: number;
    revenuePerc: string;
    bestSeller: string;
    conversionRate: string;
    conversionRatePerc: string;
    isLoading: boolean;
  }>
) {
  const { t } = useTranslation("dealer-gamification");

  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  const checkMobileView = () => {
    const breakpoint: number = 768;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const country = storage.getCountry();

  const formatPercentageVal = (val: string) => {
    const isNegative = isPrecededByMinus(val);
    if (isNegative) {
      return {
        value: `▼ ${Number(val?.slice(1)).toFixed(2)}%`,
        isPositive: false,
      };
    } else {
      return { value: `▲ ${Number(val).toFixed(2)}%`, isPositive: true };
    }
  };

  const dealerPerformanceList = [
    {
      id: 1,
      icon: "https://media.autochek.africa/file/publicAssets/ri-hand-coin-fill.png",
      title: t("commission"),
      amount: `${currencyFormat(prop.commission ?? 0, country)}`,
      percentage: `${formatPercentageVal(prop.commissionPerc ?? "0").value}`,
      percSign: formatPercentageVal(prop.commissionPerc).isPositive ?? true,
      carName: "",
    },
    {
      id: 2,
      icon: "https://media.autochek.africa/file/publicAssets/mdi-cash-multiple.png",
      title: t("revenue"),
      amount: `${currencyFormat(prop.revenue ?? 0, country)}`,
      percentage: `${formatPercentageVal(prop.revenuePerc ?? "0").value}`,
      percSign: formatPercentageVal(prop.revenuePerc).isPositive ?? true,
      carName: "",
    },
    {
      id: 3,
      icon: "https://media.autochek.africa/file/publicAssets/mingcute-car-fill-9.png",
      title: t("best-seller"),
      amount: "10",
      percentage: "",
      percSign: false,
      carName: prop.bestSeller,
    },
    {
      id: 4,
      icon: "https://media.autochek.africa/file/publicAssets/mdi-account-convert.png",
      title: t("conversion-rate"),
      amount: `${prop.conversionRate ?? 0}`,
      percentage: `${
        formatPercentageVal(prop.conversionRatePerc ?? "0").value
      }`,
      percSign: formatPercentageVal(prop.conversionRatePerc).isPositive ?? true,
      carName: "",
    },
  ];

  return (
    <main>
      <div className="mb-2.5 text-[#30345E] text-xl font-semibold">
        {t("performance")}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-3.5 md:gap-x-4 lg:gap-x-6 gap-y-4">
        {dealerPerformanceList.map((item, index) => (
          <div key={item?.id}>
            {index === 2 ? (
              <Container>
                {prop.isLoading ? (
                  <LoaderCard />
                ) : (
                  <>
                    <div>
                      <img
                        src={item.icon}
                        alt="commissions"
                        width="24"
                        height="24"
                      />
                    </div>
                    <div className="w-full">
                      {" "}
                      <div className="flex items-center justify-between">
                        <div className="text-[#6B7280] text-[10px] sm:text-xs uppercase">
                          {item.title}
                        </div>
                        <div className="text-[#6B7280] text-xs block sm:hidden">
                          {item.amount} sold
                        </div>
                      </div>
                      <div className="text-[#30345E] text-sm sm:text-base font-semibold">
                        {item.carName}
                      </div>
                    </div>
                  </>
                )}
              </Container>
            ) : (
              <MainContainer>
                {prop.isLoading ? (
                  <LoaderCard />
                ) : (
                  <>
                    <div className="grow">
                      <div className="flex items-center gap-1 sm:gap-2">
                        <div>
                          <img
                            src={item.icon}
                            alt="commissions"
                            width={isMobileView ? "24" : "28"}
                            height={isMobileView ? "24" : "28"}
                          />
                        </div>
                        <div>
                          <div className="text-[#6B7280] text-[10px] sm:text-xs uppercase">
                            {item.title}
                          </div>
                          <div className="text-[#30345E] text-sm sm:text-base font-semibold">
                            {item.amount}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`${
                        item.percSign ? "text-[#22C55E]" : "text-[#F87171]"
                      } text-xs sm:text-sm font-medium pl-1 sm:pl-2`}
                    >
                      {item.percentage}
                    </div>
                  </>
                )}
              </MainContainer>
            )}
          </div>
        ))}
      </div>
    </main>
  );
}
