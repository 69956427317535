import React, { useState, useEffect } from "react";
import storage from "@l/storage";
import {
  ApplicationLayout,
  FilterContainer,
  Header,
  InventoryCalculateLoan,
  InventoryCarsCard,
  MobileHeader,
  NoInventoryContainer,
} from "@/layouts/application-layouts/styles";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import {
  Input,
  DatePicker,
  Button,
  Card,
  Space,
  Tag,
  Pagination,
  Typography,
  message,
} from "antd";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import InventoryCustomFilter from "@/layouts/application-layouts/InventoryFilter";
import { isMobile } from "react-device-detect";
import type { PaginationProps } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import formatInt from "@/utils/format-int";
import { numberWithCommas } from "@/utils/commonFunctions";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import moment from "moment";
import ListCarModal from "@/layouts/application-layouts/ListCarModal";
import DelistCarModal from "@/layouts/application-layouts/DelistCarModal";
import RequestInspectionModal from "@/layouts/application-layouts/RequestInspectionModal";
import { ImageLoader } from "@/utils/ImageLoader";
import { useTranslation } from "react-i18next";
import { carSearchById, getCarList } from "@/hooks/use-loan";
import french from "antd/es/date-picker/locale/fr_FR";
import arabic from "antd/es/date-picker/locale/ar_EG";
import english from "antd/es/date-picker/locale/en_US";
import LoadingCarCard from "./LoadingCarCard";
import WhitelabelMenuItems from "./WhitelabelMenuItems";

type props = {
  variant: "inventory" | "change-car" | "select-car" | "repo" | "loan-transfer";
};

const CardSection = tw.div``;

const Inventory = ({ variant }: props) => {
  const role = storage.getRole();
  const user = storage.getUser();
  const isAssumedUser = storage.getAssumedUser();
  const country = storage.getCountry();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query: any = queryString.parse(search);
  const { RangePicker } = DatePicker;
  const [current, setCurrent] = useState(1);
  const [carsResult, setCarsResult] = useState<any>([]);
  const [listCar, setListCar] = useState(false);
  const [delistCar, setDelistCar] = useState(false);
  const [reqInspection, setReqInspection] = useState(false);
  const [carId, setCarId]: any = useState("");
  const [carDetails, setCarDetails]: any = useState({});
  const { Text } = Typography;
  const [ellipsis] = useState(true);
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isFranchise = storage.utilities.isFranchise();
  const franchises = storage.getFranchises();
  const queryParameters = new URLSearchParams(window.location.search);
  const loanId = queryParameters.get("loan_id");
  const redirectPath = `/applications/loans/${loanId}`;
  const [productData, setProductData]: any = useState({});
  const queryClient = useQueryClient();
  const [franchiseData, setFranchiseData]: any = useState([]);
  const { t } = useTranslation("inventory");
  const locale = storage.getLocale();
  const carVin = queryParameters.get("car_id_vin") as string;
  const from = queryParameters.get("from");
  const to = queryParameters.get("to");
  const carMakeId = queryParameters.get("make_id");
  const carModelId = queryParameters.get("model_id");
  const franchiseId = queryParameters.get("franchise_id");
  const franchise = queryParameters.get("franchise");
  const franchiseList = queryParameters.get("franchise_list");
  const pageNumber = queryParameters.get("page_number") ?? 1;
  const pageSize = queryParameters.get("page_size");
  const searchQuery = queryParameters.get("query") as string;
  const fromDate = queryParameters.get("from_date") as string;
  const to_date = queryParameters.get("to_date") as string;
  const status = queryParameters.get("status") as string;
  const simulate = queryParameters.get("simulate");
  const partnerId = queryParameters.get("partner_id");
  const [categoryId, setCategoryId] = useState("");

  let currentLocation: string;

  switch (variant) {
    case "select-car":
    case "loan-transfer":
      currentLocation = "applications/car-found";
      break;
    case "change-car":
      currentLocation = "applications/car-list";
      break;
    case "repo":
      currentLocation = "dealer/all-deals";
      break;
    default:
      currentLocation = "inventory";
  }

  const getFranchiseParams = () => {
    if (franchiseId) return { franchise_id: franchiseId };
    if (franchise) return { franchise_id: franchise };
    if (franchiseList) return { franchise_id: franchiseList };
    return {};
  };

  const carSearch = async () => {
    const franchiseParams = getFranchiseParams();

    const carSearchParams = {
      country: country,
      ...(carId && {
        query: carId,
        ...franchiseParams,
      }),
      ...(!carId && {
        ...(from && { year_low: from }),
        ...(to && { year_high: to }),
        ...(carMakeId && { make_id: carMakeId }),
        ...(carModelId && { model_id: carModelId }),
        ...franchiseParams,
        ...(pageNumber && { page_number: pageNumber || "" }),
        ...(pageSize && { page_size: pageSize }),
        ...(carVin && { query: carVin }),
        ...(searchQuery && { query: searchQuery }),
        ...(fromDate && { from_date: fromDate }),
        ...(to_date && { to_date: to_date }),
        ...(status && { status: status }),
      }),
    };

    const data = await carSearchById(carSearchParams);
    return data;
  };

  const changeCarResults = async () => {
    const carListParams: any = {
      ...(searchQuery && { query: searchQuery }),
      country: country,
      exclude_category_name: "logbook",
      page_size: pageSize ?? 12,
      ...(pageNumber && { page_number: pageNumber }),
      ...(fromDate && { from_date: fromDate }),
      ...(to_date && { to_date: to_date }),
      ...(franchiseId && { franchise_id: franchiseId }),
      ...(status && { status: status }),
    };

    const data = await getCarList(carListParams);
    return data;
  };

  useQuery({
    queryKey: ["fetch_category_id"],
    queryFn: () => {
      const params = new URLSearchParams({
        search: "repo",
      });
      return client.get(`/v1/inventory/category`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filterCategoryId = parsedResults?.categoryList?.find(
        (item: any) => item?.name === "Repossessed Sale"
      )?.id;
      setCategoryId(filterCategoryId);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const isDealerDSAFranchisesToString = () => {
    if (isDealerDSA) {
      return franchises
        ?.map((franchise: Franchise) => {
          return franchise?.id;
        })
        ?.toString();
    }
    if (!isDealerDSA) {
      return "";
    }
  };

  const { isLoading: inventoryLoading } = useQuery({
    queryKey: ["fetch_inventory_cars", query, categoryId],
    queryFn: async () => {
      if (variant === "select-car" || variant === "loan-transfer") {
        return carSearch();
      } else if (variant === "change-car") {
        return changeCarResults();
      } else if (variant === "repo") {
        const params = new URLSearchParams({
          country: country,
          category_id: categoryId,
          page_size: "12",
          ...query,
          ...queryParameters,
        });
        return client.get(`/v1/inventory/car`, { params });
      } else {
        const params = new URLSearchParams({
          country: country,
          franchise_id: isFranchise
            ? user?.company?.id
            : isDealerDSAFranchisesToString(),
          add_inspection_status: "true",
          exclude_category_name: "logbook",
          page_size: "12",
          created_by: isAccountManager || isDealerDSA ? user?.id : "",
          include_created_by: !!(isAccountManager || isDealerDSA),
          ...query,
          ...queryParameters,
        });
        return client.get(`/v1/inventory/car`, { params });
      }
    },
    onSuccess: (res: any) => {
      const parsedCars = JSON.parse(res?.data);
      setCarsResult(parsedCars);
    },
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["car_data", country, loanId],
    queryFn: () => {
      if (loanId) return client.get(`/v1/origination/loans/${loanId}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setProductData(parsedLoanDetails?.version);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    document.title = `Dealer Plus | Inventory [${role}]`;
  }, [role]);

  const toLoan = (id: string) => {
    if (variant === "loan-transfer") {
      navigate(`/applications/loan-transfer?car_id=${id}&form_type=new`);
    } else {
      navigate(`/applications/loans?car_id=${id}&form_type=new`);
    }
  };

  const toRepoGuide = (id: string) => {
    navigate(`/dealer/financing/guide?car_id=${id}`);
  };

  const toOfferSimulator = (id: string) => {
    navigate(
      `/applications/offer-simulator?loan_id=${loanId}&car_id=${id}&partner_id=${partnerId}`
    );
  };

  const handleCarChange = async (carId: string) => {
    const payload: any = {
      id: loanId,
      car_id: carId,
    };

    try {
      const response = await client.put(
        productData === 2
          ? `v2/origination/loan/${loanId}`
          : `v1/origination/loan/${loanId}`,
        payload
      );
      if (response) {
        message.success("Car changed successfully");
        queryClient.invalidateQueries([
          "fetch_dig_offers",
          "loans_by_id",
          "fetch_uploaded_documents",
        ]);
        navigate(redirectPath, { replace: true });
      }
    } catch (error: any) {
      message.error(
        JSON.parse(error?.response?.data)?.error ||
          JSON.parse(error?.response?.data)?.message
      );
    }
  };

  const showListCar = (data: any) => {
    setCarId(data);
    setListCar(true);
  };

  const handleOpenListCar = () => {
    setListCar(false);
  };

  const handleCancelListCar = () => {
    setListCar(false);
  };

  const showDelistCar = (data: any) => {
    setCarId(data);
    setDelistCar(true);
  };

  const handleOpenDelistCar = () => {
    setDelistCar(false);
  };

  const handleCancelDelistCar = () => {
    setDelistCar(false);
  };

  const showReqInspection = (data: any) => {
    setCarDetails(data);
    setReqInspection(true);
  };

  const handleOpenReqInspection = () => {
    setReqInspection(false);
  };

  const handleCancelReqInspection = () => {
    setReqInspection(false);
  };

  let currentTitle;

  if (variant === "inventory") {
    currentTitle = isMobile ? t("inventory") : t("my-inventory");
  } else if (variant === "repo") {
    currentTitle = <span>All deals</span>;
  } else {
    currentTitle = t("discover-cars-within-your-budget");
  }

  const title = currentTitle;

  const { isLoading: loadingFranchise } = useQuery({
    queryKey: ["get_franchise", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        page_size: "1000",
      });
      return client.get(`/v1/franchise`, { params });
    },
    onSuccess: (res: any) => {
      const parsedFranchise = JSON.parse(res?.data);
      const franchises = parsedFranchise?.result?.map((franchise: any) => {
        return {
          label: franchise?.name,
          value: franchise?.id,
        };
      });
      setFranchiseData(franchises);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        query: value,
      })}`
    );
  };

  const handleDateFilter = (dateString: any) => {
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        from_date: dateString
          ? moment(dateString[0].$d).format("YYYY-MM-DD")
          : "",
        to_date: dateString
          ? moment(dateString[1].$d).format("YYYY-MM-DD")
          : "",
      })}`
    );
  };

  useEffect(() => {
    if (query.page_number && typeof query.page_number == "string") {
      setCurrent(parseInt(query.page_number));
    }
  }, [query.page_number]);

  const handleFilterStatus = (e: any, newValue: any) => {
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        status: newValue?.value,
      })}`
    );
  };

  const handleFranchiseSelect = (e: any, newValue: any) => {
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        franchise_id: newValue?.value,
      })}`
    );
  };

  const handlePageChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrent(page);
    navigate(
      `/${currentLocation}${paramsObjectToQueryString({
        ...query,
        page_number: page,
        page_size: pageSize,
      })}`
    );
  };

  const navigateToInventory = (id: string) => {
    navigate(
      variant === "repo" ? `/inventory/${id}?repo=true` : `/inventory/${id}`
    );
  };

  const inspectionButtonView = (elm: any) => {
    let inspectionButtonContent;

    if (elm?.hasInspection) {
      inspectionButtonContent = (
        <Button
          className="req-inspection-btn"
          onClick={() => showListCar(elm?.id)}
          disabled={!!isAssumedUser}
        >
          {t("list-car")}
        </Button>
      );
    } else if (elm?.hasInspectionRequest) {
      inspectionButtonContent = (
        <Button className="req-inspection-btn" disabled>
          {t("pending-inspection")}
        </Button>
      );
    } else {
      inspectionButtonContent = (
        <Button
          className="req-inspection-btn"
          onClick={() => showReqInspection(elm)}
          disabled={!!isAssumedUser}
        >
          {t("request-inspection")}
        </Button>
      );
    }

    return inspectionButtonContent;
  };

  const handleAction = (variant: string, id: any) => {
    if (variant === "change-car") {
      handleCarChange(id);
    } else if (variant === "repo") {
      toRepoGuide(id);
    } else {
      toLoan(id);
    }
  };

  const getLocalizedText = (locale: any) => {
    if (locale === "fr") {
      return french;
    } else if (locale === "ar") {
      return arabic;
    } else {
      return english;
    }
  };
  const localizedText = getLocalizedText(locale);

  return (
    <React.Fragment>
      <Header style={{ marginBottom: 16, border: 0 }}>
        {isMobile ? (
          <MobileHeader>
            <div className="header">{title}</div>
          </MobileHeader>
        ) : (
          <div className="title">{title}</div>
        )}
      </Header>
      <ApplicationLayout style={{ paddingTop: "1rem" }}>
        <FilterContainer>
          <div className="filter-field">
            <div className="input-field">
              <div className="label mobile-input-field">
                {t("search-inventory")}
              </div>
              <Input
                placeholder={t("search-inventory-placeholder")}
                onChange={(val) => handleFilterSearch(val)}
                prefix={
                  <img
                    src="https://media.autochek.africa/file/publicAssets/icon-start.svg"
                    alt="search-icon"
                  />
                }
                allowClear
              />
            </div>

            <div className="input-field mobile-input-field">
              <div className="label">{t("filter-by-upload-date")}</div>
              <RangePicker
                locale={localizedText}
                onChange={handleDateFilter}
                allowClear
              />
            </div>

            {variant !== "change-car" && (
              <InventoryCustomFilter query={query} loading={inventoryLoading} />
            )}
          </div>
          <div className="filter-field">
            {isAccountManager && (
              <div className="input-field mobile-input-field">
                <div className="label">{t("filter-by-dealers")}</div>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    style={{ width: 250 }}
                    id="franchise_select"
                    disablePortal
                    loading={loadingFranchise}
                    options={franchiseData}
                    onChange={handleFranchiseSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("select-franchise")}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
            <div className="input-field mobile-input-field">
              <div className="label">{t("filter-by-status")}</div>
              <FormControl fullWidth style={{ width: 250 }}>
                <Autocomplete
                  fullWidth
                  id="filter_loans"
                  disablePortal
                  options={[
                    { label: t("all"), value: "All" },
                    {
                      label: t("pending-inspection"),
                      value: "Pending Inspection",
                    },
                    { label: t("listed"), value: "Listed" },
                    { label: t("de-listed"), value: "De-listed" },
                  ]}
                  onChange={handleFilterStatus}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("select-status")} />
                  )}
                />
              </FormControl>
            </div>
          </div>
        </FilterContainer>

        {carsResult?.carList?.length !== 0 ? (
          <React.Fragment>
            {inventoryLoading ? (
              <InventoryCarsCard>
                {Array.from({ length: 12 }, (_, index) => (
                  <div key={index}>
                    <LoadingCarCard />
                  </div>
                ))}
              </InventoryCarsCard>
            ) : (
              <>
                <InventoryCarsCard>
                  {carsResult?.carList?.map((elm: any, index: number) => (
                    <div key={elm?.id} className="card-container">
                      {variant === "inventory" && (
                        <InventoryCalculateLoan>
                          <button
                            className="button-container"
                            onClick={() =>
                              navigate(`/loan-calculator?car_id=${elm?.id}`)
                            }
                          >
                            <img
                              src="https://media.autochek.africa/file/publicAssets/Group-36536.svg"
                              alt="car-img"
                            />
                            <div className="name">{t("calculate-loan")}</div>
                            <img
                              src="https://media.autochek.africa/file/publicAssets/Line.svg"
                              alt="chevron-img"
                            />
                          </button>
                        </InventoryCalculateLoan>
                      )}

                      <Card
                        cover={
                          <button onClick={() => navigateToInventory(elm?.id)}>
                            <img
                              src={ImageLoader({
                                src: `${elm?.imageUrl}`,
                                width: 400,
                                quality: 100,
                              })}
                              alt="car-banner"
                              className="image-cover"
                            />
                          </button>
                        }
                        bordered={true}
                        // hoverable={isMobile ? false : true}
                      >
                        <CardSection
                          className="car-container"
                          onClick={() => navigateToInventory(elm?.id)}
                        >
                          <div className="car-name">
                            {elm?.year
                              ? `${elm?.year} ${
                                  elm?.model?.make?.name || elm?.title
                                } ${elm?.model?.name || ""}`
                              : "---"}{" "}
                          </div>
                          <Space size={[0, 8]} wrap>
                            <Tag color="#4E538A">
                              {elm?.sellingCondition &&
                                capitalFirstLetter(t(elm?.sellingCondition))}
                              {!elm?.sellingCondition && "---"}
                            </Tag>
                            <Tag color="#4E538A">
                              {numberWithCommas(elm?.mileage)}{" "}
                              {t(elm?.mileageUnit)?.toUpperCase()}
                            </Tag>
                            <Tag color="#4E538A">
                              {elm?.engineType &&
                                capitalFirstLetter(
                                  t(elm?.engineType)?.toUpperCase()
                                )}
                              {!elm?.engineType && "---"}
                            </Tag>
                          </Space>
                        </CardSection>

                        {variant !== "repo" && (
                          <CardSection
                            className="amount-container"
                            onClick={() => navigateToInventory(elm?.id)}
                          >
                            <div>
                              <div className="amount">
                                {t("franchise")}: {elm?.carManagerName || "---"}
                              </div>
                            </div>
                          </CardSection>
                        )}

                        <CardSection
                          className="amount-container"
                          onClick={() => navigateToInventory(elm?.id)}
                        >
                          <div>
                            <div className="amount">
                              {formatInt(elm?.marketplacePrice, true)}
                            </div>
                            <Text
                              style={ellipsis ? { width: "150px" } : undefined}
                              className="location"
                              ellipsis={
                                ellipsis
                                  ? { tooltip: `${elm?.address}` }
                                  : false
                              }
                            >
                              {elm?.address || "---"}
                            </Text>
                          </div>
                          <div>
                            <div className="min-amount">
                              {formatInt(elm?.installment, true)} / Mo
                            </div>
                            <div className="min-percent">
                              {t("min-downpayment")}
                            </div>
                          </div>
                        </CardSection>

                        <div className="cta-container">
                          {(variant === "inventory" || variant === "repo") && (
                            <>
                              {elm?.marketplaceVisible ? (
                                <Button
                                  className="req-inspection-btn"
                                  onClick={() => showDelistCar(elm?.id)}
                                  disabled={!!isAssumedUser}
                                >
                                  {t("delist-car")}
                                </Button>
                              ) : (
                                <>{inspectionButtonView(elm)}</>
                              )}
                            </>
                          )}
                          {isFranchise &&
                          variant === "inventory" &&
                          ["NG", "KE"].includes(country) ? (
                            <WhitelabelMenuItems carId={elm.id} />
                          ) : (
                            <Button
                              className="apply-loan-btn"
                              onClick={() => {
                                variant === "change-car" && simulate
                                  ? toOfferSimulator(elm?.id)
                                  : handleAction(variant, elm?.id);
                              }}
                              disabled={!!isAssumedUser}
                            >
                              {variant === "change-car"
                                ? t("select-car")
                                : t("apply-for-loan")}
                            </Button>
                          )}
                        </div>
                      </Card>
                    </div>
                  ))}
                </InventoryCarsCard>
                <div className="inventory-pagination">
                  <Pagination
                    current={current}
                    onChange={handlePageChange}
                    defaultPageSize={12}
                    total={carsResult?.pagination?.total}
                    showSizeChanger={false}
                  />
                </div>
              </>
            )}
          </React.Fragment>
        ) : (
          <NoInventoryContainer>
            <div className="content" style={{ marginTop: 100 }}>
              <div>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                  alt="caution"
                />
              </div>
              <div>
                <div className="sub-heading">{t("you-do-not-have-a-car")}</div>
              </div>
            </div>
          </NoInventoryContainer>
        )}
      </ApplicationLayout>
      <NewLoansBtn />

      <ListCarModal
        isModalOpen={listCar}
        handleOk={handleOpenListCar}
        handleCancel={handleCancelListCar}
        carId={carId}
      />
      <DelistCarModal
        isModalOpen={delistCar}
        handleOk={handleOpenDelistCar}
        handleCancel={handleCancelDelistCar}
        carId={carId}
      />
      <RequestInspectionModal
        isModalOpen={reqInspection}
        handleOk={handleOpenReqInspection}
        handleCancel={handleCancelReqInspection}
        carData={carDetails}
        refresh={() => {}}
      />
    </React.Fragment>
  );
};

export default Inventory;
