import { Button } from "antd";
import styled from "styled-components";
import { device } from "@/utils/device";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import storage from "@/lib/storage";

const ResumeApplicationContainer = styled.div`
  width: 100%;
  /* min-height: 400px; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 36px 0;
  .content {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media ${device.mobileL} {
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
    .sub-heading {
      color: #6b7280;
      font-weight: 400;
      font-size: 18px;
      @media ${device.mobileL} {
        font-size: 14px;
      }
    }
    .view-cars {
      background: #ffb619;
      border-radius: 44px;
      min-width: 162px;
      width: auto;
      height: 32px;
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
      border: 0;
      margin-top: 36px;
      @media ${device.mobileL} {
        height: 48px;
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        border: 1px solid #d9d9d9;
      }
    }
  }
`;

const ResumeLoanApplication = ({ loansById }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation("application");
  const isAssumedUser = storage.getAssumedUser();

  const toResumeApplication = () => {
    if (loansById?.productType) {
      switch (loansById?.productType) {
        case "Logbook Finance":
          navigate(
            `/applications/create-car-for-cash?loan_id=${loansById?.id}`
          );
          break;
        case "Dealer WhiteLabel":
          navigate(
            `/applications/loan-transfer?car_id=${loansById?.carId}&loan_id=${loansById?.id}`
          );
          break;
        default:
          navigate(
            `/applications/loans?car_id=${loansById?.carId}&loan_id=${loansById?.id}`
          );
          break;
      }
    } else {
      switch (loansById?.product.name) {
        case "Repo Finance":
          navigate(
            `/dealer/financing/repo-financing?loan_id=${loansById?.id}&car_id=${loansById?.car[0]?.carId}`
          );
          break;
        case "Duty Finance":
          navigate(
            `/dealer/financing/loan?loan_id=${loansById?.id}&product_type=duty`
          );
          break;
        case "Import Finance":
          navigate(
            `/dealer/financing/loan?loan_id=${loansById?.id}&product_type=import`
          );
          break;
        case "Local Finance":
          navigate(
            `/dealer/financing/loan?loan_id=${loansById?.id}&product_type=local`
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <ResumeApplicationContainer>
      <div className="content">
        <div>
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
            alt="empty-page"
          />
        </div>
        <div>
          <div className="sub-heading">{t("your-application-incomplete")}</div>
        </div>
        <div>
          <Button
            className="view-cars"
            disabled={!!isAssumedUser}
            // onClick={() => {
            //   loansById?.productType === "Logbook Finance"
            //     ? navigate(
            //         `/applications/create-car-for-cash?loan_id=${loansById?.id}`
            //       )
            //     : navigate(
            //         `/applications/loans?car_id=${loansById?.carId}&loan_id=${loansById?.id}`
            //       );
            // }}
            onClick={() => toResumeApplication()}
          >
            {t("resume-application")}
          </Button>
        </div>
      </div>
    </ResumeApplicationContainer>
  );
};

export default ResumeLoanApplication;
