import { useEffect, useState } from "react";
import storage from "@l/storage";
import styled from "styled-components";
import Cards from "@/components/cards";
import { device } from "@/utils/device";
import { numberWithCommas } from "@/utils/commonFunctions";
import { getLoanStatistics } from "@/hooks/use-loan";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import {
  ApplicationLayout,
  AppTabContainer,
  ParentTabHeader,
} from "@/layouts/application-layouts/styles";
import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DashboardDigLoans from "@/layouts/application-layouts/DashboardDigLoans";
import { getCountryCurrency } from "@/utils/getCountryCurrency";
import NewLoansBtn from "@/layouts/application-layouts/NewLoansBtn";
import { useTranslation } from "react-i18next";

const DashboardContainer = styled.div``;
const TopText = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 1.4rem;
  color: #30345e;
  font-weight: bolder;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  color: #30345e;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const SingleCardContainer = styled.div`
  width: 100%;
  color: #30345e;

  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DesktopView = styled.div`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobileL} {
    display: none;
  }
`;

const MobileView = styled.div`
  display: none;
  @media ${device.tablet} {
    padding-bottom: 1.5rem;
    display: block;
  }
  @media ${device.mobileL} {
    padding-bottom: 1.5rem;
    display: block;
  }
`;

const Dashboard = () => {
  const { t } = useTranslation(["dashboard", "component"]);
  const { t: t1 } = useTranslation("component");
  const [loanStatistics, setLoanStatistics]: any = useState({});
  const [parentTabValue, setParentTabValue] = useState("1");
  const [childTabValue, setChildTabValue] = useState("1");
  const user = storage.getUser();
  const role = storage.getRole();
  const isAccountManager = storage.utilities.isAccountManager();
  const country = storage.getCountry();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [activePage, setActivePage] = useState<any>({
    currentPage: 1,
    pageSize: 10,
  });
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [fetchFilteredLoans, setFetchFilteredLoans]: any = useState([]);
  const [fetchCarForCashLoans, setFetchCarForCashLoans]: any = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);

  useEffect(() => {
    document.title = `Dealer Plus | Dashboard [${role}]`;
  }, [role]);

  useEffect(() => {
    setStatsLoading(true);
    const getStatistics = getLoanStatistics(user?.id, country);
    getStatistics.then((result: any) => {
      setLoanStatistics(JSON.parse(result?.data));
      setStatsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleParentTabChange = (key: string) => {
    setParentTabValue(key);
  };

  const handleChildTabChange = (key: string) => {
    setChildTabValue(key);
  };

  let dashboardLoanStatus = "";
  switch (childTabValue) {
    case "1":
      dashboardLoanStatus = "";
      break;
    case "2":
      dashboardLoanStatus = "PENDING_DOCS";
      break;
    case "3":
      dashboardLoanStatus = "IN_PROGRESS";
      break;
    case "4":
      dashboardLoanStatus = "DISBURSED";
      break;
    default:
      dashboardLoanStatus =
        "REJECTED_FINAL_OFFER,REJECTED_CONDITIONAL_OFFER,DECLINED_FINAL_OFFER,DECLINED_CONDITIONAL_OFFER,FINAL_OFFER_REJECTED,CONDITIONAL_OFFER_REJECTED";
  }

  const { isLoading: filteredLoanLoading } = useQuery({
    queryKey: [
      "dashboard_dig_loans",
      country,
      query,
      activePage,
      user,
      parentTabValue,
      childTabValue,
      dashboardLoanStatus,
      parentTabValue === String(1) ? "Decision Engine Loan" : "Logbook Finance",
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        country: country,
        user_id: user?.id,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        from_date: startDate,
        to_date: endDate,
        hasCommission: "all",
        order: "DESC",
        sort_by: "createdAt",
        source: "",
        productName:
          parentTabValue === String(1)
            ? "Decision Engine Loan"
            : "Logbook Finance",
        ...(parentTabValue === String(1)
          ? {
              status: dashboardLoanStatus,
            }
          : ""),
        ...query,
      });
      return client.get(`/v1/origination/dig/loans`, { params });
    },
    staleTime: 1000,
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      parentTabValue === String(1)
        ? setFetchFilteredLoans(parsedLoans)
        : setFetchCarForCashLoans(parsedLoans);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const childTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t(`all`),
      children: (
        <DashboardDigLoans
          activePage={activePage}
          setActivePage={setActivePage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "2",
      label: t(`pending-docs`),
      children: (
        <DashboardDigLoans
          activePage={activePage}
          setActivePage={setActivePage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "3",
      label: t(`in-progress`),
      children: (
        <DashboardDigLoans
          activePage={activePage}
          setActivePage={setActivePage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "4",
      label: t(`disbursed`),
      children: (
        <DashboardDigLoans
          activePage={activePage}
          setActivePage={setActivePage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
    {
      key: "5",
      label: t(`rejected`),
      children: (
        <DashboardDigLoans
          activePage={activePage}
          setActivePage={setActivePage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          query={query}
          fetchFilteredLoans={fetchFilteredLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
  ];

  const parentTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: <ParentTabHeader>{t("car-loan")}</ParentTabHeader>,
      children: (
        <Tabs
          defaultActiveKey={childTabValue}
          items={childTabItems}
          onChange={handleChildTabChange}
        />
      ),
    },
    {
      key: "2",
      label: <ParentTabHeader>{t(`cash-loan`)}</ParentTabHeader>,
      children: (
        <DashboardDigLoans
          activePage={activePage}
          setActivePage={setActivePage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          query={query}
          fetchFilteredLoans={fetchCarForCashLoans}
          filteredLoanLoading={filteredLoanLoading}
          parentTabValue={parentTabValue}
        />
      ),
    },
  ];

  return (
    <DashboardContainer>
      <TopText>
        {t("hello")} {user?.firstname}, {t("here-are-your-stats")}.
      </TopText>
      <DesktopView>
        <CardsContainer>
          {isAccountManager && (
            <SingleCardContainer>
              <Link to="/dashboard/total-sales">
                <Cards
                  cardHeader={t("total-sales", { ns: "component" })}
                  leftText={t("no-of-sales", { ns: "component" })}
                  leftValue={
                    statsLoading
                      ? "---"
                      : numberWithCommas(
                          +loanStatistics?.totalSales?.number || 0
                        )
                  }
                  rightText={`${t1("sales-value")} (${getCountryCurrency(
                    country
                  )})`}
                  rightValue={
                    statsLoading
                      ? "---"
                      : numberWithCommas(
                          +loanStatistics?.totalSales?.value || 0
                        )
                  }
                  color="#60A5FA"
                />
              </Link>
            </SingleCardContainer>
          )}
          <Cards
            cardHeader={t1("total-loans")}
            leftText={t1("no-of-loans")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.totalLoansCreated?.number || 0
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalLoansCreated?.value || 0
                  )
            }
            color="#60A5FA"
          />

          {["NG", "GH", "KE"]?.includes(country) && (
            <Cards
              cardHeader={t("cash-loan")}
              leftText={t1("no-of-loans")}
              leftValue={
                statsLoading ? "---" : loanStatistics?.carForCash?.number || 0
              }
              rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
              rightValue={
                statsLoading
                  ? "---"
                  : numberWithCommas(+loanStatistics?.carForCash?.value || 0)
              }
              color="#DC2626"
            />
          )}

          <Cards
            cardHeader={t1("disbursed")}
            leftText={t1("loans-disbursed")}
            leftValue={
              statsLoading ? "---" : loanStatistics?.loansDisbursed?.number || 0
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(+loanStatistics?.loansDisbursed?.value || 0)
            }
            color="#22C55E"
          />
          <Cards
            cardHeader={t1("loan-processing")}
            leftText={t1("loans-in-process")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.loansBeingProcessed?.number || 0
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.loansBeingProcessed?.value || 0
                  )
            }
            color="#FFB619"
          />
          <Link to="/dashboard/total-commission">
            <Cards
              cardHeader={t1("total-commissions")}
              leftText={t1("no-of-loans")}
              leftValue={
                statsLoading
                  ? "---"
                  : loanStatistics?.totalCommission?.number || 0
              }
              rightText={`${t1("commission-value")} (${getCountryCurrency(
                country
              )})`}
              rightValue={
                statsLoading
                  ? "---"
                  : numberWithCommas(
                      +loanStatistics?.totalCommission?.value || 0
                    )
              }
              color="#60A5FA"
            />
          </Link>
          <Cards
            cardHeader={t1("commissions-processing")}
            leftText={t1("in-progress")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.totalCommissionBeingProcessed?.number || 0
            }
            rightText={`${t1("expected-value")} (${getCountryCurrency(
              country
            )})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalCommissionBeingProcessed?.value || 0
                  )
            }
            color="#FFB619"
          />
          <Cards
            cardHeader={t1("commissions-earned")}
            leftText={t1("cleared")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.totalCommissionEarned?.number || 0
            }
            rightText={`${t1("received")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalCommissionEarned?.value || 0
                  )
            }
            color="#22C55E"
          />
          <Cards
            cardHeader={t1("no-documents")}
            leftText={`${t1("potential-commission")} (${getCountryCurrency(
              country
            )})`}
            leftValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalLoansWithoutDocuments?.value || 0
                  )
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={statsLoading ? "---" : numberWithCommas(0)}
            buttonLink={() => navigate(`/applications/#2`)}
            isUpload
            color="#DC2626"
          />
        </CardsContainer>

        {/* <EmptyTable /> */}

        <ApplicationLayout>
          <AppTabContainer style={{ padding: 0 }}>
            {["NG", "GH", "KE"].includes(country) ? (
              <Tabs
                defaultActiveKey={parentTabValue}
                items={parentTabItems}
                onChange={handleParentTabChange}
              />
            ) : (
              <Tabs
                defaultActiveKey={childTabValue}
                items={childTabItems}
                onChange={handleChildTabChange}
              />
            )}
          </AppTabContainer>
        </ApplicationLayout>
      </DesktopView>

      <MobileView>
        {isAccountManager && (
          <SingleCardContainer>
            <Link to="/dashboard/total-sales">
              <Cards
                cardHeader={t1("total-sales")}
                leftText={t1("no-of-sales")}
                leftValue={
                  statsLoading
                    ? "---"
                    : numberWithCommas(+loanStatistics?.totalSales?.number || 0)
                }
                rightText={`${t1("sales-value")} (${getCountryCurrency(
                  country
                )})`}
                rightValue={
                  statsLoading
                    ? "---"
                    : numberWithCommas(+loanStatistics?.totalSales?.value || 0)
                }
                color="#60A5FA"
              />
            </Link>
          </SingleCardContainer>
        )}
        <CardsContainer>
          <Cards
            cardHeader={t1("total-loans")}
            leftText={t1("no-of-loans")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.totalLoansCreated?.number || 0
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalLoansCreated?.value || 0
                  )
            }
            color="#60A5FA"
          />
          <Cards
            cardHeader={t1("no-documents")}
            leftText={`${t1("potential-commission")} (${getCountryCurrency(
              country
            )})`}
            leftValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalLoansWithoutDocuments?.value || 0
                  )
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={numberWithCommas(0)}
            buttonLink={() => navigate(`/applications/#2`)}
            isUpload
            color="#DC2626"
          />
          <Cards
            cardHeader={t1("disbursed")}
            leftText={t1("loans-disbursed")}
            leftValue={
              statsLoading ? "---" : loanStatistics?.loansDisbursed?.number || 0
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(+loanStatistics?.loansDisbursed?.value || 0)
            }
            color="#22C55E"
          />
          <Cards
            cardHeader={t1("loan-processing")}
            leftText={t1("loans-in-process")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.loansBeingProcessed?.number || 0
            }
            rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.loansBeingProcessed?.value || 0
                  )
            }
            color="#FFB619"
          />
          {["NG", "GH", "KE"]?.includes(country) && (
            <Cards
              cardHeader={t("cash-loan")}
              leftText={t1("no-of-loans")}
              leftValue={
                statsLoading ? "---" : loanStatistics?.carForCash?.number || 0
              }
              rightText={`${t1("loan-value")} (${getCountryCurrency(country)})`}
              rightValue={
                statsLoading
                  ? "---"
                  : numberWithCommas(+loanStatistics?.carForCash?.value || 0)
              }
              color="#DC2626"
            />
          )}
        </CardsContainer>
        <SingleCardContainer>
          <Link to="/dashboard/total-commission">
            <Cards
              cardHeader={t1("total-commissions")}
              leftText={t1("no-of-loans")}
              leftValue={
                statsLoading
                  ? "---"
                  : loanStatistics?.totalCommission?.number || 0
              }
              rightText={`${t1("commission-value")} (${getCountryCurrency(
                country
              )})`}
              rightValue={
                statsLoading
                  ? "---"
                  : numberWithCommas(
                      +loanStatistics?.totalCommission?.value || 0
                    )
              }
              color="#60A5FA"
            />
          </Link>
        </SingleCardContainer>
        <CardsContainer>
          <Cards
            cardHeader={t1("commissions-processing")}
            leftText={t1("in-progress")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.totalCommissionBeingProcessed?.number || 0
            }
            rightText={`${t1("expected-value")} (${getCountryCurrency(
              country
            )})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalCommissionBeingProcessed?.value || 0
                  )
            }
            color="#FFB619"
          />
          <Cards
            cardHeader={t1("commissions-earned")}
            leftText={t1("cleared")}
            leftValue={
              statsLoading
                ? "---"
                : loanStatistics?.totalCommissionEarned?.number || 0
            }
            rightText={`${t1("commission-received")} (${getCountryCurrency(
              country
            )})`}
            rightValue={
              statsLoading
                ? "---"
                : numberWithCommas(
                    +loanStatistics?.totalCommissionEarned?.value || 0
                  )
            }
            color="#22C55E"
          />
        </CardsContainer>
      </MobileView>

      <NewLoansBtn />
    </DashboardContainer>
  );
};

export default Dashboard;
