import { useState } from "react";
import CustomBenefitsCard, {
  CustomBenefitsLoadingCard,
} from "./CustomBenefitsCard";
import UnlockedBenefitModal from "./UnlockedBenefitModal";
import tw from "tailwind-styled-components";
import { IBenefitList } from "@/interface/dealer";
import { IDealerType } from "./DealerLevelCard";
import { useTranslation } from "react-i18next";

const ViewButton = tw.div`text-[#30345E] text-sm cursor-pointer border border-solid border-[#30345E] font-semibold rounded-full h-[32px] flex items-center px-3`;

export default function DealerHomeBenefits({
  benefits,
  allBenefits,
  dealerType,
  loading,
}: Readonly<{
  benefits: {
    options: string[];
    icon: string;
    title: string;
  }[];
  allBenefits: IBenefitList[];
  dealerType: string;
  loading: boolean;
}>) {
  const { t } = useTranslation("dealer-gamification");

  const [openBenefitModal, setOpenBenefitModal] = useState<boolean>(false);

  const showBenefitModal = () => {
    setOpenBenefitModal(true);
  };

  const handleOpenBenefitModal = () => {
    setOpenBenefitModal(false);
  };

  const handleCloseBenefitModal = () => {
    setOpenBenefitModal(false);
  };

  const currentUserlevelBenefit = allBenefits?.find((benefits) =>
    benefits.name.toLowerCase().includes(dealerType?.toLowerCase())
  );

  return (
    <main>
      <div className="flex flex-col gap-2">
        <div>
          <div className="mb-2.5 text-[#30345E] text-xl font-semibold">
            {t("unlocked-benefits")}
          </div>
          <div className="custom-scroll-container">
            {loading
              ? Array.from({ length: 5 }, (_, index) => (
                  <CustomBenefitsLoadingCard key={index} />
                ))
              : benefits?.map((item, index) => (
                  <CustomBenefitsCard
                    key={benefits.indexOf(item)}
                    icon={item.icon}
                    title={t(item.title)}
                    loading={loading}
                  />
                ))}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-[#7F83A8] text-sm font-semibold cursor-pointer">
            {t("see-faqs")}
          </div>
          <ViewButton onClick={showBenefitModal}>
            {t("view-all-benefits")}
          </ViewButton>
        </div>
      </div>

      <UnlockedBenefitModal
        openBenefitModal={openBenefitModal}
        handleOpenBenefitModal={handleOpenBenefitModal}
        handleCloseBenefitModal={handleCloseBenefitModal}
        type={(dealerType?.toLowerCase() as IDealerType) ?? "bronze"}
        dealerBenefit={currentUserlevelBenefit}
        loading={loading}
      />
    </main>
  );
}
