import React, { useState, useEffect } from "react";
import {
  CarContentContainer,
  CarMediaContainer,
  CarMediaMobile,
  InspectionReportTable,
  InventoryDetailsPage,
  LoanDetailsHeader,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Switch,
  Image,
  Button,
  Carousel,
  Card,
  Space,
  Tag,
  Table,
  Empty,
  Collapse,
  Rate,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import formatInt from "@/utils/format-int";
import { device } from "@/utils/device";
import tw from "tailwind-styled-components";
import ListCarModal from "@/layouts/application-layouts/ListCarModal";
import DelistCarModal from "@/layouts/application-layouts/DelistCarModal";
import { ImageLoader } from "@/utils/ImageLoader";
import RequestInspectionModal from "@/layouts/application-layouts/RequestInspectionModal";
import { useTranslation } from "react-i18next";
import LoanCalculator from "@/components/LoanCalculator";
import { Form, Inputs } from "../auth/styles/sign-in";
import GethelpInput from "@/components/blocks/inputs/getheipinput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import WhitelabelMenuItems from "@/components/WhitelabelMenuItems";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .preview-container {
    display: none;
    @media ${device.mobileL} {
      display: none;
    }
  }
`;

const RequestInspectionButton = styled(Button)`
  height: 32px;
  background: #ffb619;
  border-radius: 60px;
  border: 0;
  span {
    color: #30345e;
    font-weight: 600;
    font-size: 14px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgb(255, 182, 25);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }
  margin-right: 2rem;
`;

const SwtichWrap = styled.div`
  .ant-switch.ant-switch-checked {
    background: #22c55e !important;
  }
  .ant-switch {
    height: 1.9276rem;
    width: 3.5rem;
  }
  .ant-switch .ant-switch-handle {
    top: 3.5px;
    width: 23px;
    height: 23px;
  }
  .ant-switch .ant-switch-handle::before {
    border-radius: 18px;
  }
  .ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 27px);
  }
`;

const panelStyle = {
  marginBottom: 16,
  background: "#fff",
  borderRadius: 5,
  border: "none",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
};

const columns = [
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    render: (_: any, elm: any) => (
      <div className="name">
        {elm?.name ? capitalFirstLetter(elm?.name) : "---"}
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_: any, elm: any) => (
      <div>{elm?.condition ? capitalFirstLetter(elm?.condition) : "---"}</div>
    ),
  },
  {
    title: "Images & Videos",
    dataIndex: "imagesVideos",
    key: "imagesVideos",
    render: (_: any, elm: any) =>
      elm?.medias?.length === 0 ? (
        "---"
      ) : (
        <>
          {elm?.medias?.map((item: any, index: number) => (
            <div key={elm?.medias?.indexOf(item)} className="table-img">
              <img
                src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-2.svg"
                alt="score"
              />
            </div>
          ))}
        </>
      ),
  },
  {
    title: "Inspection Comment",
    dataIndex: "inspectionComment",
    key: "inspectionComment",
    render: (_: any, elm: any) => (
      <div>{elm?.response ? capitalFirstLetter(elm?.response) : "---"}</div>
    ),
  },
];

interface IRepoOffer {
  amount: string;
}

const InventoryDetails = () => {
  const { carId }: any = useParams();
  const navigate = useNavigate();
  const country = storage.getCountry();
  const role = storage.getRole();
  const queryParameters = new URLSearchParams(window.location.search);
  const { Panel } = Collapse;
  const [visible, setVisible] = useState(false);
  const [scaleStep] = useState(0.5);
  const [activeTabKey1, setActiveTabKey1] = useState<string>("tab1");
  const [carById, setCarById]: any = useState({});
  const [carMedia, setCarMedia]: any = useState([]);
  const [carFeature, setCarFeature]: any = useState([]);
  const [inspectionId, setInspectionId] = useState("");
  const [inspectionById, setInspectionById]: any = useState({});
  const [checkListed, setCheckListed] = useState(false);
  const [listCar, setListCar] = useState(false);
  const [delistCar, setDelistCar] = useState(false);
  const [carData, setCarData] = useState("");
  const [carDetails, setCarDetails] = useState({});
  const [reqInspection, setReqInspection] = useState(false);
  const { t } = useTranslation("inventory");
  const [hasInspectionRequest, setHasInspectionRequest] = useState(true);
  const isDsaAgent = storage.utilities.isDsaAgent();
  const repoCheck = queryParameters.get("repo");
  const isAssumedUser = storage.getAssumedUser();
  const isFranchise = storage.utilities.isFranchise();

  useEffect(() => {
    document.title = `Dealer Plus | Car Details [${role}]`;
  }, [role]);

  const toLoan = (id: string) => {
    navigate(`/applications/loans?car_id=${id}&form_type=new`);
  };

  const toEdit = (id: string) => {
    navigate(`/inventory/${id}/edit`);
  };

  const showListCar = (data: any) => {
    setCarData(data);
    setListCar(true);
  };

  const handleOpenListCar = () => {
    setListCar(false);
  };

  const handleCancelListCar = () => {
    setListCar(false);
  };

  const showDelistCar = (data: any) => {
    setCarData(data);
    setDelistCar(true);
  };

  const handleOpenDelistCar = () => {
    setDelistCar(false);
  };

  const handleCancelDelistCar = () => {
    setDelistCar(false);
  };

  const showReqInspection = (data: any) => {
    setCarDetails(data);
    setReqInspection(true);
  };

  const handleOpenReqInspection = () => {
    setReqInspection(false);
  };

  const handleCancelReqInspection = () => {
    setReqInspection(false);
  };

  const expandIconFunction = (isActive?: boolean) => {
    return <CaretRightOutlined rotate={isActive ? 90 : 0} />;
  };

  const { isLoading: carAdminLoading } = useQuery({
    queryKey: ["fetch_admin_cars_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/admin/car/${carId}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarById(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carMediaLoading } = useQuery({
    queryKey: ["car_media_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        page_number: "1",
        page_size: "20",
      });
      return client.get(`/v1/inventory/car_media`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarMedia(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carFeaturesLoading } = useQuery({
    queryKey: ["car_feature_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        page_number: "1",
        page_size: "1000",
      });
      return client.get(`/v1/inventory/car_feature`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarFeature(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_inspection_id", carId, country],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        country: country,
        type: "regular",
      });
      return client.get(`/v1/inspection`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setInspectionId(parsedResults?.result[0]?.id);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const hasInspection = Object.keys(inspectionById)?.length > 0;

  const { refetch: refetchRequests } = useQuery({
    queryKey: ["get_inspection_request", carById, country],
    queryFn: () => {
      if (!carById?.vin) {
        return;
      }
      const params = new URLSearchParams({
        vin: carById?.vin,
        country: country,
        type: "regular",
      });
      return client.get(`/v1/inventory/inspection_request`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      if (parsedResults?.inspectionRequests?.length > 0) {
        setHasInspectionRequest(true);
      } else setHasInspectionRequest(false);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_inspection_by_id", inspectionId],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inspection/${inspectionId}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setInspectionById(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!inspectionId,
  });

  const tabList = [
    {
      key: "tab1",
      tab: t("inspection-report"),
    },
    {
      key: "tab2",
      tab: t("car-features"),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    tab1: (
      <>
        {Object?.keys(inspectionById)?.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <InspectionReportTable>
            <Collapse
              accordion
              bordered={false}
              expandIcon={({ isActive }) => expandIconFunction(isActive)}
              size="small"
              className="collapse-class"
            >
              {inspectionById?.result?.sections?.map((elm: any) => (
                <Panel
                  header={capitalFirstLetter(elm?.name)}
                  extra={
                    <Rate
                      allowHalf
                      defaultValue={Number(elm?.stars?.toFixed(1))}
                      disabled={true}
                      className="text-sm md:text-xl"
                    />
                  }
                  key={inspectionById?.result?.sections?.indexOf(elm)}
                  style={panelStyle}
                >
                  <Table
                    dataSource={elm?.inspectionItems}
                    columns={columns}
                    pagination={false}
                  />
                </Panel>
              ))}
            </Collapse>
          </InspectionReportTable>
        )}
      </>
    ),
    tab2: (
      <div>
        {carFeature?.carFeatureList?.length !== 0 ? (
          <Space size={[4, 16]} wrap>
            {carFeature?.carFeatureList?.map((elm: any, index: number) => (
              <Tag key={elm?.id} color="#E5E7EB">
                {elm?.feature?.name}
              </Tag>
            ))}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    ),
  };

  const onTab1Change = (key: string) => {
    setActiveTabKey1(key);
  };

  useEffect(() => {
    if (carById?.marketplaceVisible) {
      setCheckListed(true);
    } else {
      setCheckListed(false);
    }
  }, [carById]);

  const handleListCar = (checked: boolean) => {
    if (checked) {
      setCheckListed(true);
      showListCar(carId);
    } else if (!checked) {
      setCheckListed(false);
      showDelistCar(carId);
    }
  };

  const handleCarousel = (currentSlide: number) => {};

  const useFinancingonChange = (checked: boolean) => {
    return checked;
  };

  const schema = yup
    .object({
      amount: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRepoOffer>({
    resolver: yupResolver(schema),
  });

  const handleSubmitOffer = (data: IRepoOffer) => {
    return data;
  };

  const HIRequestButton = () => {
    if (hasInspectionRequest) {
      return { text: t("pending-inspection"), disabled: true };
    } else {
      return {
        text: t("request-inspection"),
        disabled: false,
        onClick: () => showReqInspection(carById),
      };
    }
  };

  const HIRequestButtonProps = HIRequestButton();

  const transmission = carById?.transmission || "---";
  const fuelType = carById?.fuelType || "---";
  const engineType = carById?.engineType || "---";
  const bodyTypeName = carById?.bodyType?.name || "---";
  const exteriorColor = carById?.exteriorColor || "---";
  const interiorColor = carById?.interiorColor || "---";

  const displayTransmission = capitalFirstLetter(transmission);
  const displayFuelType = capitalFirstLetter(fuelType);
  const displayEngineType = capitalFirstLetter(engineType);
  const displayBodyTypeName = capitalFirstLetter(bodyTypeName);
  const displayExteriorColor = capitalFirstLetter(exteriorColor);
  const displayInteriorColor = capitalFirstLetter(interiorColor);

  const year = carById?.year || "---";
  const make = carById?.model?.make?.name || "---";
  const model = carById?.model?.name || "---";

  const city = carById?.city || "---";
  const cityDisplay = capitalFirstLetter(city);
  const state = carById?.state || "---";
  const stateDisplay = capitalFirstLetter(state);

  const locationDisplay = `${cityDisplay}, ${stateDisplay}`;

  const carInfo = `${year} ${make} ${model}`;

  return (
    <>
      {carAdminLoading || carMediaLoading || carFeaturesLoading ? (
        <TableContainer>
          <div className="filter-mobile-loader-wrapper">
            <Loader variant={"secondary"} />
          </div>
        </TableContainer>
      ) : (
        <Main>
          <LoanDetailsHeader>
            <button className="loan-header" onClick={() => navigate(-1)}>
              <img
                src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
                alt="arrow-back-img"
              />{" "}
              <div className="name">{t("car-details")}</div>
            </button>
            {!isDsaAgent && (
              <div className="loan-header">
                <div className="apply-loan-container">
                  {carById?.marketplaceVisible ? (
                    <></>
                  ) : (
                    <>
                      {hasInspection ? (
                        ""
                      ) : (
                        <RequestInspectionButton
                          onClick={HIRequestButtonProps.onClick}
                          disabled={
                            HIRequestButtonProps.disabled || !!isAssumedUser
                          }
                          className="apply-loan-btn"
                        >
                          {HIRequestButtonProps?.text}
                        </RequestInspectionButton>
                      )}
                    </>
                  )}
                </div>
                <div className="name">
                  {carById?.marketplaceVisible
                    ? t("delist-car")
                    : t("list-car")}
                </div>
                <Switch
                  onChange={handleListCar}
                  loading={carAdminLoading}
                  checked={checkListed}
                  disabled={!inspectionId || !!isAssumedUser}
                />
              </div>
            )}
          </LoanDetailsHeader>
          <InventoryDetailsPage>
            {/* CAR MEDIA */}
            <CarMediaContainer className="hidden lg:block">
              <div className="car-media-container">
                <div className="img-one">
                  <img
                    src={ImageLoader({
                      src: `${carById?.imageUrl}`,
                      width: 400,
                      quality: 100,
                    })}
                    alt="car-img"
                  />
                </div>
                <div className="media-group-two">
                  {carMedia?.carMediaList?.length > 0 ? (
                    <>
                      {carMedia?.carMediaList?.slice(0, 4)?.map((elm: any) => (
                        <div className="img-two-one" key={elm?.id}>
                          <img
                            src={ImageLoader({
                              src: `${elm?.url}`,
                              width: 400,
                              quality: 100,
                            })}
                            alt="car-img"
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="img-two-one">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/download.png"
                          alt="fallback"
                        />
                      </div>
                      <div className="img-two-one">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/download.png"
                          alt="fallback"
                        />
                      </div>
                      <div className="img-two-one">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/download.png"
                          alt="fallback"
                        />
                      </div>
                      <div className="img-two-one">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/download.png"
                          alt="fallback"
                        />
                      </div>
                    </>
                  )}
                </div>
                <button
                  className="show-photos"
                  onClick={() => setVisible(true)}
                >
                  <img
                    src="https://media.autochek.africa/file/publicAssets/Frame-106.svg"
                    alt="screen-img"
                  />{" "}
                  {t("show-all-photos")}
                </button>
              </div>
            </CarMediaContainer>

            <CarMediaMobile className="mb-5 block lg:hidden">
              <Carousel afterChange={handleCarousel} autoplay>
                {carMedia?.carMediaList?.map((elm: Record<string, any>) => (
                  <div key={elm?.id}>
                    <div className="content-style">
                      <img
                        src={ImageLoader({
                          src: `${elm?.url}`,
                          width: 400,
                          quality: 75,
                        })}
                        alt="car-img"
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </CarMediaMobile>

            <CarContentContainer className="flex flex-col xl:flex-row gap-[24px]">
              <div className="car-content-one-wrapper w-full max-w-full xl:max-w-[65%]">
                <Space direction="vertical" size={24} style={{ width: "100%" }}>
                  <Card
                    className="card-one"
                    title={
                      <div className="title-container">
                        <div className="name">
                          <div>{carInfo}</div>
                          {!repoCheck && (
                            <div className="franchise-name">
                              {t("franchise")} : {carById?.carManagerName}
                            </div>
                          )}
                        </div>
                        <div className="detail">
                          <div className="location">
                            <img
                              src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-1-0.svg"
                              alt="location-icon"
                            />{" "}
                            {locationDisplay}
                          </div>
                          <div className="detail-name">
                            <span className="rate-wrap">
                              <img
                                src="https://media.autochek.africa/file/publicAssets/star.svg"
                                alt="rate-icon"
                              />{" "}
                              {carById?.gradeScore?.toFixed(1) || "---"}
                            </span>
                            <span> | </span>
                            <span>
                              {t("id")}: {carId || "---"}
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    <div>
                      <div className="overview">{t("overview")}</div>
                      <div className="car-description-wrapper">
                        <div>
                          <div className="title">{t("fuel-type")}</div>
                          <div className="content">{displayFuelType}</div>
                        </div>
                        <div>
                          <div className="title">{t("Transmission")}</div>
                          <div className="content">{displayTransmission}</div>
                        </div>
                        <div>
                          <div className="title">{t("engine-type")}</div>
                          <div className="content">{displayEngineType}</div>
                        </div>
                        <div>
                          <div className="title">{t("body-style")}</div>
                          <div className="content">{displayBodyTypeName}</div>
                        </div>
                        <div>
                          <div className="title">{t("exterior-color")}</div>
                          <div className="content">{displayExteriorColor}</div>
                        </div>
                        <div>
                          <div className="title">{t("interior-color")}</div>
                          <div className="content">{displayInteriorColor}</div>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <div className="hidden xl:block">
                    <Card
                      className="card-two"
                      style={{ width: "100%" }}
                      tabList={tabList}
                      activeTabKey={activeTabKey1}
                      onTabChange={onTab1Change}
                    >
                      {contentList[activeTabKey1]}
                    </Card>
                  </div>

                  {/* CARD DETAILS ON MOBILE */}
                  <div className="block xl:hidden">
                    <div className="car-content-two-wrapper mb-6">
                      <Card className="card-three" style={{ width: "100%" }}>
                        <div className="title-container">
                          <div className="price">
                            <img
                              src="https://media.autochek.africa/file/publicAssets/cash-outline.svg"
                              alt="price-icon"
                            />{" "}
                            {t("price")}
                          </div>
                          <div className="installment-amount">
                            <div className="instalment">
                              {formatInt(carById?.installment, true)} /{" "}
                              {t("mo")}
                            </div>
                            <div className="amount">
                              {formatInt(carById?.marketplacePrice, true)}
                            </div>
                          </div>
                          <div className="note">
                            {t("estimated-payment-excludes-taxes")}.
                          </div>
                        </div>
                        <div className="apply-container">
                          {isFranchise && ["NG", "KE"].includes(country) ? (
                            <WhitelabelMenuItems carId={carId} />
                          ) : (
                            <Button
                              className="apply-loan-btn"
                              onClick={() => toLoan(carId)}
                              disabled={!!isAssumedUser}
                            >
                              {t("apply-for-loan")}
                            </Button>
                          )}
                        </div>
                        <div className="edit-container">
                          <div className="make-edit">
                            {t("make-updates-to-car")}
                          </div>
                          <Button
                            onClick={() => toEdit(carId)}
                            className="edit-car"
                            disabled={!!isAssumedUser}
                          >
                            {t("edit-car")}
                          </Button>
                        </div>
                      </Card>
                    </div>

                    <Card
                      className="card-two"
                      style={{ width: "100%" }}
                      tabList={tabList}
                      activeTabKey={activeTabKey1}
                      onTabChange={onTab1Change}
                    >
                      {contentList[activeTabKey1]}
                    </Card>

                    <div className="car-content-two-wrapper mt-6">
                      <LoanCalculator
                        car={carById}
                        loading={carAdminLoading}
                        carId={carById?.id}
                      />
                    </div>
                  </div>
                </Space>
              </div>

              <div className="hidden xl:block w-full max-w-full xl:max-w-[35%]">
                <div className="car-content-two-wrapper">
                  <Card className="card-three" style={{ width: "100%" }}>
                    <div
                      className={`title-container ${
                        repoCheck && `flex items-center justify-between`
                      }`}
                    >
                      <div className="price">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/cash-outline.svg"
                          alt="price-icon"
                        />{" "}
                        {t("price")}
                      </div>
                      {repoCheck && (
                        <div
                          className={`amount ${
                            repoCheck &&
                            `text-base font-bold text-[#30345E] tracking-[0.01rem]`
                          } `}
                        >
                          {formatInt(carById?.marketplacePrice, true)}
                        </div>
                      )}
                      {!repoCheck && (
                        <div className="installment-amount">
                          <div className="instalment">
                            {formatInt(carById?.installment, true)} / {t("mo")}
                          </div>
                          <div className="amount">
                            {formatInt(carById?.marketplacePrice, true)}
                          </div>
                        </div>
                      )}
                      {!repoCheck && (
                        <div className="note">
                          {t("estimated-payment-excludes-taxes")}.
                        </div>
                      )}
                    </div>
                    {repoCheck && (
                      <div className="p-4">
                        <div className="flex justify-between items-center">
                          <div>
                            <span className="text-[#30345E] text-base font-bold tracking-[0.01rem]">
                              Use financing
                            </span>
                          </div>
                          <SwtichWrap>
                            <Switch
                              defaultChecked
                              onChange={useFinancingonChange}
                            />
                          </SwtichWrap>
                        </div>
                        <div className="flex justify-between items-center pt-4">
                          <div>
                            <span className="text-[#30345E] text-base font-bold tracking-[0.01rem]">
                              Make an offer
                            </span>
                          </div>
                          <div>
                            <span className="text-[#6B7280]">
                              Offer must be X% of car price
                            </span>
                          </div>
                        </div>
                        <div className="pt-2">
                          <Form
                            onSubmit={handleSubmit(handleSubmitOffer)}
                            autoComplete="off"
                          >
                            <Inputs>
                              <GethelpInput
                                label={""}
                                type="text"
                                placeholder={"Enter amount"}
                                {...register("amount")}
                                error={errors.amount}
                              />
                            </Inputs>
                            <div
                              className="apply-container"
                              style={{ padding: 0, marginTop: "-10px" }}
                            >
                              <button className="submit-offer-btn font-bold">
                                Submit Offer
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    )}
                    {!repoCheck && (
                      <div className="apply-container">
                        {isFranchise && ["NG", "KE"].includes(country) ? (
                          <WhitelabelMenuItems carId={carId} />
                        ) : (
                          <Button
                            className="apply-loan-btn"
                            onClick={() => toLoan(carId)}
                            disabled={!!isAssumedUser}
                          >
                            {t("apply-for-loan")}
                          </Button>
                        )}
                      </div>
                    )}
                    {!repoCheck && (
                      <div className="edit-container">
                        <div className="make-edit">
                          {t("make-updates-to-car")}
                        </div>
                        <Button
                          onClick={() => toEdit(carId)}
                          className="edit-car"
                          disabled={!!isAssumedUser}
                        >
                          {t("edit-car")}
                        </Button>
                      </div>
                    )}
                  </Card>

                  {!repoCheck && (
                    <LoanCalculator
                      car={carById}
                      loading={carAdminLoading}
                      carId={carById?.id}
                    />
                  )}
                </div>
              </div>
            </CarContentContainer>
          </InventoryDetailsPage>

          <div className="preview-container">
            <Image.PreviewGroup
              preview={{
                visible,
                scaleStep,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            >
              {carMedia?.carMediaList?.map((elm: any, index: number) => (
                <div key={elm?.id}>
                  <Image
                    style={{ display: "none" }}
                    width={200}
                    src={elm?.url}
                    alt="car-img"
                  />
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        </Main>
      )}

      <ListCarModal
        isModalOpen={listCar}
        handleOk={handleOpenListCar}
        handleCancel={handleCancelListCar}
        carId={carData}
      />
      <DelistCarModal
        isModalOpen={delistCar}
        handleOk={handleOpenDelistCar}
        handleCancel={handleCancelDelistCar}
        carId={carData}
      />
      <RequestInspectionModal
        isModalOpen={reqInspection}
        handleOk={handleOpenReqInspection}
        handleCancel={handleCancelReqInspection}
        carData={carDetails}
        refresh={refetchRequests}
      />
    </>
  );
};

export default InventoryDetails;
