import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";

const MainContainer = tw.div`bg-white flex justify-center items-center border border-solid border-[#e5e7eb] rounded-lg w-full min-h-[250px] h-auto`;
const ContentContainer = tw.div`flex flex-col gap-6 md:gap-4 justify-center items-center`;
const Text = tw.div`text-center text-base text-[#485164] leading-tight`;

export default function BenefitEmptyState() {
  const { t } = useTranslation("dealer-gamification");

  return (
    <MainContainer>
      <ContentContainer>
        <div className="w-[90px] h-[79px] my-0 mx-auto">
          <img
            src="https://media.autochek.africa/file/publicAssets/Frame-q.svg"
            alt="no document"
          />
        </div>
        <Text>
          {t("your-benefits-will-be-accessible")} <br />{" "}
          {t("your-benefits-will-be-accessible-newline")}
        </Text>
      </ContentContainer>
    </MainContainer>
  );
}
