import { useState } from "react";
import { Button, Form, message } from "antd";
import { CustomCarUploadContainer, CustomFormField } from "./styles";
import client from "@/lib/axios-client";
import CustomFileUploader from "./CustomFileUpload";
import { useTranslation } from "react-i18next";
import { generateCompressedUrl } from "@/utils/compressedUrl";
import { Loader } from "@/pages/auth/styles/loader";

interface ICarUpload {
  setStep: (a: number) => void;
  resData: any;
}

const CarUploads = ({ setStep, resData }: ICarUpload) => {
  const { t } = useTranslation("inventory");
  const { t: t1 } = useTranslation("car-upload");
  const [form] = Form.useForm();
  const [loadingLeftFront, setLoadingLeftFront] = useState(false);
  const [loadingRightFront, setLoadingRightFront] = useState(false);
  const [loadingFront, setLoadingFront] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [loadingLeftBack, setLoadingLeftBack] = useState(false);
  const [loadingRightBack, setLoadingRightBack] = useState(false);
  const [leftFrontUrl, setLeftFrontUrl] = useState("");
  const [rightFrontUrl, setRightFrontUrl] = useState("");
  const [frontUrl, setFrontUrl] = useState("");
  const [backUrl, setBackUrl] = useState("");
  const [leftBackUrl, setLeftBackUrl] = useState("");
  const [rightBackUrl, setRightBackUrl] = useState("");
  const [loadingLeft, setLoadingLeft] = useState(false);
  const [loadingRight, setLoadingRight] = useState(false);
  const [loadingEngine, setLoadingEngine] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [loadingTyres, setLoadingTyres] = useState(false);
  const [loadingInterior, setLoadingInterior] = useState(false);
  const [loadingOthers, setLoadingOthers] = useState(false);
  const [leftUrl, setLeftUrl] = useState("");
  const [rightUrl, setRightUrl] = useState("");
  const [engineUrl, setEngineUrl] = useState("");
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [tyresUrl, setTyresUrl] = useState("");
  const [interiorUrl, setInteriorUrl] = useState("");
  const [othersUrl, setOthersUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitUpload = async (values: any) => {
    setLoading(true);

    Object.keys(values).forEach((item: any) => {
      if (
        values[item] === undefined ||
        values[item] === null ||
        values[item] === ""
      ) {
        delete values[item];
      }
    });

    const newValueObj = await Promise.all(
      Object.keys(values).map(async (el) => {
        const compressedUrl = await generateCompressedUrl(
          values[el][0].response.file.url
        );
        return {
          name: el.replaceAll("_", " "),
          type: values[el][0]?.response.file.mimetype,
          url: compressedUrl,
        };
      })
    );

    const payload = {
      car_id: resData?.id,
      payload: newValueObj,
    };

    try {
      const response = await client.post("/v1/inventory/car_media", payload);
      if (response) {
        setStep(3);
        message.success(t1("car-media-uploaded-successfully"));
        setLoading(false);
      }
    } catch (err: any) {
      message.error(
        JSON.parse(err?.response?.data)?.error ||
          t1("error-uploading-car-media")
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <CustomCarUploadContainer>
        <Form
          name="basic"
          form={form}
          onFinish={handleSubmitUpload}
          layout="vertical"
        >
          <div className="upload-container">
            <CustomFileUploader
              imageUrl={leftFrontUrl}
              loadingUpload={loadingLeftFront}
              setLoadingUpload={setLoadingLeftFront}
              setImageUrl={setLeftFrontUrl}
              label="Upload left front image"
              name="car_left_front"
            />
            <CustomFileUploader
              imageUrl={rightFrontUrl}
              loadingUpload={loadingRightFront}
              setLoadingUpload={setLoadingRightFront}
              setImageUrl={setRightFrontUrl}
              label="Upload right front image"
              name="car_right_front"
            />

            <CustomFileUploader
              imageUrl={frontUrl}
              loadingUpload={loadingFront}
              setLoadingUpload={setLoadingFront}
              setImageUrl={setFrontUrl}
              label="Upload front image "
              name="car_front"
            />
            <CustomFileUploader
              imageUrl={backUrl}
              loadingUpload={loadingBack}
              setLoadingUpload={setLoadingBack}
              setImageUrl={setBackUrl}
              label="Upload back image"
              name="car_back"
            />
            <CustomFileUploader
              imageUrl={leftBackUrl}
              loadingUpload={loadingLeftBack}
              setLoadingUpload={setLoadingLeftBack}
              setImageUrl={setLeftBackUrl}
              label="Upload left back image"
              name="car_left_back"
            />
            <CustomFileUploader
              imageUrl={rightBackUrl}
              loadingUpload={loadingRightBack}
              setLoadingUpload={setLoadingRightBack}
              setImageUrl={setRightBackUrl}
              label="Upload right back image"
              name="car_right_back"
            />
            <CustomFileUploader
              imageUrl={leftUrl}
              loadingUpload={loadingLeft}
              setLoadingUpload={setLoadingLeft}
              setImageUrl={setLeftUrl}
              label="Upload left image"
              name="car_left"
            />
            <CustomFileUploader
              imageUrl={rightUrl}
              loadingUpload={loadingRight}
              setLoadingUpload={setLoadingRight}
              setImageUrl={setRightUrl}
              label="Upload right image"
              name="car_right"
            />
            <CustomFileUploader
              imageUrl={engineUrl}
              loadingUpload={loadingEngine}
              setLoadingUpload={setLoadingEngine}
              setImageUrl={setEngineUrl}
              label="Upload engine image"
              name="engine"
            />
            <CustomFileUploader
              imageUrl={dashboardUrl}
              loadingUpload={loadingDashboard}
              setLoadingUpload={setLoadingDashboard}
              setImageUrl={setDashboardUrl}
              label="Upload dashboard image"
              name="dashboard"
            />
            <CustomFileUploader
              imageUrl={tyresUrl}
              loadingUpload={loadingTyres}
              setLoadingUpload={setLoadingTyres}
              setImageUrl={setTyresUrl}
              label="Upload tyre image"
              name="tyres"
            />
            <CustomFileUploader
              imageUrl={interiorUrl}
              loadingUpload={loadingInterior}
              setLoadingUpload={setLoadingInterior}
              setImageUrl={setInteriorUrl}
              label="Upload interior image"
              name="car_interior"
            />
            <CustomFileUploader
              imageUrl={othersUrl}
              loadingUpload={loadingOthers}
              setLoadingUpload={setLoadingOthers}
              setImageUrl={setOthersUrl}
              label="Upload other image"
              name="other_images"
            />
          </div>

          <Form.Item>
            <CustomFormField>
              <div className="loading-btn-container">
                <Button
                  disabled={
                    !leftFrontUrl ||
                    !rightFrontUrl ||
                    !frontUrl ||
                    !backUrl ||
                    !leftBackUrl ||
                    !rightBackUrl ||
                    !leftUrl ||
                    !rightUrl ||
                    !engineUrl ||
                    !dashboardUrl ||
                    !tyresUrl ||
                    !interiorUrl
                  }
                  htmlType="submit"
                  type="primary"
                >
                  {loading ? <Loader variant={"secondary"} /> : t("next")}
                </Button>
              </div>
            </CustomFormField>
          </Form.Item>
        </Form>
      </CustomCarUploadContainer>
    </div>
  );
};

export default CarUploads;
