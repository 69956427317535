import { createSvgIcon } from '@mui/material'
import React from 'react'

const UploadCloudIcon = createSvgIcon(
  <svg width="24" height="20" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.938 0.347574C17.2038 0.264092 19.459 0.695353 21.5342 1.60893C23.6093 2.52251 25.4504 3.89464 26.9189 5.62216C28.1046 7.01703 29.0226 8.6143 29.6309 10.3333H30.4991C32.7209 10.3348 34.8799 11.0761 36.6336 12.4402C38.3872 13.8043 39.6366 15.7136 40.1846 17.8667C40.7327 20.0198 40.548 22.294 39.66 24.3306C38.7719 26.3671 37.2309 28.0499 35.2802 29.1133C34.472 29.5539 33.4597 29.2559 33.0191 28.4477C32.5785 27.6396 32.8765 26.6272 33.6847 26.1866C34.9851 25.4777 36.0125 24.3558 36.6045 22.9981C37.1966 21.6405 37.3196 20.1243 36.9543 18.6889C36.589 17.2535 35.756 15.9807 34.5869 15.0712C33.4178 14.1618 31.9792 13.6676 30.498 13.6666H28.3991C27.6393 13.6666 26.9757 13.1528 26.7855 12.4172C26.3441 10.7098 25.5214 9.12472 24.3792 7.7811C23.237 6.43748 21.8051 5.37027 20.1911 4.6597C18.5771 3.94914 16.823 3.61372 15.0607 3.67865C13.2984 3.74358 11.5737 4.20717 10.0164 5.03458C8.45905 5.86199 7.10955 7.03168 6.06933 8.45571C5.02912 9.87974 4.32527 11.5211 4.01069 13.2563C3.69611 14.9915 3.779 16.7754 4.25311 18.474C4.72723 20.1726 5.58024 21.7415 6.74801 23.063C7.35754 23.7527 7.29252 24.806 6.60278 25.4155C5.91303 26.0251 4.85976 25.96 4.25023 25.2703C2.74881 23.5713 1.65208 21.554 1.04251 19.3702C0.432928 17.1863 0.326361 14.8926 0.730818 12.6617C1.13527 10.4307 2.04023 8.32041 3.37765 6.48951C4.71506 4.65861 6.45014 3.15473 8.45244 2.09092C10.4547 1.02711 12.6721 0.431056 14.938 0.347574ZM19.3206 15.8215C19.9715 15.1706 21.0268 15.1706 21.6776 15.8215L28.3443 22.4881C28.9952 23.139 28.9952 24.1943 28.3443 24.8451C27.6934 25.496 26.6382 25.496 25.9873 24.8451L22.1658 21.0237V32C22.1658 32.9204 21.4196 33.6666 20.4991 33.6666C19.5786 33.6666 18.8325 32.9204 18.8325 32V21.0237L15.011 24.8451C14.3601 25.496 13.3048 25.496 12.6539 24.8451C12.0031 24.1943 12.0031 23.139 12.6539 22.4881L19.3206 15.8215Z" fill="#30345E"/>
  </svg>, 'Upload'
)

export default UploadCloudIcon;
